import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userDetails: {},
  guestCount: 1,
};
debugger
const confirmationNoSlice = createSlice({

  name: "confirmation",
  initialState,
  reducers: {
    userReservationDetailsConfirmatioNo: (state, action) => {
      //console.log("payload set==>"+JSON.stringify(action.payload))
      state.userDetails = action.payload;
     // console.log("state.userDetails set==>"+JSON.stringify(state.userDetails))
    },
    confNoCountOfTheGuests: (state, action) => {
      state.guestCount = state.guestCount + action.payload;
    },
    resetGuestCount: (state) => {
      state.guestCount = 1;
    },
  },
});

export const getconfirmationnumberData = (state) =>
  state.confirmation.userDetails;

export const getConfNoCurrentCountOfGuest = (state) =>
  state.confirmation.guestCount;

export const {
  userReservationDetailsConfirmatioNo,
  confNoCountOfTheGuests,
  resetGuestCount,
} = confirmationNoSlice.actions;

export default confirmationNoSlice.reducer;
