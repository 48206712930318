/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState, useMemo, useCallback } from "react";
import "../../assets/stylesheet/terms-and-condition.css";
import "../../assets/stylesheet/reservation-detail.css";
import LoaderPopup from "../LoaderAndNotification/LoaderPopup";
import ErrorPopup from "../LoaderAndNotification/ErrorPopup";
import SignaturePad from "../Forms/SignaturePad";
import countryList from "react-select-country-list";
import {
  capitalizeFullName,
  formatDate,
  nameValidation,
} from "../../helpers/commonHelper";
import { getTermsAndConditons } from "../../network/apiService";

const UserReservationDetails = ({
  loading,
  loading2,
  loading3,
  erroModalData,
  erroModalData2,
  erroModalData3,
  closeErrorModal,
  closeErrorModal2,
  closeErrorModal3,
  openModal,
  reservationData,
  confirmationDetails,
  confirmationDetailsHandler,
  signature,
  setSignature,
  setUrl,
  url,
  gotSignature,
  userAgreed,
  navigateToPayment,
  handleCheckboxChange,
  clearSign,
  updateProfile,
  openCheckOutTimeModal,
  toggleCheckoutTimeModal,
  timeChanged,
  setTimeChanged,
  onChangeHandler,
  emailValidation,
  countryValidation,
  mobileNoValidation,
  enterEmailValidation,
}) => {
  const [termsAndConditionData, setTermsAndConditionData] = useState("");
  const [tnCLoader, setTnCLoader] = useState(false);
  const [erroModalData4, setErrorModalData4] = useState({
    toggleError: false,
    errorMessage: "",
  });

  const token = localStorage.getItem("token");

  const hrsCount = [
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "0",
  ];

  const minsCount = ["00", "15", "30", "45"];

  const countryOptions = useMemo(() => countryList().getData(), []);

  const closeErrorModal4 = () => {
    setErrorModalData4((state) => ({
      ...state,
      toggleError: false,
      errorMessage: "",
    }));
  };

  const getTermsAndConditonsData = useCallback(async () => {
    try {
      setTnCLoader(true);
      const response = await getTermsAndConditons(token);
      if (response.status === 200) {
        setTermsAndConditionData(response.data);
        setTnCLoader(false);
        return;
      }
    } catch (error) {
      setTnCLoader(false);
      // console.log(error);
      setErrorModalData4((state) => ({
        ...state,
        toggleError: true,
        errorMessage: error.message,
      }));
    }
    return;
  }, [token]);

  useEffect(() => {
    getTermsAndConditonsData();
  }, []);

  useEffect(() => {
    if (timeChanged) {
      openCheckOutTimeModal();
    }
  }, [timeChanged]);

  return (
    <div>
      <main>
        <LoaderPopup loading={loading} />
        <LoaderPopup loading={loading2} />
        <LoaderPopup loading={loading3} />
        <LoaderPopup loading={tnCLoader} />
        <ErrorPopup
          closeErrorModal={closeErrorModal}
          erroModalData={erroModalData}
        />
        <ErrorPopup
          closeErrorModal={closeErrorModal2}
          erroModalData={erroModalData2}
        />
        <ErrorPopup
          closeErrorModal={closeErrorModal3}
          erroModalData={erroModalData3}
        />
        <ErrorPopup
          closeErrorModal={closeErrorModal4}
          erroModalData={erroModalData4}
        />
        <div className="main-content">
          <div className="h-100">
            <div className="content-bg h-100">
              <div className="row h-100">
                <div className="col-sm-6 pl-0">
                  <div className="h-100">
                    <div>
                      <button
                        onClick={() => {
                          openModal();
                        }}
                        className="btn home-btn mt-6"
                      >
                        <i className="ri-arrow-go-back-line mr-3"></i>RESTART
                      </button>
                    </div>
                    <div className="reservation-content-width" style={{marginTop:'15px'}}>
                      <div className="">
                        <div className="mb-20">
                          <div className="terms-title">
                            <h5>Reservation Details</h5>
                          </div>

                          <div className="d-flex reservation-detail-content">
                            <div className="reservation-width">
                              <label>Name:</label>
                              <p>
                                {nameValidation(
                                  reservationData.firstName,
                                  reservationData.lastName
                                )}
                              </p>
                            </div>
                            <div className="reservation-width-right">
                              <label>Number of Nights:</label>
                              <p>{reservationData.nights}</p>
                            </div>
                            {/* <div>
                            <label>Room Number:</label>
                            <p>{reservationData.roomNo}</p>
                          </div> */}
                          </div>
                          <div className="d-flex reservation-detail-content">
                            <div className="reservation-width">
                              <label>Arrival Date:</label>
                              <p>{formatDate(reservationData?.arrivalDate)}</p>
                            </div>
                            <div className="reservation-width-right">
                              <label>Departure Date:</label>
                              <p>
                                {formatDate(reservationData?.departureDate)}
                              </p>
                            </div>
                          </div>

                          <div className="d-flex justify-content-between communication-detail" >
                            <label>
                              Check-out Time
                              <span style={{ color: "red" }}>*</span> :
                            </label>

                            <select
                            style={{marginLeft:'-30px'}}
                              name="hrs"
                              value={confirmationDetails.hrs}
                              onChange={onChangeHandler}
                              className="form-control form-control-set select-option"
                            >
                              {hrsCount.map((hr, idx) => {
                                if (
                                  hr === confirmationDetails.hrs ||
                                  hr !== confirmationDetails.hrs
                                ) {
                                  return (
                                    <option key={idx} value={hr}>
                                      {hr}
                                    </option>
                                  );
                                } else {
                                  return null;
                                }
                              })}
                            </select>
                            <div className="dot-div">:</div>
                            <select
                              name="mins"
                              value={confirmationDetails.mins}
                              onChange={onChangeHandler}
                              className="form-control form-control-set select-option"
                            >
                              {minsCount.map((mins, idx) => {
                                if (
                                  mins === confirmationDetails.hrs ||
                                  mins !== confirmationDetails.hrs
                                ) {
                                  return (
                                    <option key={idx} value={mins}>
                                      {mins}
                                    </option>
                                  );
                                } else {
                                  return null;
                                }
                              })}
                            </select>
                          </div>
                          <hr style={{border: '1px dashed'}}/>

                          <div className="d-flex justify-content-between communication-detail">
                            <label className="reservation-width-right">
                              Country of Residence
                              <span style={{ color: "red" }}>*</span> :
                            </label>
                            <select
                              name="country"
                              value={confirmationDetails.country || ""}
                              onChange={confirmationDetailsHandler}
                              className="form-control form-control-set"
                            >
                              <option value="" default>
                                Select
                              </option>
                              {countryOptions?.map((country, idx) => {
                                if (
                                  country?.label ===
                                    confirmationDetails?.country ||
                                  country?.label !==
                                    confirmationDetails?.country
                                ) {
                                  return (
                                    <option key={idx} value={country?.label}>
                                      {country?.label}
                                    </option>
                                  );
                                } else {
                                  return null;
                                }
                              })}
                            </select>
                          </div>
                          {countryValidation && (
                            <p className="paragraph-validation error-message">
                              Please select your country
                            </p>
                          )}
                          <div className="d-flex justify-content-between communication-detail">
                            <label className="reservation-width-right">
                              Email<span style={{ color: "red" }}>*</span> :
                            </label>
                            <input
                              type="email"
                              onChange={confirmationDetailsHandler}
                              name="email"
                              placeholder="Email"
                              autoComplete="off"
                              value={confirmationDetails.email}
                              required
                              className="form-control form-control-set"
                            />
                           
                          </div>
                        
                          {/* {enterEmailValidation && (
                            <p className="paragraph-validation error-message">
                              Please fill in your Email Address
                            </p>
                          )}
                           {emailValidation && !enterEmailValidation && (
                            <p className="paragraph-validation error-message">
                              Please enter a valid Email Address.
                            </p>
                          )} */}

                          <div className="d-flex justify-content-between communication-detail">
                            <label className="reservation-width-right">
                              Mobile Number
                              <span style={{ color: "red" }}>*</span> :
                            </label>
                            <input
                              type="number"
                              id="myNumberInput"
                              placeholder="Mobile Number"
                              onChange={confirmationDetailsHandler}
                              autoComplete="off"
                              className="form-control form-control-set"
                              name="mobileNo"
                              value={confirmationDetails.mobileNo}
                            />
                             {/* {mobileNoValidation && (
                            <p className="paragraph-validation error-message">
                              Please fill in your Phone Number
                            </p>
                          )} */}
                          </div>
                         
                          <div className="d-flex reservation-detail-content">
                            <div className="reservation-width">
                              <label className="reservation-width-right">Room Rate:</label>
                            
                              <strong style={{color:'black'}}><h3>${reservationData?.roomRate}</h3></strong>
                            </div>
                            <div className="reservation-width-right" style={{marginLeft:'-40px'}}>
                              <label className="reservation-width-right">Total Amount:</label>
                              <br/>
                              <strong style={{color:'black'}}><h3>${reservationData?.totalAmount}</h3></strong>
                            </div>
                          </div>
                          <div style={{ marginTop: "20px" }}>
                            <p style={{fontSize:'14px'}}>
                              Note: The official hotel departure time is 12
                              noon. Late departures can be requested through the
                              Front Desk, subject to hotel availability.
                              Additional charges may be applied.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6" style={{background:"rgb(245 247 246)"}}>
                  <div className="">
                    <div>
                      <div className="terms-condition-width">
                        <div>
                          <div className="terms-title">
                            <h5>Terms and Conditions</h5>
                            <div className="terms-condition-scroll">
                              {Array.isArray(termsAndConditionData) ? (
                                termsAndConditionData?.map((ele, idx) => (
                                  <p key={idx}>{ele.content}</p>
                                ))
                              ) : (
                                <p style={{fontSize:'14px'}}>{termsAndConditionData || ""}</p>
                              )}
                            </div>

                            <div className="accept-condition">
                              <input
                                type="checkbox"
                                checked={userAgreed}
                                onChange={handleCheckboxChange}
                              />
                              <span>
                                By clicking this box I am agree to receive
                                marketing emails
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="h-100">
                          <div>
                            <div className="mt-20 d-flex justify-content-between">
                              <div className="terms-title">
                                <h5>
                                  By signing, I accept the Terms and Conditions
                                </h5>
                              </div>
                              <div className="">
                                <button
                                  onClick={clearSign}
                                  className="btn terms-retry-btn"
                                >
                                  <i className="bx bx-revision"></i>
                                </button>
                              </div>
                            </div>

                            <div>
                              <SignaturePad
                                signature={signature}
                                setSignature={setSignature}
                                gotSignature={gotSignature}
                                setUrl={setUrl}
                                url={url}
                                showRetryBtn={false}
                              />
                            </div>
                          </div>
                          <div>
                            <div className="d-flex justify-content-end">
                              <button                               
                                onClick={updateProfile}
                                className="btn proceed-btn"
                                style={{
                                  borderColor: "#03355c",
                                }}
                              >
                                Proceed
                                {/* Proceed <i className="bx bx-chevron-right"></i> */}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default UserReservationDetails;
