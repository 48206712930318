import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ component: C }) => {
  const AuthenticationState = useSelector((state) => state.AuthReducer);
  // if (AuthenticationState.isLoggedIn) {
    return (
      <div>
        <C />
      </div>
    );
  // } else {
  //   return <Navigate to={"/"} />;
  // }
};

export default PrivateRoute;
