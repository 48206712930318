/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import "../../assets/stylesheet/upgrade-option.css";
import Deluxe from "../../assets/images/deluxe.jpg";
import SuperDeluxe from "../../assets/images/super-deluxe.jpg";
import Executive from "../../assets/images/executive.jpg";

const UpgradeRoom = ({ closeModal, roomOptions, setRoomOptions }) => {
  const deluxeRoomPrice = 57;
  const superDeluxeRoomPrice = 80;
  const executiveSuiteRoomPrice = 156;

  const roomSelectionHandler = (roomType) => {
    if (roomType === "deluxe") {
      setRoomOptions((state) => {
        return { ...state, room: "Deluxe", price: deluxeRoomPrice };
      });
    }
    if (roomType === "superdeluxe") {
      setRoomOptions((state) => {
        return {
          ...state,
          room: "Super Deluxe",
          price: superDeluxeRoomPrice,
        };
      });
    }
    if (roomType === "executivesuite") {
      setRoomOptions((state) => {
        return {
          ...state,
          room: "Executive Suite",
          price: executiveSuiteRoomPrice,
        };
      });
    }
  };


  return (
    <div
      className="modal"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-top">
        <div className="modal-content  modal-bg">
          <div className="modal-body">
            <div className="text-center">
              <div className="mt-30">
                <div className="upgrade-section">
                  <div className="upgrade-box">
                    <div className="upgrade-image">
                      <img src={Deluxe} />
                    </div>
                    <div className="upgrade-caption">
                      <p>Deluxe</p>
                      <span>${deluxeRoomPrice}</span>
                      {roomOptions.room !== "Deluxe" && (
                        <button
                          onClick={() => {
                            roomSelectionHandler("deluxe");
                            closeModal();
                          }}
                        >
                          Select
                        </button>
                      )}
                      {roomOptions.room === "Deluxe" && (
                        <button
                          style={{
                            backgroundColor: "white",
                            color: "black",
                            border: "2px solid",
                          }}
                          onClick={() => {
                            setRoomOptions({
                              room: null,
                              price: null,
                            });
                          }}
                        >
                          Remove
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="upgrade-box">
                    <div className="upgrade-image">
                      <img src={SuperDeluxe} />
                    </div>
                    <div className="upgrade-caption">
                      <p>Super Deluxe</p>
                      <span>${superDeluxeRoomPrice}</span>
                      {roomOptions.room !== "Super Deluxe" && (
                        <button
                          onClick={() => {
                            roomSelectionHandler("superdeluxe");
                            closeModal();
                          }}
                        >
                          Select
                        </button>
                      )}
                      {roomOptions.room === "Super Deluxe" && (
                        <button
                          style={{
                            backgroundColor: "white",
                            color: "black",
                            border: "2px solid",
                          }}
                          onClick={() => {
                            setRoomOptions({
                              room: null,
                              price: null,
                            });
                          }}
                        >
                          Remove
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="upgrade-box">
                    <div className="upgrade-image">
                      <img src={Executive} />
                    </div>
                    <div className="upgrade-caption">
                      <p>Executive Suite</p>
                      <span>${executiveSuiteRoomPrice}</span>
                      {roomOptions.room !== "Executive Suite" && (
                        <button
                          onClick={() => {
                            roomSelectionHandler("executivesuite");
                            closeModal();
                          }}
                        >
                          Select
                        </button>
                      )}
                      {roomOptions.room === "Executive Suite" && (
                        <button
                          style={{
                            backgroundColor: "white",
                            color: "black",
                            border: "2px solid",
                          }}
                          onClick={() => {
                            setRoomOptions({
                              room: null,
                              price: null,
                            });
                          }}
                        >
                          Remove
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center mt-30">
              <button
                className="btn modal-yes-btn close-modal-btn"
                data-bs-dismiss="modal"
                onClick={closeModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpgradeRoom;
