import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userDetails: {},
  guestCount: 1,
};

const passportScanSlice = createSlice({
  name: "scanpassport",
  initialState,
  reducers: {
    userReservationDetailsPassport: (state, action) => {
      state.userDetails = action.payload;
    },
    scanPassportCountOfTheGuests: (state, action) => {
      state.guestCount = state.guestCount + action.payload;
    },
    resetScanPassportGuestCount: (state) => {
      state.guestCount = 1;
    },
  },
});

export const getPassportScanData = (state) => state.scanpassport.userDetails;

export const getScanPassportCurrentCountOfGuest = (state) =>
  state.scanpassport.guestCount;

export const {
  userReservationDetailsPassport,
  scanPassportCountOfTheGuests,
  resetScanPassportGuestCount,
} = passportScanSlice.actions;

export default passportScanSlice.reducer;
