import ErrorPopup from "../LoaderAndNotification/ErrorPopup";
import LoaderPopup from "../LoaderAndNotification/LoaderPopup";
import { Link, useNavigate } from "react-router-dom";
import ReservationFormTemplate from "./ReservationFormTemplate";
import { capitalizeFullName, nameValidation } from "../../helpers/commonHelper";
import { Margin } from "@mui/icons-material";

const ReservationForm = ({
  loading,
  loading2,
  loading3,
  reservationData,
  openModal,
  closeErrorModal,
  closeErrorModal2,
  closeErrorModal3,
  erroModalData,
  erroModalData2,
  erroModalData3,
  navigateUrl,
  updateConfirmationNo,
  page,
  getConfirmationNoOfSelectedDiv,
  propertyDetails,
  disptachUserDetails,
  proceedHandler,
}) => {
  return (
    <div>
      <main>
        <div className="main-content reservation-white">
          <div>
            <div>
              <button
                onClick={() => {
                  openModal();
                }}
                type="button"
                className="btn home-btn"
              >
                <i className="ri-arrow-go-back-line mr-3"></i>RESTART
              </button>
            </div>
          </div>

          <div>
            <div className="reservation-info-title text-center">
              {typeof reservationData === "object" &&
                !Array.isArray(reservationData) && (
                  <h5>
                    <strong>
                      {nameValidation(
                        reservationData?.firstName,
                        reservationData?.lastName
                      )}
                    </strong>
                  </h5>
                )}
              {typeof reservationData === "object" &&
                !Array.isArray(reservationData) &&
                reservationData !== null && (
                  <p>
                    Here are your reservation details. Kindly review and confirm
                    the information
                  </p>
                )}
              {Array.isArray(reservationData) && (
                <p>
                  You have <strong>{reservationData.length}</strong>{" "}
                  reservations under your name. Please select{" "}
                  <strong>one</strong> to proceed
                </p>
              )}
            </div>

            <div className="card-scroll">
              {Array.isArray(reservationData) &&
                reservationData.map((userData, index) => (
                  <div
                    key={userData?.pmsConfirmationNo}
                    onClick={() => {
                      getConfirmationNoOfSelectedDiv(
                        userData?.pmsConfirmationNo
                      );
                    }}
                    className="reservation-card-width"
                  >
                    <div
                      className="card"
                      style={{
                        border:
                          userData?.pmsConfirmationNo === updateConfirmationNo
                            ? "2px solid blue"
                            : "",
                      }}
                    >
                      <ReservationFormTemplate
                        reservationData={userData}
                        multipleUserData={reservationData}
                        propertyDetails={propertyDetails}
                      />
                    </div>
                  </div>
                ))}
            </div>

            {typeof reservationData === "object" &&
              !Array.isArray(reservationData) &&
              reservationData !== null && (
                <div
                  key={reservationData?.pmsConfirmationNo}
                  className="reservation-card-width"
                >
                  <div className="card">
                    <ReservationFormTemplate
                      reservationData={reservationData}
                      propertyDetails={propertyDetails}
                    />
                  </div>
                </div>
              )}
          </div>

          <LoaderPopup loading={loading} />
          <LoaderPopup loading={loading2} />
          <LoaderPopup loading={loading3} />
          <ErrorPopup
            closeErrorModal={closeErrorModal}
            erroModalData={erroModalData}
          />
          <ErrorPopup
            closeErrorModal={closeErrorModal2}
            erroModalData={erroModalData2}
          />
          <ErrorPopup
            closeErrorModal={closeErrorModal3}
            erroModalData={erroModalData3}
          />

          <div className="reservation-btn-set">
            <div className="reservation-btn">
              <button
                className="btn cancel-blue-btn"
                onClick={() => {
                  openModal();
                }}
              >
                Cancel
              </button>
            </div>
            <div className="reservation-btn">
              {page !== "passportscan" && (
                <button
                  onClick={() => {
                    proceedHandler();
                  }}
                  className="btn button-blue proceed-blue-btn"
                >
                  Proceed
                </button>
              )}

              {page === "passportscan" && (
                <button
                  onClick={() => {
                    proceedHandler();
                  }}
                  // disabled={!updateConfirmationNo}
                  className="btn button-blue proceed-blue-btn"
                >
                  Proceed
                </button>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ReservationForm;
