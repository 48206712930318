/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from "react-router-dom";

const DoneModal = ({ closeModal, navigateUrl }) => {
  return (
    <div className="modal" style={{ zIndex: "1" }}>
      <div className="modal-dialog modal-lg modal-top">
        <div className="modal-content modal-bg">
          <div className="modal-body">
            <div className="text-center">
              <div>
                <h3>
                  <strong>
                  Upgrade Summary
                  </strong>
                 <br />
                </h3>
              </div>

              <div>
                <div className="done-modal-table">
                  <div className="d-flex justify-content-end total-text">
                    <p className="" style={{fontSize: "20px"}}>Total</p>
                  </div>

                  <div className="d-flex justify-content-between first-row-content">
                    <div className="">
                      <p style={{fontSize: "20px"}}>Breakfast</p>
                    </div>
                    <div>
                      <p style={{fontSize: "20px"}}>$ 25.78</p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between second-row-content">
                    <div className="">
                      <p style={{fontSize: "20px"}}>Room</p>
                    </div>
                    <div>
                      <p style={{fontSize: "20px"}}>$ 156</p>
                    </div>
                  </div>
                  
                  <div className="d-flex justify-content-end all-total-text" style={{marginTop: "15px"}}>
                    <p className="">
                      <strong style={{fontSize: "25px"}}>Total: </strong>
                      <span style={{fontSize: "25px", color:'black'}}> $ 188.77</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center mt-30">
              <button
                className="btn modal-no-btn"
                data-bs-dismiss="modal"
                onClick={closeModal}
              >
                No
              </button>
              <Link to={navigateUrl}>
                <button className="btn modal-yes-btn bg-transperent">
                  Yes
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoneModal;
