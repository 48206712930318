export const reservationSample = {
  statusCode: 200,
  status : 200,
  message: "Success",
  data:  {
    reservationID: 0,
    pmsConfirmationNo: "19489691",
    arrivalDate: "2021-09-25T00:00:00",
    departureDate: "2021-09-26T00:00:00",
    reservationStatus: "CLEAN",
    reservationType: null,
    roomTypeName: "Delux",
    paymentType: null,
    roomNo: "306",
    rateCode: "DAILYBAR",
    rateCodeAmount: 40,
    adultCount: 1,
    childCount: null,
    reservationNameID: "280184",
    visitPurposeID: null,
    isVerified: null,
    isSkipped: null,
    isCompleted: null,
    isPrecheckoutDone: null,
    isPreCheckInDone: null,
    preCheckInDate: null,
    isPreCheckinMail: null,
    isPreCheckOutMail: null,
    printRate: false,
    balanceAmount: null,
    totalAmount: 500.0,
    totalFixedCharges: null,
    totalPackageAmount: null,
    totalBaseRate: null,
    totalTaxesAndFees: null,
    totalRoomRateAndPackages: null,
    isPrimary: true,
    createdDate: null,
    updatedDate: null,
    externalReference: null,
    isActive: null,
    hotelCode: null,
    pmsProfileID: "532312",
    title: null,
    firstName: "Rakesh Valan",
    middleName: null,
    lastName: "Louis",
    arabicName: null,
    gender: "",
    fullName: null,
    dateofBirthDT: "1984-05-01T00:00:00",
    guestType: null,
    documentNumber: null,
    documentType: null,
    nationality: null,
    issueDate: null,
    expiryDate: null,
    issueCountry: null,
    issuePlace: null,
    countryOfResidence: null,
    birthPlace: null,
    emiratesName: null,
    accessibilityTypeDescr: null,
    emailType: "PRIM",
    email: "sample@gmail.com",
    phoneType: null,
    phoneNo_UAE: "(555) 555-1234",
    phoneNo_Res: "(555) 555-1234",
    addressType: null,
    city: null,
    state: null,
    pincode: null,
    addressLine1: null,
    noPost: false,
    reservationSourceName: null,
    account: null,
    arrivalTime: null,
    departureTime: null,
    roomStatus: null,
    guestDetailID: null,
    isVVIP: null,
    resortFee: null,
    currency: null,
    folioTaxItems: null,
    preferences: null,
    packages: null,
    folioItems: null,
    folioTotal: null,
    statusCode: 200,
    status : 200,
  },
  result: true,
};

export const profileSample = {
  result: true,
  message: "Success",
  status : 200,
  statusCode: 200,
  data: [
    {
      profileID: 1,
      reservationID: 1,
      pmsConfirmationNo: null,
      title: "Mr.",
      documentNumber: null,
      documentType: null,
      emailType: null,
      email: "sample@gmail.com",
      nationality: null,
      nationalityCountry: null,
      issueDate: null,
      expiryDate: null,
      firstName: "Swapnil",
      middleName: null,
      lastName: "Guduru",
      arabicName: null,
      issueCountry: null,
      issuePlace: null,
      countryOfResidence: "USA",
      countryOfResidenceId: 242,
      birthPlace: "105",
      emiratesName: "Sharjah",
      phoneType: null,
      phoneNo_UAE: null,
      phoneNo_Res: "09980514529",
      gender: "Male",
      addressType: null,
      city: "qasimiya",
      state: "Sharjah",
      pincode: "0909099",
      fullName: null,
      dateofBirthDT: null,
      pmsProfileID: "91",
      sharerFlag: null,
      addressLine1: "",
      sharerID: null,
      isDocVerified: false,
      isFaceMatched: false,
      isFaceManuallyVerified: false,
      isSkipable: null,
      isFaceEnabled: null,
      isPrimary: true,
    },
    {
      profileID: 4,
      reservationID: 1,
      pmsConfirmationNo: null,
      title: "Mr.",
      documentNumber: "784199385175171",
      documentType: "IL",
      emailType: null,
      email: "email@address.com",
      nationality: 105,
      nationalityCountry: null,
      issueDate: "01/01/1900 00:00:00",
      expiryDate: "07/21/2024 00:00:00",
      firstName: "QWERTY",
      middleName: "",
      lastName: "TYUIOP",
      arabicName: "NAIM CHAND SH",
      issueCountry: 0,
      issuePlace: null,
      countryOfResidence: "USA",
      countryOfResidenceId: 242,
      birthPlace: "",
      emiratesName: "United Arab Emirates",
      phoneType: null,
      phoneNo_UAE: "",
      phoneNo_Res: "0303441111",
      gender: "M",
      addressType: null,
      city: "qasimiya",
      state: "ARE",
      pincode: "0909099",
      fullName: "NAIM CHAND SH",
      dateofBirthDT: "1993-06-30T00:00:00",
      pmsProfileID: null,
      sharerFlag: null,
      addressLine1: "",
      sharerID: null,
      isDocVerified: true,
      isFaceMatched: false,
      isFaceManuallyVerified: false,
      isSkipable: null,
      isFaceEnabled: null,
      isPrimary: false,
    },
  ],
};


export const roomListSample = {
    result: true,
    status : 200,
    data: {
      statusCode: 200,
      message: "Success",
      data: [
        {
          roomNumber: "306",
          roomTypeCode: "SUP",
          roomStatus: "CL"
        }      
      ],
      result: true
    },
    message: "Success"
  }


export const tokenSample = {
  result: true,
  message: "Success",
  token:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJKV1RTZXJ2aWNlQWNjZXNzVG9rZW4iLCJqdGkiOiI4ZDRkZTM2MS03OWE2LTRiNjUtODI3Yi1hZTZlNDBhMWFhNWQiLCJpYXQiOiIxMC8yLzIwMjMgMTE6MjY6NDIgQU0iLCJVc2VyTmFtZSI6ImFkbWluIiwiVmVuZG9yQ29kZSI6IiIsImxpY2Vuc2Vjb2RlIjoiN0kyWS1JREdZLTJUQU4tR1RFMi1UVFUwIiwiZGV2aWNlaWQiOiIxOTIuMTY4LjUyLjE4Ni4xIiwiZXhwIjoxNjk2MjQ5NjAyLCJpc3MiOiJTYW1zb3RlY2hIdWJHYXRld2F5U2VydmVyIiwiYXVkIjoiSldUU2VydmljZVNhbXNvSHViR2F0ZXdheUNsaWVudCJ9.h5FZgyq5vy4YUkU--4rtdKdAYy6fssk587cwwIVX_oE",
  expires: "2023-10-02T12:26:42.9615023Z",
  refreshToken: "1b85afe7718f446f88b591a30d479c39",
  status : 200
};



export const propertySample = {
    result: true,
    status : 200,
    data: {
      property: "Northland Properties",
      currency: "$"
    }
  }

  export const termsAndConditionSample = {
    result: true,
    status : 200,
    data: [
      {
        language: "EN",
        direction: "LTR",
        content: "\r\nThe official hotel departure time is 12 noon.\r\nLate departures can be requested through the Front Desk, subject to hotel availability.  Additional charges may be applied.\r\nDeparture date extensions are subject to hotel availability and changes in rate may be applied. Early departure date charges may be applied.\r\nExtra bed charges will be applied as per the hotels policy.\r\nChildren must be attended and supervised by an adult at all times. The management is not and will not be liable for any unattended child. \r\nAll guests and visitors must be registered.\r\nThe hotel is not responsible for any loss of money or valuables left in the room.",
        moreInfo: "Room rates are subject to 10% Service Charge, 4% Tourism Fee and 2% Municipality Fee. %5 VAT is applied to Room Rate and Service Charge. Your stay is subject to AED 15.00 Municipality Room Fee per bedroom per night."
      },
      {
        language: "AR",
        direction: "RTL",
        content: "",
        moreInfo:""
        // content: "\r\nتخضع أسعار الغرف لـ 10٪ رسوم خدمة، و 4 ٪ رسوم السياحة و 2 ٪ رسوم البلدية.\r\nيتم تطبيق  5 ٪ ضريبة القيمة المضافة على سعر الغرفة ورسوم الخدمة. تخضع إقامتك إلى 10.00 درهم رسم البلدية لكل غرفة نوم في الليلة الواحدة.\r\nوقت تسليم الغرف هو 12 ظهراً.\r\n. يخضع تمديد الإقامة في الفندق للتوافر وقد يتغيير سعر الغرفة. وقد يتم تطبيق رسوم على تسجيل الخروج المبكر.\r\nسيتم تطبيق رسوم السرير الإضافي وفقاً لسياسة الفندق.\r\nيجب الإشراف على الأطفال من قبل شخص بالغ في جميع الأوقات.\r\n",
        // moreInfo: "\r\nإدارة الفندق غير مسؤولة عن أي طفل غير مراقب.\r\nيجب تسجيل بيانات جميع الضيوف والزوار.\r\nالفندق غير مسؤول عن أي خسارة في الأموال أو الأشياء الثمينة المتبقية في الغرف."
      }
    ],
    message: "Success"
  }

  export const responseResultSample = {
    result: true,
    status : 200,    
    statusCode: 200,
    message: "Success"
  }