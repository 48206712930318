/* eslint-disable jsx-a11y/alt-text */
// import WhiteTextLogo from "../../assets/images/rotana-logo.png";
import "../../assets/stylesheet/footer.css";
import DrawIcon from "@mui/icons-material/Draw";
import FooterTimer from "./FooterTimer";

const Footer = ({
  page,
  openModal,
  timerValue,
  showModal,
  restartTimer,
  stopTimer,
  timerCounter,
  timeLimit,
}) => {
  const pages = [
    "documentscanning",
    "facerecognition",
    "upgrade",
    "signature",
    "payment",
    "key",
    "thankyou",
  ];

  const activeIcons = {
    documentscanning: "activeDocumentIcon",
    facerecognition: "activeFaceIcon",
    upgrade: "activeUpgradeIcon",
    signature: "activeSignatureIcon",
    payment: "activePaymentIcon",
    key: "activeKeyIcon",
    thankyou: "activeAllDoneIcon",
  };

  let activeIcon = "";
  let activeState = {};

  let pageIndex = pages.indexOf(page);

  if (pageIndex !== -1) {
    for (let i = 0; i <= pageIndex; i++) {
      let currentPage = pages[i];
      activeIcon =
        activeIcons[currentPage] === "activeAllDoneIcon"
          ? "active"
          : activeIcons[currentPage];

      if (activeIcons[currentPage] !== activeIcons[pages[pageIndex]]) {
        activeState[currentPage + "Activated"] = "activated";
      }
      if (activeIcons[currentPage] === "activeAllDoneIcon") {
        activeState[currentPage + "Activated"] = "activated";
      }
    }
  }

  return (
    <footer>
      {/* <div className="footer-logo">
        <img src={WhiteTextLogo} />
      </div> */}
      <div className="tracker-status">
        <div
          className={`mat-icon-wrap ${
            page === "confirmation" ? "active" : "activated"
          }`}
        >
          <span className="bx bx-user-check mat-icon material-symbols-rounded"></span>
          <span className="title">Confirmation</span>
        </div>
        <span className="mat-line"></span>
        <div
          className={`mat-icon-wrap ${
            activeIcon === "activeDocumentIcon"
              ? "active"
              : activeState?.documentscanningActivated
          }`}
        >
          <span className="bx bx-scan mat-icon material-symbols-rounded"></span>
          <span className="title">Document scanning</span>
        </div>
        <span className="mat-line"></span>
        <div
          className={`mat-icon-wrap ${
            activeIcon === "activeFaceIcon"
              ? "active"
              : activeState?.facerecognitionActivated
          }`}
        >
          <span className="mat-icon material-symbols-rounded">
            familiar_face_and_zone
          </span>
          <span className="title">Face recognition</span>
        </div>
        <span className="mat-line"></span>
        <div
          className={`mat-icon-wrap ${
            activeIcon === "activeUpgradeIcon"
              ? "active"
              : activeState?.upgradeActivated
          }`}
        >
          <span className="bx bx-chevrons-up mat-icon material-symbols-rounded"></span>
          <span className="title">Upgrade</span>
        </div>
        <span className="mat-line"></span>
        <div
          className={`mat-icon-wrap ${
            activeIcon === "activeSignatureIcon"
              ? "active"
              : activeState?.signatureActivated
          }`}
        >
          <span className=" mat-icon material-symbols-rounded">
            <DrawIcon fontSize="small" />
          </span>
          <span className="title">Signature</span>
        </div>
        <span className="mat-line"></span>
        <div
          className={`mat-icon-wrap ${
            activeIcon === "activePaymentIcon"
              ? "active"
              : activeState?.paymentActivated
          }`}
        >
          <span className="bx bxs-credit-card mat-icon material-symbols-rounded"></span>
          <span className="title">Payment</span>
        </div>
        <span className="mat-line"></span>
        <div
          className={`mat-icon-wrap ${
            activeIcon === "activeKeyIcon"
              ? "active"
              : activeState?.keyActivated
          }`}
        >
          <span className="bx bxs-key mat-icon material-symbols-rounded"></span>
          <span className="title">Key</span>
        </div>
        <span className="mat-line"></span>
        <div
          className={`mat-icon-wrap ${
            activeIcon === "activeAllDoneIcon"
              ? "active"
              : activeState?.thankyouActivated
          }`}
        >
          <span className="mat-icon material-symbols-rounded">thumb_up</span>
          <span className="title">All Done</span>
        </div>
      </div>

      {/* 80 */}
      <FooterTimer
        openModal={openModal}
        timerValue={timerValue}
        timerCounter={timerCounter}
        restartTimer={restartTimer}
        showModal={showModal}
        stopTimer={stopTimer}
        timeLimit={timeLimit}
      />
    </footer>
  );
};

export default Footer;
