/* eslint-disable jsx-a11y/alt-text */
import Logo from "../assets/images/rotana-logo.PNG";
import Footer from "./Layout/Footer";
import { Link, useNavigate } from "react-router-dom";
import "../assets/stylesheet/confirmation.css";
import i18next from "i18next";
import { useState } from "react";
import { useEffect } from "react";
import { userReservationDetailsQr } from "../store/Checkin/QrCode/qrCodeSlice";
import { userReservationDetailsPassport } from "../store/Checkin/Scan/scanPassportSlice";
import { userReservationDetailsConfirmatioNo } from "../store/Checkin/ConfirmationNumber/confirmationNumberSlice";
import { useDispatch } from "react-redux";
import { thankyouPageTimerValue } from "../constants/constant";

const Thankyou = ({ userData }) => {
  const timeLimit = thankyouPageTimerValue;
  const [showModal, setShowModal] = useState(false);
  const [timerValue, setTimerValue] = useState(timeLimit);
  const [restartTimer, setRestartTimer] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const homePageHandler = () => {
    localStorage.removeItem("confirmationNo");
    localStorage.removeItem("token");
    i18next.changeLanguage("en");
    dispatch(userReservationDetailsQr({}));
    dispatch(userReservationDetailsPassport({}));
    dispatch(userReservationDetailsConfirmatioNo({}));
    navigate("/");
    return;
  };

  useEffect(() => {
    if (timerValue === 0) {
      homePageHandler();
    }
  }, [timerValue]);

  const timerCounter = (value) => {
    setTimerValue(value);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const stopTimer = () => {
    setRestartTimer(false);
  };

  return (
    <div>
      <main>
        <div className="main-content">
          <div>
            <div>
              {/* <Link to="/" onClick={homePageHandler} className="btn home-btn">
                <i className="ri-arrow-go-back-line mr-3"></i>RESTART
              </Link> */}
            </div>
          </div>
          <div className="thank-you-box thank-you-center">
            <div>
              <img src={Logo} className="thank-logo-img" />
            </div>

            {/* <div>
              <img src={QrImage} className="thank-qr-img" />
            </div> */}
            <div className="mt-30 font-weight-500 font-14">
              <div>
                <h2 style={{ fontWeight: "bold" }}>
                  {" "}
                  Room Number: {userData?.roomNo || "-"}
                </h2>
              </div>

              <h1 className="mb-0" style={{marginTop: "15px", color: "yellowgreen"}}>Thank you for choosing us! </h1>
              <hr/>
              <p className="mb-0" style={{marginTop: "10px"}}>                
                Please proceed to your Room.
              </p>
              <p className="mt-10 mb-0">
                We wish you a pleasent stay with us here.
              </p>
              <p className="mt-10">
                Please find the check-in receipt on your registered email.
              </p>
            </div>
          </div>
        </div>
        <Footer
          page={"thankyou"}
          showModal={showModal}
          openModal={openModal}
          timerCounter={timerCounter}
          timerValue={timerValue}
          restartTimer={restartTimer}
          stopTimer={stopTimer}
          timeLimit={timeLimit}
        />
      </main>
    </div>
  );
};

export default Thankyou;
