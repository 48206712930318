export const saveParametersIntoLocalStorage = (dataKey, data) => {
  localStorage.setItem(dataKey, JSON.stringify(data));
};

export const removeParametersFromLocalStorage = (datakey, data) => {
  localStorage.removeItem(datakey, JSON.stringify(data));
};

export const getParametersIntoLocalStorage = (datakey, data) => {
  let res = localStorage.getItem(datakey);
  if (res) {
    return JSON.parse(res);
  }
  return {};
};

export const getParametersFromLocalStorage = (datakey, data) => {
  let res = localStorage.getItem(datakey);
  if (res) {
    return JSON.parse(res);
  }
  return {};
};

export const formatDate = (date) => {
  if (!date) return "-";
  const options = { day: "2-digit", month: "short", year: "numeric" };
  const formattedDate = new Date(date)?.toLocaleDateString("en-US", options);

  // Split the formatted date into day, month, and year parts
  const [month, day, year] = formattedDate.split(" ");

  // Convert the month abbreviation to uppercase
  const capitalizedMonth = month.toUpperCase();

  // Return the formatted date with uppercase month abbreviation and desired format
  return `${day?.toString().replace(/,/g, "")}/${capitalizedMonth}/${year}`;
};

export const capitalizeFullName = (fullName) => {
  const words = fullName.split(" ");
  const capitalizedWords = words.map((word) => {
    if (word.length > 0) {
      return word[0].toUpperCase() + word.slice(1).toLowerCase();
    } else {
      return word;
    }
  });
  const capitalizedFullName = capitalizedWords.join(" ");

  return capitalizedFullName;
};

export const nameValidation = (firstName, lastName) => {
  let sortedName = "";
  if (firstName && lastName) {
    sortedName = capitalizeFullName(firstName + " " + lastName);
  } else if (!firstName && lastName) {
    sortedName = " -" + capitalizeFullName(" " + lastName);
  } else if (firstName && !lastName) {
    sortedName = capitalizeFullName(firstName);
  } else {
    sortedName = "-";
  }
  return sortedName;
};

export const verifyScanData=(scanData,reservationDetails,guestCount)=>
{
  let scanResult = false;
  let flipDocument = false;
  let error="";
  if(scanData.data!==null && scanData.DocumentType==="P")
  {
    
    flipDocument=false;
    scanResult=true;
  }
  if(scanData.data!==null && scanData.DocumentType!=="P")
  {
    if(scanData.data.DocumentImageSide2===null||scanData.data.DocumentImageSide2==="")
    {
     flipDocument=true
     scanResult=true;
    }
    else{
      flipDocument=false
      scanResult=true;
    }

  }
  else
  {
    scanResult=false;
    flipDocument=false;
  }
  return [scanResult,flipDocument];
}
