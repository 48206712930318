import { useState, useEffect } from "react";
import moment from "moment";
import {
  getPropertyData,
  getReservationDetails,
  navigationPath,
} from "../../../network/apiService";
import ReservationForm from "../../../components/Forms/ReservationForm";
import Footer from "../../../components/Layout/Footer";
import HomePageCard from "../../../components/Layout/Card/HomePageCard";
import { timerConstantValue } from "../../../constants/constant";
import { useDispatch } from "react-redux";
import { userReservationDetailsConfirmatioNo } from "../../../store/Checkin/ConfirmationNumber/confirmationNumberSlice";
import { useNavigate } from "react-router-dom";

const ConfirmationNumberReservationForm = () => {
  const timeLimit = timerConstantValue;
  let noOfNights = null;
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [timerValue, setTimerValue] = useState(timeLimit);
  const [restartTimer, setRestartTimer] = useState(false);
  const [erroModalData, setErrorModalData] = useState({
    toggleError: false,
    errorMessage: "",
  });
  const [erroModalData2, setErrorModalData2] = useState({
    toggleError: false,
    errorMessage: "",
  });
  const [erroModalData3, setErrorModalData3] = useState({
    toggleError: false,
    errorMessage: "",
  });
  const [reservationData, setReservationData] = useState({
    firstName: "",
    confirmationNo: "",
    roomNo: "",
    arrivalDate: "",
    departureDate: "",
    nights: "",
    adults: "",
    child: "",
    checkoutTime: "",
    roomType: "",
  });
  const [propertyDetails, setPropertyDetails] = useState();
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const confirmNo = localStorage.getItem("confirmationNo");

  //api call for reservation form.
  useEffect(() => {
    if (confirmNo) {
      fetchReservationDetails({ ConfirmationNo: confirmNo });
    }
  }, [confirmNo]);
  
  const fetchReservationDetails = async (query) => {
    try {
      setLoading(true);
      const response = await getReservationDetails(query, token);
      const propertyResponse = await getPropertyData(token);

      debugger
      // data of latest state of reservation form api call
      if (
        response?.statusCode === 200
      ) {
        setLoading(false);
        dispatch(userReservationDetailsConfirmatioNo(response?.data));
        setReservationData(response?.data);
        setPropertyDetails(propertyResponse?.data);
      }
      if (response.data.statusCode === 102) {
        setErrorModalData((state) => ({
          ...state,
          toggleError: true,
          errorMessage: response.data.message,
        }));
        setLoading(false);
        return;
      }
    } catch (error) {
      setErrorModalData((state) => ({
        ...state,
        toggleError: true,
        errorMessage: error.message,
      }));
      setLoading(false);
    }
  };

  const resetTimerAndClosePopUp = () => {
    if (!timerValue) {
      setTimerValue(timeLimit);
    }
    setRestartTimer(true);
    setShowModal(false);
  };

  const timerCounter = (value) => {
    setTimerValue(value);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const stopTimer = () => {
    setRestartTimer(false);
  };

  const closeErrorModal = () => {
    setErrorModalData((state) => ({
      ...state,
      toggleError: false,
      errorMessage: "",
    }));
  };

  const proceedHandler = async () => {
    setLoading2(true);
    

    setTimeout(() => {
      setLoading(false);
      return navigate("/confirmation-no-document-verification");
    }, 2000);

    // if (!reservationData?.reservationStatus) {
    //   setErrorModalData((state) => ({
    //     ...state,
    //     toggleError: true,
    //     errorMessage: "Please visit front desk for assistance",
    //   }));
    //   return;
    // } else {
     
    // }
  };

  return (
    <div>
      <ReservationForm
        loading={loading}
        loading2={loading2}
        reservationData={reservationData}
        openModal={openModal}
        closeErrorModal={closeErrorModal}
        erroModalData={erroModalData}
        propertyDetails={propertyDetails}
        proceedHandler={proceedHandler}
        erroModalData2={erroModalData2}
        erroModalData3={erroModalData3}
      />
      <Footer
        page={"documentscanning"}
        showModal={showModal}
        openModal={openModal}
        timerCounter={timerCounter}
        timerValue={timerValue}
        restartTimer={restartTimer}
        stopTimer={stopTimer}
        timeLimit={timeLimit}
      />
      {showModal && (
        <HomePageCard
          showModal={showModal}
          resetTimerAndClosePopUp={resetTimerAndClosePopUp}
        />
      )}
    </div>
  );
};

export default ConfirmationNumberReservationForm;
