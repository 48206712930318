import axios from "axios";
import { API_URL, API_BASE_URL2 } from ".";
import { profileSample, propertySample, reservationSample, roomListSample, termsAndConditionSample, tokenSample, responseResultSample } from "../constants/reservationSample";

//axios header setup
axios.defaults.baseURL = API_URL;
axios.defaults.headers.common["Accept"] = "application/json, text/plain, */*";
axios.defaults.headers.post["Content-Type"] = "application/json";
// axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

// const axios2 = axios.create({
//   baseURL: API_BASE_URL2,
//   headers: {
//     common: {
//       Accept: "application/json, text/plain, */*",
//     },
//     post: {
//       "Content-Type": "application/json",
//     },
//   },
// });

const axios2 = axios.create({
  baseURL: API_BASE_URL2,
  headers: {
    common: {
      Accept: "application/json, text/plain, */*",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
    },
    post: {
      "Content-Type": "application/json",
    },
  },
});

// auth api
export const loginApi = (data) => {
  //return axios.post("api/Authentication/GenerateToken", data);
  return tokenSample;
};

// Generate Token api
export const generateTokenApi = (data) => {
  //return axios.post("api/Authentication/GenerateToken", data);
  return tokenSample;
};


//***************************For Scanning******************************* */


// get clear scan/qr data
export const clearScanData = () => {
  return axios2.get(`scanner/ClearResults`);
};

// get scan/qr data
export const getScanData = () => {
  return axios2.get(`scanner/scan`);
};



// get start face verification scan/qr data
export const faceVerification = () => {
  return axios2.get(`scanner/StartFaceVerification`);
};



















//***************************  Get APIS. **********************//

//get user details
export const getReservationDetails = (data, token) => {
  const searchParams = new URLSearchParams(data);
  let query = searchParams.toString();
  // return axios.get(`api/Kiosk/GetReservationDetails?${query}`, {
  //   headers: { Authorization: `Bearer ${token}` },
  // });

  return reservationSample;
};

//GetAllProfile by ConfirmationNo
export const getAllProfile = (data, token) => {
  const searchParams = new URLSearchParams(data);
  let query = searchParams.toString();
  // return axios.get(`api/Kiosk/GetAllProfiles?${query}`, {
  //   headers: { Authorization: `Bearer ${token}` },
  // });
  return profileSample;
};

//GetAvailableRooms
export const getAvailableRooms = (data, token) => {
  const searchParams = new URLSearchParams(data);
  let query = searchParams.toString();
  // return axios.get(`api/Kiosk/GetAvailableRooms?${query}`, {
  //   headers: { Authorization: `Bearer ${token}` },
  // });

  return roomListSample;
};

// Is early checkin
export const isEarlyCheckin = (data, token) => {
  const searchParams = new URLSearchParams(data);
  let query = searchParams.toString();
  // return axios.get(`api/Kiosk/VerifyEarlyCheckIn?${query}`, {
  //   headers: { Authorization: `Bearer ${token}` },
  // });
  return responseResultSample;
};

// navigation url/ stage api
export const navigationPath = (data, token) => {
  const searchParams = new URLSearchParams(data);
  let query = searchParams.toString();
  return axios.get(`api/Kiosk/GetNavigationStep?${query}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

// Get terms and conditions data
export const getTermsAndConditons = (token) => {
  // return axios.get(`api/Reservation/GetTermsAndCondition`, {
  //   headers: { Authorization: `Bearer ${token}` },
  // });
  return termsAndConditionSample;
};

export const getPropertyData = (token) => {
  // return axios.get(`api/Master/GetPropertyInfo`, {
  //   headers: { Authorization: `Bearer ${token}` },
  // });
  return propertySample;
};

// get scan/qr data
// export const getScanData = (token) => {
//   return axios2.get(`scanData`);
// };

// ******************** Post APIS ************************//

// AssignRoom
export const assignRoom = (data, token) => {
  // return axios.post("api/Kiosk/AssignRoom", data, {
  //   headers: { Authorization: `Bearer ${token}` },
  // });
  return responseResultSample;
};

//Add Package
export const addPackage = (data, token) => {
  // return axios.post("api/Kiosk/AddPackage", data, {
  //   headers: { Authorization: `Bearer ${token}` },
  // });
  return responseResultSample;
};

// Get Room status
export const getRoomStatus = (data, token) => {
  // return axios.post("api/Kiosk/GetRoomStatus", data, {
  //   headers: { Authorization: `Bearer ${token}` },
  // });
  return responseResultSample;
};

//Room upsell
export const roomUpsell = (data, token) => {
  // return axios.post("api/Kiosk/RoomUpsell", data, {
  //   headers: { Authorization: `Bearer ${token}` },
  // });
  
  return responseResultSample;
};

//update user Profile - reservation details page
export const updateUserProfile = (data, token) => {
  // return axios.post("api/Kiosk/UpdateProfile", data, {
  //   headers: { Authorization: `Bearer ${token}` },
  // });
  return responseResultSample;
};

//checkin - after payment call this api
export const checkIn = (data, token) => {
  // return axios.post("api/Kiosk/CheckIn", data, {
  //   headers: { Authorization: `Bearer ${token}` },
  // });
  return responseResultSample;
};

//checkout
export const checkOut = (data, token) => {
  // return axios.post("api/Kiosk/CheckOut", data, {
  //   headers: { Authorization: `Bearer ${token}` },
  // });
  return responseResultSample;
};

//Encode key - create key page api call
export const encodeKey = (data, token) => {
  // return axios.post("api/Kiosk/EncodeKey", data, {
  //   headers: { Authorization: `Bearer ${token}` },
  // });
  return responseResultSample;
};

// Save reg card  - (signature pad api call)
export const saveRegistrationCard = (data, token) => {
  // return axios.post("api/Reservation/SaveRegistrationCard", data, {
  //   headers: { Authorization: `Bearer ${token}` },
  // });
  return responseResultSample;
};

//trigger payment
export const triggerPayment = (data, token) => {
  // return axios.post("api/Payment/InitPayment", data, {
  //   headers: { Authorization: `Bearer ${token}` },
  // });
  return responseResultSample;
};
