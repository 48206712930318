import { configureStore } from "@reduxjs/toolkit";
import AuthReducer from "./AuthSlice";
import scanpassportReducer from "./Checkin/Scan/scanPassportSlice";

import scandocumentReducer from  "./Checkin/Scan/documentScanSlice";

import confirmationNumberReducer from "./Checkin/ConfirmationNumber/confirmationNumberSlice";
import qrcodeReducer from "./Checkin/QrCode/qrCodeSlice";
import checkoutReducer from "./Checkout/checkoutSlice"
import reservationFormReducer from "./Checkin/reservationformSlice";
import faceVerificationReducer from "./Checkin/faceVerificationSlice";
import upgradeRoomReducer from "./Checkin/upgradeRoomsSlice";
import reservationDetailsReducer from "./Checkin/reservationDetailsSlice";

const store = configureStore({
  reducer: {
    AuthReducer,
    scanpassport: scanpassportReducer,
    scandocument: scandocumentReducer,
    confirmation: confirmationNumberReducer,
    qrscanning: qrcodeReducer,
    checkout: checkoutReducer,
    reservationform: reservationFormReducer,
    userFaceVerification: faceVerificationReducer,
    upgradeUserRoom: upgradeRoomReducer,
    reservationDetails: reservationDetailsReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
