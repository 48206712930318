/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import "../../assets/stylesheet/upgrade-option.css";
import HomePageCard from "../Layout/Card/HomePageCard";
import BreakFast from "../../assets/images/breakfast.jpg";
import Room from "../../assets/images/room.jpg";
import EarlyCheckOut from "../../assets/images/early-check-out.jpg";
import AirportDropOff from "../../assets/images/airport-drop-off.jpg";
import UpgradeRoom from "./UpgradeRoom";
import SkipModal from "../Layout/Card/SkipModal";
import DoneModal from "..//Layout/Card/DoneModal";
import Footer from "../Layout/Footer";



const Upgrade = ({
  skipHandler,
  upgradeRoomModal,
  toggleSkipModal,
  toggleDoneModal,
  timerValue,
  restartTimer,
  upgrade,
  roomOptions,
  openModal,
  skipModal,
  resetStates,
  removeRoomOptions,
  openUpgradeModal,
  closeUpgradeModal,
  closeSkipModal,
  closeDoneModal,
  upgradeHandler,
  setUpgrade,
  setRoomOptions,
  navigateUrl,
}) => {
  return (
    <div>
      <main>
        <div className="main-content">
          <div className="d-flex justify-content-between">
            <div>
              <button
                onClick={() => {
                  openModal();
                }}
                className="btn home-btn"
                data-bs-toggle="modal"
                data-bs-target="#home-back"
              >
                <i className="ri-arrow-go-back-line mr-3"></i>RESTART
              </button>
            </div>
            <div>
              <button
                className="btn skip-btn"
                data-bs-toggle="modal"
                data-bs-target="#skip-modal"
                onClick={() => {
                  skipModal();
                }}
              >
                Skip<i className="bx bx-last-page"></i>
              </button>

              {/* <button
                className="btn done-btn"
                data-bs-toggle="modal"
                data-bs-target="#done-modal"
                onClick={() => {
                  resetStates();
                }}
              >
                Done
              </button> */}
            </div>
          </div>
          <div className="page-title mt-0">
           <h3>Please select an upgrade option or Skip</h3>  
          </div>

           <div style={{height: "50%"}}>
           <div className="upgrade-section" style={{marginTop: "60px"}}>
            <div className="upgrade-box">
              <div className="upgrade-image">
                <img src={BreakFast} />
              </div>
              <div className="upgrade-caption">
                <p>Breakfast</p>
                <span>$25.78 / Pack</span>
                {!upgrade.breakFast && (
                  <button
                    onClick={() => {
                      upgradeHandler("breakfast", "selected");
                    }}
                  >
                    Select
                  </button>
                )}
                {upgrade.breakFast && (
                  <button
                    onClick={() => {
                      upgradeHandler("breakfast", "remove");
                    }}
                    style={{
                      backgroundColor: "white",
                      color: "black",
                      border: "2px solid",
                    }}
                  >
                    Remove
                  </button>
                )}
              </div>
            </div>
            <div className="upgrade-box">
              <div className="upgrade-image">
                <img src={Room} />
              </div>
              <div className="upgrade-caption">
                <p>{roomOptions.room || "Room"}</p>
                <span>
                  {roomOptions.price
                    ? "$" + roomOptions.price
                    : "$57 - 156 / Room"}
                </span>
                {!roomOptions.room && (
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#select-upgrade-modal"
                    onClick={() => {
                      openUpgradeModal();
                    }}
                  >
                    Select
                  </button>
                )}
                {roomOptions.room && (
                  <div style={{ display: "flex" }}>
                    <button
                      data-bs-toggle="modal"
                      data-bs-target="#select-upgrade-modal"
                      onClick={() => {
                        openUpgradeModal();
                      }}
                    >
                      Upgrade
                    </button>
                    <button
                      style={{
                        backgroundColor: "white",
                        color: "black",
                        border: "2px solid",
                        marginLeft: "8px",
                      }}
                      onClick={() => {
                        removeRoomOptions();
                      }}
                    >
                      remove
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="upgrade-box">
              <div className="upgrade-image">
                <img src={EarlyCheckOut} />
              </div>
              <div className="upgrade-caption">
                <p>Late check-out</p>
                <span>$16 / Room</span>
                {!upgrade.earlyCheckout && (
                  <button
                    onClick={() => {
                      upgradeHandler("earlycheckout", "selected");
                    }}
                  >
                    Select
                  </button>
                )}
                {upgrade.earlyCheckout && (
                  <button
                    onClick={() => {
                      upgradeHandler("earlycheckout", "remove");
                    }}
                    style={{
                      backgroundColor: "white",
                      color: "black",
                      border: "2px solid",
                    }}
                  >
                    Remove
                  </button>
                )}
              </div>
            </div>
            <div className="upgrade-box">
              <div className="upgrade-image">
                <img src={AirportDropOff} />
              </div>
              <div className="upgrade-caption">
                <p>Airport drop-off</p>
                <span>$23.8+ / Car</span>
                {!upgrade.airportDropOff && (
                  <button
                    onClick={() => {
                      upgradeHandler("airportdropoff", "selected");
                    }}
                  >
                    Select
                  </button>
                )}
                {upgrade.airportDropOff && (
                  <button
                    onClick={() => {
                      upgradeHandler("airportdropoff", "remove");
                    }}
                    style={{
                      backgroundColor: "white",
                      color: "black",
                      border: "2px solid",
                    }}
                  >
                    Remove
                  </button>
                )}
              </div>
            </div>
          </div>

           
            </div>     

         
          <div style={{margin:"25px"}}>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-lg button-blue"
                style={{
                  borderColor: "#03355c",
                }}
                onClick={()=>{
                  resetStates()
                }}  
              >
                PROCEED
                {/* Proceed <i className="bx bx-chevron-right"></i> */}
              </button>
            </div>
          </div>
        </div>
      </main>

      {upgradeRoomModal && (
        <UpgradeRoom
          closeModal={closeUpgradeModal}
          roomOptions={roomOptions}
          setRoomOptions={setRoomOptions}
        />
      )}
      {toggleSkipModal && (
        <SkipModal
          closeModal={closeSkipModal}
          skipHandler={skipHandler}
          navigateUrl={navigateUrl}
        />
      )}
      {toggleDoneModal && (
        <DoneModal
          closeModal={closeDoneModal}
          setUpgrade={setUpgrade}
          setRoomOptions={setRoomOptions}
          navigateUrl= "/confirmation-no-reservation-details"
        />
      )}
    </div>
  );
};

export default Upgrade;
