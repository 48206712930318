import { useState, useEffect } from "react";
import UserReservationDetails from "../../../components/ReservationDetails/UserReservationDetails";
import moment from "moment";
import Footer from "../../../components/Layout/Footer";
import HomePageCard from "../../../components/Layout/Card/HomePageCard";
import { useNavigate } from "react-router-dom";
import {
  addPackage,
  generateTokenApi,
  getReservationDetails,
  saveRegistrationCard,
  updateUserProfile,
} from "../../../network/apiService";
import CheckoutTimeModal from "../../../components/Layout/Card/CheckoutTimeModal";
import {
  resetScanPassportGuestCount,
  userReservationDetails,
  userReservationDetailsPassport,
} from "../../../store/Checkin/Scan/scanPassportSlice";
import { useDispatch } from "react-redux";
import { timerConstantValue } from "../../../constants/constant";

const ReservationDetailsPassportScan = () => {
  const timeLimit = timerConstantValue;
  let noOfNights = null;
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [enterEmailValidation, setEnterEmailValidation] = useState(false);
  const [emailValidation, setEmailValidation] = useState(false);
  const [countryValidation, setCountryValidation] = useState(false);
  const [mobileNoValidation, setMobileNoValidation] = useState(false);
  const [timerValue, setTimerValue] = useState(timeLimit);
  const [restartTimer, setRestartTimer] = useState(false);
  const [toggleCheckoutTimeModal, setToggleCheckoutTimeModal] = useState(false);
  const [refectGetUserDetails, setRefectGetUserDetails] = useState(false);
  const [togglePackageApi, setTogglePackageApi] = useState(false);
  const [timeChanged, setTimeChanged] = useState(false);
  const [reservationData, setReservationData] = useState({
    firstName: "",
    name: "",
    roomNo: "",
    arrivalDate: "",
    departureDate: "",
    nights: "",
    adults: "",
    child: "",
    checkoutTime: "",
    roomType: "",
    roomRate: 0.0,
    totalAmount: 0.0,
  });
  const [confirmationDetails, setConfirmationDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNo: "",
    hrs: "12",
    mins: "00",
    country: "",
    pmsProfileID: "",
    pmsConfirmationNo: "",
  });
  const [erroModalData, setErrorModalData] = useState({
    toggleError: false,
    errorMessage: "",
  });
  const [erroModalData2, setErrorModalData2] = useState({
    toggleError: false,
    errorMessage: "",
  });
  const [erroModalData3, setErrorModalData3] = useState({
    toggleError: false,
    errorMessage: "",
  });
  const [userAgreed, setUserAgreed] = useState(false);
  const [signature, setSignature] = useState(null);
  const [url, setUrl] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  const confirmNo = localStorage.getItem("confirmationNo");

  useEffect(() => {
    if (confirmNo) {
      dispatch(resetScanPassportGuestCount());
      fetchReservationDetails({ ConfirmationNo: confirmNo });
    }
  }, [confirmNo]);

  useEffect(() => {
    if (refectGetUserDetails) {
      fetchReservationDetails({ ConfirmationNo: confirmNo });
    }
  }, [refectGetUserDetails]);

  const findNumberOfNights = (arrivalDate, departureDate) => {
    let noOfNights = null;
    if (arrivalDate && departureDate) {
      const date1 = new Date(moment(arrivalDate).format("YYYY-MM-DD"));
      const date2 = new Date(moment(departureDate).format("YYYY-MM-DD"));
      const timeDiff = date2.getTime() - date1.getTime();
      const nights = Math.ceil(timeDiff / (1000 * 3600 * 24));
      noOfNights = nights;
    }
    return noOfNights;
  };

  const fetchReservationDetails = async (query) => {
    try {
      setLoading(true);
      const response = await getReservationDetails(query, token);

      // data of latest state of reservation form api call
      if (response?.statusCode === 200) {
        setLoading(false);
        dispatch(userReservationDetailsPassport(response?.data));

        noOfNights = findNumberOfNights(
          response?.data?.arrivalDate,
          response?.data?.departureDate
        );

        setReservationData({
          firstName: response?.data?.firstName,
          lastName: response?.data?.lastName,
          name:
            response?.data?.firstName +
              " " +
              response?.data?.lastName || "",
          roomNo: response?.data?.roomNo || "",
          arrivalDate:
            moment(response?.data?.arrivalDate).format("YYYY-MM-DD") ||
            "",
          departureDate:
            moment(response?.data?.departureDate).format("YYYY-MM-DD") ||
            "",
          nights: noOfNights,
          adults: response?.data?.adultCount,
          child: response?.data?.childCount,
          checkoutTime:
            moment(response?.data?.departureDate).format("hh:mm A") || "",
          roomType: response?.data?.roomTypeName || "",
          roomRate: response?.data?.rateCodeAmount || "0.0",
          totalAmount: response?.data?.totalAmount || "0.0",
        });
        setConfirmationDetails({
          email: response?.data?.email,
          mobileNo: Number(response?.data?.phoneNo_UAE)
            ? Number(response?.data?.phoneNo_UAE)
            : "",
          pmsProfileID: response?.data?.pmsProfileID,
          pmsConfirmationNo: response?.data?.pmsConfirmationNo,
        });
      }
      if (response.statusCode === 102) {
        setErrorModalData((state) => ({
          ...state,
          toggleError: true,
          errorMessage: response.message,
        }));
        setLoading(false);
        return;
      }
    } catch (error) {
      setLoading(false);
      setErrorModalData((state) => ({
        ...state,
        toggleError: true,
        errorMessage: error.message,
      }));
    }
  };

  const resetTimerAndClosePopUp = () => {
    if (!timerValue) {
      setTimerValue(timeLimit);
    }
    setRestartTimer(true);
    setShowModal(false);
  };

  const timerCounter = (value) => {
    setTimerValue(value);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const openCheckOutTimeModal = () => {
    setToggleCheckoutTimeModal(true);
  };

  const closeCheckoutTimeModal = () => {
    setToggleCheckoutTimeModal(false);
    setTimeChanged(false);
  };

  const stopTimer = () => {
    setRestartTimer(false);
  };

  const closeErrorModal = () => {
    setErrorModalData((state) => ({
      ...state,
      toggleError: false,
      errorMessage: "",
    }));
  };

  const closeErrorModal2 = () => {
    setErrorModalData2((state) => ({
      ...state,
      toggleError: false,
      errorMessage: "",
    }));
  };

  const closeErrorModal3 = () => {
    setErrorModalData3((state) => ({
      ...state,
      toggleError: false,
      errorMessage: "",
    }));
  };

  const confirmationDetailsHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (name === "mobileNo") {
      setMobileNoValidation(false);
    }
    if (name === "email" && value) {
      setEnterEmailValidation(false);
    }
    if (name === "email" && mailFormat.test(value)) {
      setEmailValidation(false);
    }
    if (name === "country") {
      setCountryValidation(false);
    }
    setConfirmationDetails((state) => ({ ...state, [name]: value }));
  };

  const gotSignature = () => {
    setUrl(signature.getTrimmedCanvas().toDataURL("image/png"));
  };

  const handleCheckboxChange = (e) => {
    setUserAgreed(e.target.checked);
  };

  const navigateToPayment = () => {
    navigate("/passport-scan-payment");
    //navigate("/confirmation-no-upgrade");
  };

  const clearSign = () => {
    signature.clear();
    setUrl(null);
  };

  const onChangeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setConfirmationDetails((preState) => ({ ...preState, [name]: value }));
    setTimeChanged(true);
  };

  useEffect(() => {
    setConfirmationDetails((preState) => ({
      ...preState,
      hrs: moment(reservationData?.departureDate)?.hour()?.toString() || "12",
      mins:
        moment(reservationData?.departureDate)?.minutes()?.toString() || "12",
      country: reservationData?.countryOfResidence || "",
      pmsProfileID: "553209",
      pmsConfirmationNo: "19649415",
    }));
  }, [reservationData]);

  //api call
  const updateProfile = async () => {
    // debugger
    try {
      let mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (!confirmationDetails.email) {
        setEnterEmailValidation(true);
      }
      if (!mailFormat.test(confirmationDetails.email)) {
        setEmailValidation(true);
      }
      if (!confirmationDetails.country) {
        setCountryValidation(true);
      }
      if (!confirmationDetails.mobileNo) {
        setMobileNoValidation(true);
      }
      if (
        !confirmationDetails.email ||
        !confirmationDetails.country ||
        !confirmationDetails.mobileNo ||
        !mailFormat.test(confirmationDetails.email)
      ) {
        return;
      }
      setLoading2(true);
      const response = await updateUserProfile(confirmationDetails, token);
      if (response.statusCode === 200) {
        setLoading2(false);
      }
      if (response.statusCode === 102) {
        setErrorModalData2((state) => ({
          ...state,
          toggleError: true,
          errorMessage: response.message,
        }));
        setLoading2(false);
        return;
      }
      setLoading3(true);
      const response2 = await saveRegistrationCard({ confirmNo, url }, token);
      // if (response2.data.statusCode === 200) {
      //   setLoading2(false);
      // }
      if (response.statusCode === 200 && response2.status === 200) {
        setLoading3(false);
        navigate("/passport-scan-payment");
        return;
      }
    } catch (error) {
      if (error?.config?.url === "api/Reservation/SaveRegistrationCard") {
        setLoading3(false);
        setErrorModalData3((state) => ({
          ...state,
          toggleError: true,
          errorMessage: error.message,
        }));
      } else {
        setLoading2(false);
        setErrorModalData2((state) => ({
          ...state,
          toggleError: true,
          errorMessage: error.message,
        }));
      }
      return;
    }
    return;
  };

  useEffect(() => {
    if (togglePackageApi) {
      addPackageHandler();
    }
  }, [togglePackageApi]);

  const addPackageHandler = async () => {
    try {
      setLoading3(true);
      const packageData = {
        confirmationNumber: confirmNo,
        addOnItem: {
          itemCode: "LATECO",
          quantity: 1,
        },
      };

      const response = await addPackage(packageData, token);
      if (response.statusCode === 200) {
        setLoading3(false);
        setTogglePackageApi(false);
        setRefectGetUserDetails(true);
      }
    } catch (error) {
      setLoading3(false);
      setTogglePackageApi(false);
    }
    return;
  };

  return (
    <div>
      <UserReservationDetails
        loading={loading}
        loading2={loading2}
        loading3={loading3}
        openModal={openModal}
        reservationData={reservationData}
        confirmationDetails={confirmationDetails}
        erroModalData={erroModalData}
        erroModalData2={erroModalData2}
        closeErrorModal={closeErrorModal}
        closeErrorModal2={closeErrorModal2}
        confirmationDetailsHandler={confirmationDetailsHandler}
        setSignature={setSignature}
        setUrl={setUrl}
        signature={signature}
        gotSignature={gotSignature}
        handleCheckboxChange={handleCheckboxChange}
        clearSign={clearSign}
        navigateToPayment={navigateToPayment}
        userAgreed={userAgreed}
        url={url}
        updateProfile={updateProfile}
        erroModalData3={erroModalData3}
        closeErrorModal3={closeErrorModal3}
        openCheckOutTimeModal={openCheckOutTimeModal}
        toggleCheckoutTimeModal={toggleCheckoutTimeModal}
        setTimeChanged={setTimeChanged}
        timeChanged={timeChanged}
        onChangeHandler={onChangeHandler}
        emailValidation={emailValidation}
        countryValidation={countryValidation}
        mobileNoValidation={mobileNoValidation}
        enterEmailValidation={enterEmailValidation}
      />
      <Footer
        page={"signature"}
        showModal={showModal}
        openModal={openModal}
        timerCounter={timerCounter}
        timerValue={timerValue}
        restartTimer={restartTimer}
        stopTimer={stopTimer}
        timeLimit={timeLimit}
      />
      {showModal && (
        <HomePageCard
          showModal={showModal}
          resetTimerAndClosePopUp={resetTimerAndClosePopUp}
        />
      )}
      {toggleCheckoutTimeModal && (
        <CheckoutTimeModal
          closeCheckoutTimeModal={closeCheckoutTimeModal}
          togglePackageApi={togglePackageApi}
          setTogglePackageApi={setTogglePackageApi}
        />
      )}
    </div>
  );
};

export default ReservationDetailsPassportScan;
