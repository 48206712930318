import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import baseApi from "../../network/baseApi";

export const fetchReservationform = createAsyncThunk("fetchReservationform", async(qd)=>{
    const searchParams = new URLSearchParams(qd);
    let query = searchParams.toString();
    const response = await baseApi.get(`/api/Kiosk/GetAllProfiles?${query}`);
    return response.data;
})

const initialState = {
  reservationformData: { loading: false, data: [], error: "" },
};

const reservationFormSlice = createSlice({
  name: "reservationform",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchReservationform.pending, (state) => {
      state.reservationformData.loading = true;
    });
    builder.addCase(fetchReservationform.fulfilled, (state, action) => {
      state.reservationformData.loading = false;
      state.reservationformData.data = action.payload;
      state.reservationformData.error = "";
    });
    builder.addCase(fetchReservationform.rejected, (state, action) => {
      state.reservationformData.loading = false;
      state.reservationformData.data = [];
      state.reservationformData.error = action.error.message;
    });
  },
});

export const getReservationFormData = (state) => state.reservationform.reservationformData;

export default reservationFormSlice.reducer;