/* eslint-disable jsx-a11y/anchor-is-valid */
import "../../assets/stylesheet/verify-email.css";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CheckoutFooter from "../../components/Layout/CheckoutFooter";
import HomePageCard from "../../components/Layout/Card/HomePageCard";
import { useSelector } from "react-redux";
import { getCheckoutData } from "../../store/Checkout/checkoutSlice";
import LoaderPopup from "../../components/LoaderAndNotification/LoaderPopup";
import { timerConstantValue } from "../../constants/constant";

const SendInvoiceOnMail = () => {
  const timeLimit = timerConstantValue;
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timerValue, setTimerValue] = useState(timeLimit);
  const [restartTimer, setRestartTimer] = useState(false);
  const userData = useSelector(getCheckoutData);
  const [email, setEmail] = useState(userData?.email || "");
  const [emailValidation, setEmailValidation] = useState(false);
  const navigate = useNavigate();

  const resetTimerAndClosePopUp = () => {
    if (!timerValue) {
      setTimerValue(timeLimit);
    }
    setRestartTimer(true);
    setShowModal(false);
  };

  const timerCounter = (value) => {
    setTimerValue(value);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const stopTimer = () => {
    setRestartTimer(false);
  };

  const emailChangeHandler = (e) => {
    setEmail(e.target.value);
  };

  const updateEmailHandler = () => {
    let mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!mailFormat.test(email)) {
      setEmailValidation(true);
      return;
    }
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      navigate("/checkout-thank-you");
      return;
    }, 2000);
    return;
  };

  return (
    <div>
      <main>
        <div className="main-content bg-invoice-white">
          <div>
            <div>
              <button
                onClick={() => {
                  setShowModal(true);
                }}
                type="button"
                className="btn home-btn"
              >
                <i className="ri-arrow-go-back-line mr-3"></i>RESTART
              </button>
              <Link
                to="/checkout-thank-you"
                className="btn skip-btn float-right"
              >
                Skip<i className="bx bx-last-page"></i>
              </Link>
            </div>
          </div>
          <LoaderPopup loading={loading} />

          <div className="send-invoice-center">
            <div className="page-title invoice-mail-title">
              <h3><strong>Would you like to get the invoice by email?</strong> </h3>
            </div>

            <div className="reservation-confirm-box">
              <div className="reservation-confirm w-341">
                <label className="text-center">Please update or verify your email address</label>
                <input
                  type="email"
                  value={email}
                  autoComplete="off"
                  onChange={emailChangeHandler}
                  placeholder="Email address"
                  className="form-control form-control-set font-weight-600"
                />
                {emailValidation && (
                  <p className="email-error-msg">
                    Please enter a valid Email Address
                  </p>
                )}
              </div>
            </div>

            <div className="reservation-btn-set">
              <div>
                <button
                  style={{marginTop: "30px"}}
                  onClick={updateEmailHandler}
                  disabled={!email}
                  className="btn button-outline proceed-blue-btn confirm-invoice-btn"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
        <CheckoutFooter
          page={"receipt"}
          showModal={showModal}
          openModal={openModal}
          timerCounter={timerCounter}
          timerValue={timerValue}
          restartTimer={restartTimer}
          stopTimer={stopTimer}
          timeLimit={timeLimit}
        />
      </main>
      {showModal && (
        <HomePageCard
          showModal={showModal}
          resetTimerAndClosePopUp={resetTimerAndClosePopUp}
        />
      )}
    </div>
  );
};

export default SendInvoiceOnMail;
