/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Logo from "../../assets/images/pay-ic.png";
import "../../assets/stylesheet/process-payment.css";
import creditCardScan from "../../assets/images/CreditCardScan.gif";
import PaymentModal from "../Layout/Card/PaymentModal";
import LoaderPopup from "../LoaderAndNotification/LoaderPopup";
import ErrorPopup from "../LoaderAndNotification/ErrorPopup";
import SuccessPopup from "../LoaderAndNotification/SuccessPopup";

const Payment = ({
  loading,
  loading2,
  loading3,
  userData,
  erroModalData,
  erroModalData2,
  erroModalData3,
  generateToken,
  closeErrorModal,
  closeErrorModal2,
  closeErrorModal3,
  paymentToggle,
  setPaymentToggle,
  openModal,
  navigateUrl,
  paymentTypeHandler,
  userCheckIn,
  successLoader,
  successMessage,
}) => {
  const roomAmount = userData?.totalAmount || 0;
  const packageAmount = userData?.totalPackageAmount || 0;
  const totalAmount = roomAmount + packageAmount;

  return (
    <div>
      <main>
        <LoaderPopup loading={loading} />
        <ErrorPopup
          erroModalData={erroModalData}
          closeErrorModal={closeErrorModal}
        />
        <ErrorPopup
          erroModalData={erroModalData2}
          closeErrorModal={closeErrorModal2}
        />
        <ErrorPopup
          erroModalData={erroModalData3}
          closeErrorModal={closeErrorModal3}
        />
        <SuccessPopup loading={successLoader} successMessage={successMessage} />
        <div className="main-content">
          <div className="h-100">
            <div className="content-bg h-100">
              <div className="row h-100">
                <div className="col-sm-5 pl-0">
                  <div className="h-100">
                    <div>
                      <div>
                        <button
                          onClick={() => {
                            openModal();
                          }}
                          className="btn home-btn"
                        >
                          <i className="ri-arrow-go-back-line mr-3"></i>RESTART
                        </button>
                      </div>
                    </div>
                    <div className="position-relative">
                      <div className="payment-scan-center text-center">
                        <img src={Logo} className="thank-logo-img text-center" style={{width:'130px'}} />
                        
                        <div className="scan-text" style={{marginBottom: '30px'}}>
                          <h4 className="text-center font-weight-600">
                            It's time to process your payment
                          </h4>
                        </div>
                       

                        {/* <div>
                          <div className="text-center payment-content">
                            <p>
                              Room Rate:
                              <span>${roomAmount}</span>
                            </p>
                            <p>
                              Package:
                              <span>${packageAmount}</span>
                            </p>
                            <p>
                              Tax: <span>$54.33</span>
                            </p>
                            <p>
                              Incidental Changes: <span>$54.43</span>
                            </p>
                          </div>
                        </div> */}

                        <div className="mt-30">
                          <h5 className="font-weight-600 text-center font-15">
                            Total Amount Due:
                          </h5>
                          <div className="text-center tot-amt">
                            <h2>${totalAmount}</h2>
                          </div>
                        </div>

                        <div className="text-center mt-30">
                          {totalAmount ? (
                            <button
                              onClick={generateToken}
                              className="btn button-outline proceed-blue-btn"
                              style={{
                                backgroundColor: "#03355c",
                                width: "166px",
                              }}
                            >
                              PAY
                            </button>
                          ) : (
                            <button
                              onClick={userCheckIn}
                              style={{
                                backgroundColor: "#03355c",
                                width: "166px",
                              }}
                              className="btn button-outline proceed-blue-btn"
                            >
                              Continue
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-7 pr-0">
                  <div className="process-payment-img">
                    <img src={creditCardScan} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {paymentToggle && (
          <PaymentModal
            setPaymentToggle={setPaymentToggle}
            loading2={loading2}
            loading3={loading3}
            erroModalData2={erroModalData2}
            erroModalData3={erroModalData3}
            closeErrorModal2={closeErrorModal2}
            closeErrorModal3={closeErrorModal3}
            paymentTypeHandler={paymentTypeHandler}
          />
        )}
      </main>
    </div>
  );
};

export default Payment;
