import Thankyou from "../../../components/Thankyou";
import { useSelector } from "react-redux";
import { getPassportScanData } from "../../../store/Checkin/Scan/scanPassportSlice";

const ThankyouPassportScan = ()=>{
  const userData = useSelector(getPassportScanData);

    return <Thankyou userData={userData}/>;
}

export default ThankyouPassportScan;