/* eslint-disable jsx-a11y/alt-text */
import React, { Suspense } from "react";
import logo from "../assets/images/rotana-logo.PNG";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { generateTokenApi } from "../network/apiService";
import { useTranslation } from "react-i18next";
import LoaderPopup from "../components/LoaderAndNotification/LoaderPopup";
import ErrorPopup from "../components/LoaderAndNotification/ErrorPopup";

const HomePage = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [erroModalData, setErrorModalData] = useState({
    toggleError: false,
    errorMessage: "",
  });
  const navigate = useNavigate();

  const closeErrorModal = () => {
    setErrorModalData((state) => ({
      ...state,
      toggleError: false,
      errorMessage: "",
    }));
  };

  const generateToken = async (navigateUrl) => {
    try {
      debugger
      setLoading(true);
      const response = await generateTokenApi({
        userName: "admin",
        password: "admin",
      });
      if (response.status === 200) {
        setLoading(false);
        const data = response.data || {};
        localStorage.setItem("token", data?.token);
        navigate(navigateUrl);
        return;
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setErrorModalData((state) => ({
        ...state,
        toggleError: true,
        errorMessage: error.message,
      }));
      return;
    }
    return;
  };

  return (
    <div>
      <section className="section-bg">
        <div className="container-fluid">
          <div className="">
            <div className="row">
              <div className="col-sm-6 pl-0 pr-0">
                <div className="h-100 relative">
                  <div className="content-left vh-100">
                    <div className="content-center">
                      <div>
                        <div className="logo-img">
                          <img src={logo} />
                        </div>
                      </div>

                      <div className="mt-70">
                        <div>
                          <h2 className="welcome-title">{t("Welcome")}!</h2>
                          <p className="text-center font-15">
                            {t("Please")} {t("select")} {t("an")} {t("option")}{" "}
                            {t("to")} {t("begin")}
                          </p>
                        </div>

                        <div className="text-center mt-30">
                          <div>
                            <button
                              onClick={() => {
                                generateToken("/checkin-page");
                              }}
                              className="btn btn-lg button-blue check-in-btn"
                            >
                              {t("CHECK")} - {t("IN")}
                            </button>
                          </div>
                          <div className="mt-30">
                            <button
                              onClick={() => {
                                generateToken("/check-out-page");
                              }}
                              className="btn btn-lg button-blue check-in-btn"
                            >
                              {t("CHECK")} - {t("OUT")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <LoaderPopup loading={loading} />
                  <ErrorPopup
                    closeErrorModal={closeErrorModal}
                    erroModalData={erroModalData}
                  />
                  <Suspense fallback={<div>loading</div>}>
                    {/* <Language flag1={flag1} /> */}
                  </Suspense>
                </div>
              </div>
              <div className="col-sm-6 pr-0"></div>
            </div>
          </div>
        </div>
      </section>
      {/* </Slide> */}
    </div>
  );
};

export default HomePage;
