import Thankyou from "../../../components/Thankyou";
import { getconfirmationnumberData } from "../../../store/Checkin/ConfirmationNumber/confirmationNumberSlice";
import { useSelector } from "react-redux";

const ThankyouConfirmationNo = ()=>{
  const userData = useSelector(getconfirmationnumberData);

    return <Thankyou userData={userData}/>;
}

export default ThankyouConfirmationNo;