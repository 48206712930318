/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import scanOpt from "../assets/images/scan-option.PNG";
import confNumber from "../assets/images/confirmation_number.png";
import qrScan from "../assets/images/qr-scanning.png";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import "../assets/stylesheet/boxicons/css/boxicons.min.css";
import "../assets/stylesheet/bootstrap/css/bootstrap.min.css";
import "../assets/stylesheet/confirmation.css";
import HomePageCard from "../components/Layout/Card/HomePageCard";
import confirmNumber from "../assets/images/confirmation-number.png";
import passportScan from "../assets/images/passport-scanner.png";
import qrScanImage from "../assets/images/qr-scan.png";
import Footer from "../components/Layout/Footer";
import { timerConstantValue } from "../constants/constant";

const Confirmation = () => {
  const timeLimit = timerConstantValue;
  const [showModal, setShowModal] = useState(false);
  const [timerValue, setTimerValue] = useState(timeLimit);
  const [restartTimer, setRestartTimer] = useState(false);

  const resetTimerAndClosePopUp = () => {
    if (!timerValue) {
      setTimerValue(timeLimit);
    }
    setRestartTimer(true);
    setShowModal(false);
  };

  const timerCounter = (value) => {
    setTimerValue(value);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const stopTimer = () => {
    setRestartTimer(false);
  };

  return (
    <div>
      <main>
        <div className="main-content">
          <div>
            <div>
              <button
                onClick={() => {
                  setShowModal(true);
                }}
                type="button"
                className="btn home-btn"
              >
                <i className="ri-arrow-go-back-line mr-3"></i>RESTART
              </button>
            </div>
          </div>
          {/* <div className="page-title">Choose your way to check-in</div> */}
          <div className="checkin-section content-center">
            <div className="checkIn-white-box">
              <div className="qr-scan-img-flex">
                <div>
                  <div>
                    <h5 className="mb-20 text-center font-30">
                      QR Code Check-In
                    </h5>
                    <div>
                      <Link to="/qr-code-scanning">
                        <button
                          type="button"
                          className="btn button-blue qr-checkin-btn"
                        >
                          Scan QR Code
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="qr-scan-img">
                    <img src={qrScanImage} />
                  </div>
                </div>
              </div>
            </div>

            <div className="text-center mt-20">
              <div className="line-center">
                <span>Don't have QR code?</span>
              </div>
            </div>

            <div className="scan-confirm-flex mt-20">
              <div>
                <div className="passport-scan-box">
                  <div className="qr-scan-img-flex">
                    <div>
                      <div>
                        <p>
                        By Scanning My Passport / ID Document
                        </p>
                        <div>
                          <Link to="/passport-scanning">
                            <button
                              type="button"
                              className="btn button-blue  scan-passport-btn"
                            >
                              Scan Passport
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="passport-scan-img">
                        <img src={passportScan} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="confirm-number-box">
                  <div className="qr-scan-img-flex">
                    <div>
                      <div>
                        <p>By Entering My Confirmation Number</p>
                        <div>
                          <Link to="/confirmation-number">
                            <button
                              type="button"
                              className="btn button-blue confirm-number-btn"
                            >
                              Enter Confirmation Number
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="confirm-num-img">
                        <img src={confirmNumber} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer
          page={"confirmation"}
          showModal={showModal}
          openModal={openModal}
          timerCounter={timerCounter}
          timerValue={timerValue}
          restartTimer={restartTimer}
          stopTimer={stopTimer}
          timeLimit={timeLimit}
        />
      </main>
      {showModal && (
        <HomePageCard
          showModal={showModal}
          resetTimerAndClosePopUp={resetTimerAndClosePopUp}
        />
      )}
    </div>
  );
};

export default Confirmation;
