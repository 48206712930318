/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import "../../../assets/stylesheet/upgrade-option.css";
import SkipImage from "../../../assets/images/person-image.PNG";
import { Link } from "react-router-dom";

const SkipModal = ({ closeModal, skipHandler,navigateUrl }) => {
  return (
    <div
      className="modal"
    >
      <div className="modal-dialog modal-lg modal-top">
        <div className="modal-content modal-bg">
          <div className="modal-body">
            <div className="text-center">
              <div>
                <img src={SkipImage} className="w-110" />
              </div>
              <div className="mt-30">
                <h6>
                  Do you want to skip this step without <br></br>selecting
                  anything
                </h6>
              </div>
            </div>
            <div className="text-center mt-30">
              <button
                className="btn modal-no-btn"
                data-bs-dismiss="modal"
                onClick={closeModal}
              >
                No
              </button>
              <Link to={navigateUrl}>
                <button
                  className="btn modal-yes-btn bg-transperent"
                  onClick={() => {
                    skipHandler();
                  }}
                >
                  Yes
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkipModal;
