import { createSlice } from "@reduxjs/toolkit";
import { clearCookie, getCookie, setCookie } from "../helpers/cookiesHelper";
import { getParametersFromLocalStorage, removeParametersFromLocalStorage, saveParametersIntoLocalStorage } from "../helpers/commonHelper";

const initialState = {
  token: null,
  isLoggedIn: false,
};

const getInitialState = () => {
  const token = localStorage.getItem('token')
  if (!token) return initialState;

  return { token, isLoggedIn: true };
};

const AuthSlice = createSlice({
  initialState: getInitialState(),
  name: "AuthSlice",
  reducers: {
    login: (state, action) => {
      const {
        payload: { token },
      } = action;
      // setCookie("token", token);
      saveParametersIntoLocalStorage('token', token)
      state.isLoggedIn = true;
      state.token = token;
    },
    logout: () => {
      // clearCookie();
      removeParametersFromLocalStorage('token');
      return initialState;
    },
  },
});

export const { login, logout } = AuthSlice.actions;

export default AuthSlice.reducer;
