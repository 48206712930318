/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import scanGIF from "../../assets/images/scan.gif";
import "../../assets/stylesheet/boxicons/css/boxicons.min.css";
import "../../assets/stylesheet/scan.css";
import { useEffect, useState } from "react";
import HomePageCard from "../../components/Layout/Card/HomePageCard";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Layout/Footer";
import LoaderPopup from "../../components/LoaderAndNotification/LoaderPopup";
import {
  getReservationDetails,
  getPropertyData,
} from "../../network/apiService";
import ErrorPopup from "../../components/LoaderAndNotification/ErrorPopup";
import SuccessPopup from "../../components/LoaderAndNotification/SuccessPopup";
import { timerConstantValue } from "../../constants/constant";
import { scanPassportData } from "../../constants/scanjsondata";
import { scannedDocumentDetails } from "../../store/Checkin/Scan/documentScanSlice";
import { clearScanData, getScanData } from "../../network/apiService";
import { useDispatch } from "react-redux";
import FlipDocumentModal from "../../components/Layout/Card/FlipDocumentModal";
import moment from "moment";
import { useSelector } from "react-redux";
import {
  confNoCountOfTheGuests,
  getConfNoCurrentCountOfGuest,
  getconfirmationnumberData,
  userReservationDetailsConfirmatioNo,
} from "../../store/Checkin/ConfirmationNumber/confirmationNumberSlice";

const PassportScanning = () => {
  const timeLimit = timerConstantValue;
  const [showModal, setShowModal] = useState(false);
  const [clearData, setClearData] = useState({});
  const [scanData, setScanData] = useState({});
  const [flipDocument, setFlipDocument] = useState(false);
  const [flipModalToggle, setFlipModalToggle] = useState(false);
  const dispatch = useDispatch();

  const [userData, setUserData] = useState({
    LastName: "",
    CheckOutDate: "",
  });
  const [active, setActive] = useState("");
  const [loading, setLoading] = useState(false);
  const [timerValue, setTimerValue] = useState(timeLimit);
  const [restartTimer, setRestartTimer] = useState(false);
  const [successLoader, setSuccessLoader] = useState(false);
  const [erroModalData, setErrorModalData] = useState({
    toggleError: false,
    errorMessage: "",
  });
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const guestCount = useSelector(getConfNoCurrentCountOfGuest);

  const resetTimerAndClosePopUp = () => {
    if (!timerValue) {
      setTimerValue(timeLimit);
    }
    setRestartTimer(true);
    setShowModal(false);
  };

  const timerCounter = (value) => {
    setTimerValue(value);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const stopTimer = () => {
    setRestartTimer(false);
  };

  const closeErrorModal = () => {
    setErrorModalData((state) => ({
      ...state,
      toggleError: false,
      errorMessage: "",
    }));
  };

  const onChangeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUserData((state) => ({ ...state, [name]: value }));
  };

  useEffect(() => {
    if (userData.LastName && userData.CheckOutDate) {
      setActive("active");
    } else {
      setActive("");
    }
  }, [userData.CheckOutDate, userData.LastName]);

  // const scanHandler = () => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     setLoading(false);
  //     navigate("/passport-scanning-date-selection");
  //   }, 3000);
  // };
  const [reservationData, setReservationData] = useState({
    firstName: "",
    confirmationNo: "",
    roomNo: "",
    arrivalDate: "",
    departureDate: "",
    nights: "",
    adults: "",
    child: "",
    checkoutTime: "",
    roomType: "",
  });
  const [propertyDetails, setPropertyDetails] = useState();
  const completedScanDone = async () => {
    //api call for flip scan document
    setLoading(true);
    setFlipModalToggle(false);
    setFlipDocument(false);
    // try {
    // const response = await scanUserPassport

    //after api call if response is success
    // if (signedInUserName !== scanData.firstName + "" + scanData.lastName) {
    //   setScanData(scanData);
    //logic of scanning multiple guest document after successful api call.
    // if (response?.data?.statusCode === 200){

    // apply the logic for successfull api call here and for error in the catch block which is commented down below

    const scanData = await getScanData({});
    const propertyResponse = await getPropertyData(token);
    const response = await getReservationDetails("", token);
    debugger;
    // data of latest state of reservation form api call
    if (
      scanData != null &&
      scanData.data != null &&
      scanData.data.DocumentImageSide1 != null &&
      scanData.data.DocumentImageSide2 != null
    ) {
      if (response?.statusCode === 200 && response?.data !== null && response?.data !== undefined) {
        setLoading(false);
        dispatch(scannedDocumentDetails(scanData.data));
        dispatch(userReservationDetailsConfirmatioNo(response?.data));
        setReservationData(response?.data);
        setPropertyDetails(propertyResponse?.data);

        setTimeout(() => {
          setLoading(false);
          navigate("/passport-scan-face-verification");
        }, 2000);
        
        
      }
      if (response.data.statusCode === 102) {
        setErrorModalData((state) => ({
          ...state,
          toggleError: true,
          errorMessage: response.data.message,
        }));
        setLoading(false);
        return;
      }
    } 
    else {
      setErrorModalData((state) => ({
        ...state,
        toggleError: true,
        errorMessage: "Scanning failed",
      }));
      setLoading(false);
      // console.log(error);
      //return;
    }
  };

  const confirmationDetailsHandler = (e) => {};

  const getConfirmationDetails = async () => {
    try {
      setLoading(true);
      // call getScanData
      // const response = await getReservationDetails(
      //   {
      //     LastName: "Jidhin",
      //   },
      //   token
      // );
      // console.log(response);

      // replace the scanPassportData variable with response we will get after scanning the passport

      const clearData = await clearScanData({});

      const scanData = await getScanData({});
      debugger;
    
      // scanData.data.DocumentType = "ID";
      // if(scanData.data!=null && scanData.data.FullImage!=null)
      // {
      //   dispatch(scannedDocumentDetails(scanData.data));
      //   console.log(scanData.data.LastName);
      // }
      if (scanData != null && scanData.data != null && scanData.data.DocumentImageSide1 != null) {
        dispatch(scannedDocumentDetails(scanData.data));
        console.log(scanData.data.LastName);
        setLoading(false);
        if(scanData.data.DocumentType !== undefined ){

         

          if (scanData.data.DocumentType !== "P") {
            setFlipDocument(true);
            setFlipModalToggle(true)
            // setTimeout(() => {
            //   setLoading(false);
            // }, 1000);
            return;
            //setScanLoader(true);
            //return;
          } else if (
            scanData.data.Portrait !== null &&
            scanData.data.DocumentType === "P"
          ) {
            dispatch(scannedDocumentDetails(scanData.data));
  
            //     const date1 = new Date(moment(scanData.DateOfBirth).format("YYYY-MM-DD"));
            // const date2 = new Date();
            // const date3 = new Date(moment(date2).format("YYYY-MM-DD"));
            // const years = date3.getFullYear() - date1.getFullYear();
  
            // if(scanData.data.Age<18)
            // {
            //   setLoading(false);
            //   setErrorModalData((state) => ({
            //     ...state,
            //     toggleError: true,
            //     errorMessage: "Age of guest should be more than 18",
            //   }));
            //   return;
            // }
            // else{
  
            //     //navigate("passport-scanning-reservation-form");
            // }
            // setTimeout(() => {
            //   setSuccessLoader(false);
            //   navigate("/confirmation-no-face-verification");
            //   return;
            // }, 2000);
  
            const lastName = scanData.data.LastName;
            const response2 = await getReservationDetails(
              {
                LastName: lastName,
              },
              token
            );
            if (response2.statusCode === 200) {
              setLoading(false);
              setSuccessLoader(true);
              localStorage.setItem(
                "LastName",
                response2?.data?.lastName || response2?.data?.lastName
              );
              setSuccessLoader(false);
              navigate("/passport-scanning-reservation-form");
              return;
            }
            if (response2.statusCode === 102) {
              setErrorModalData((state) => ({
                ...state,
                toggleError: true,
                errorMessage: response2.data.message,
              }));
              setLoading(false);
              return;
            }
        }

        
        }else{
             setLoading(false);
              setErrorModalData((state) => ({
                ...state,
                toggleError: true,
                errorMessage: "Failed to scan the document",
              }));
              return;
        }
      }

      // if (scanPassportData.age <= 18) {
      //   setLoading(false);
      //   setErrorModalData((state) => ({
      //     ...state,
      //     toggleError: true,
      //     errorMessage: "Age of guest should be more than 18",
      //   }));
      //   return;
      // }

      // if (scanPassportData.age > 18) {
      //   const lastName = scanPassportData.lastName;
      //   const response2 = await getReservationDetails(
      //     {
      //       LastName: lastName,
      //     },
      //     token
      //   );
      //   if (response2.data.statusCode === 200) {
      //     setLoading(false);
      //     setSuccessLoader(true);
      //     localStorage.setItem(
      //       "LastName",
      //       response2?.data?.data[0]?.lastName ||
      //         response2?.data?.data?.lastName
      //     );
      //     setSuccessLoader(false);
      //     navigate("/passport-scanning-reservation-form");
      //     return;
      //   }
      //   if (response2.data.statusCode === 102) {
      //     setErrorModalData((state) => ({
      //       ...state,
      //       toggleError: true,
      //       errorMessage: response2.data.message,
      //     }));
      //     setLoading(false);
      //     return;
      //   }
      // }
    } catch (error) {
      setErrorModalData((state) => ({
        ...state,
        toggleError: true,
        errorMessage: error.message,
      }));
      setLoading(false);
      console.log(error);
      return;
    }
    return;
  };

  return (
    <div>
      <main>
        <LoaderPopup loading={loading} />
        <ErrorPopup
          closeErrorModal={closeErrorModal}
          erroModalData={erroModalData}
        />

        <SuccessPopup
          loading={successLoader}
          successMessage={"Document scanning completed succcessfully"}
        />
        <FlipDocumentModal
          completedScanDone={completedScanDone}
          flipModalToggle={flipModalToggle}
        />
        <div className="main-content">
          <div className="h-100">
            <div className="content-bg h-100">
              <div className="row h-100">
                <div className="col-sm-6 pl-0">
                  <div className="h-100">
                    <div>
                      <div>
                        <button
                          onClick={() => {
                            navigate(-1);
                          }}
                          className="btn button-blue back-btn"
                        >
                          <i className="bx bx-chevron-left"></i>BACK
                        </button>
                      </div>
                      <div>
                        <button
                          onClick={() => {
                            setShowModal(true);
                          }}
                          className="btn home-btn"
                        >
                          <i className="ri-arrow-go-back-line mr-3"></i>RESTART
                        </button>
                      </div>
                    </div>
                    <div className="scan-content-left position-relative">
                      <div className="passport-scan-center">
                        <div className="scan-text">
                          <h5 className="text-center font-14 font-weight-500">
                            Place your valid document on the scanner and touch
                            'SCAN'
                          </h5>
                        </div>
                        <div className="text-center mt-30">
                          <button
                            onClick={() => {
                              getConfirmationDetails();
                            }}
                            className="btn button-blue scan-btn"
                          >
                            <i className="bx bx-barcode-reader scan-icon"></i>
                            Scan
                          </button>
                        </div>
                      </div>

                      {/* <div className="mt-20">
                        <div className="line-center">or</div>
                      </div>
                      <div className="mt-20">
                        <h5 className="font-weight-500 text-center font-14">
                          <strong> Please enter the details</strong>
                        </h5>
                      </div> */}

                      {/* <div className="mt-20 text-center">
                        <div className="scan-confirmation">
                          <input
                            type="text"
                            name="LastName"
                            value={userData.LastName}
                            onChange={onChangeHandler}
                            className="form-control form-control-set"
                            placeholder="Last Name"
                          />
                        </div>
                        <div className="scan-confirmation">
                          <input
                            type="date"
                            name="CheckOutDate"
                            value={userData.CheckOutDate}
                            onChange={onChangeHandler}
                            className="form-control form-control-set"
                            placeholder="Check-out Date"
                          />
                        </div>
                      </div> */}
                      {/* <div className="mt-20 text-center">
                        <button
                          className={`btn search-btn ${active}`}
                          onClick={getConfirmationDetails}
                          disabled={active ? false : true}
                        >
                          Search
                        </button>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 pr-0">
                  <div className="scan-img">
                    <img src={scanGIF} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer
          page={"documentscanning"}
          showModal={showModal}
          openModal={openModal}
          timerCounter={timerCounter}
          timerValue={timerValue}
          restartTimer={restartTimer}
          stopTimer={stopTimer}
          timeLimit={timeLimit}
        />
      </main>
      {showModal && (
        <HomePageCard
          showModal={showModal}
          resetTimerAndClosePopUp={resetTimerAndClosePopUp}
        />
      )}
    </div>
  );
};

export default PassportScanning;
