import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import LanguageDetector from "i18next-browser-languagedetector";
import englishLang from "./translations/english.json";
import arabicLang from "./translations/arabic.json";

const availableLanguages = ["en", "ar"];

const resources = {
  en: { translation: englishLang },
  ar: { translation: arabicLang },
};

const option = {
  order: ["navigator", "htmlTag", "path", "subdomail"],
  checkWhitelist: true,
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
