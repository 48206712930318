/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */

import HomePageCard from "../../components/Layout/Card/HomePageCard";
import { useState } from "react";
import "../../assets/stylesheet/scan.css";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../components/Layout/Footer";
import { useDispatch } from "react-redux";
import { getReservationDetails } from "../../network/apiService";
import LoaderPopup from "../../components/LoaderAndNotification/LoaderPopup";
import ErrorPopup from "../../components/LoaderAndNotification/ErrorPopup";
import { timerConstantValue } from "../../constants/constant";
import {clearScanData,getScanData} from "../../network/apiService";

const QrScanning = () => {
  const timeLimit = timerConstantValue;
  const [timerValue, setTimerValue] = useState(timeLimit);
  const [showModal, setShowModal] = useState(false);
  const [clearData, setClearData] = useState({});
  const [scanData, setScanData] = useState({});
  const [loading, setLoading] = useState(false);
  const [restartTimer, setRestartTimer] = useState(false);
  const [erroModalData, setErrorModalData] = useState({
    toggleError: false,
    errorMessage: "",
  });
  const token = localStorage.getItem("token");

  const navigate = useNavigate();

  const resetTimerAndClosePopUp = () => {
    if (!timerValue) {
      setTimerValue(11);
    }
    setRestartTimer(true);
    setShowModal(false);
  };

  const timerCounter = (value) => {
    setTimerValue(value);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const stopTimer = () => {
    setRestartTimer(false);
  };

  const closeErrorModal = () => {
    setErrorModalData((state) => ({
      ...state,
      toggleError: false,
      errorMessage: "",
    }));
  };

  const getConfirmationDetails = async () => {
    try {
      setLoading(true);
      // call getScanData

      const clearData = await clearScanData({});

      const scanData = await getScanData({});
      
      debugger

      if(scanData.data!=null && scanData.data.BarCodeData!=null)
      {

      const response = await getReservationDetails(
        {
          ConfirmationNo: scanData.data.BarCodeData, //19533596 scanData.data.BarCodeData,
        },
        token
      );
      if (response.statusCode === 200) {
        const confirmNo = response.data.pmsConfirmationNo;

        const response2 = await getReservationDetails(
          {
            ConfirmationNo: confirmNo,
          },
          token
        );
        if (response2.statusCode === 200) {
          setLoading(false);
          localStorage.setItem(
            "confirmationNo",
            response?.data?.pmsConfirmationNo
          );
          navigate("/qr-code-scanning-reservation-form");
          return;
        }
        if (response.statusCode === 102) {
          setErrorModalData((state) => ({
            ...state,
            toggleError: true,
            errorMessage: response.data.message,
          }));
          setLoading(false);
          return;
        }
      }
      else{
        setErrorModalData((state) => ({
          ...state,
          toggleError: true,
          errorMessage: "Booking not found",
        }));
        setLoading(false);
        return;
      }
      }
      else{
        setErrorModalData((state) => ({
          ...state,
          toggleError: true,
          errorMessage: "Unable to find the reservation",
        }));
        setLoading(false);
        return;
      }
    } catch (error) {
      setErrorModalData((state) => ({
        ...state,
        toggleError: true,
        errorMessage: error.message,
      }));
      setLoading(false);
      console.log(error);
      return;
    }
    return;
  };

  return (
    <div>
      <main>
        <LoaderPopup loading={loading} />
        <ErrorPopup
          closeErrorModal={closeErrorModal}
          erroModalData={erroModalData}
        />
        <div className="main-content">
          <div className="h-100">
            <div className="content-bg h-100">
              <div className="row h-100">
                <div className="col-sm-4 pl-0">
                  <div className="h-100">
                    <div>
                      <div>
                        <button
                          onClick={() => {
                            navigate(-1);
                          }}
                          className="btn button-blue back-btn"
                        >
                          <i className="bx bx-chevron-left mr-3"></i>BACK
                        </button>
                      </div>
                      <div>
                        <button
                          onClick={() => {
                            setShowModal(true);
                          }}
                          className="btn home-btn mt-6"
                        >
                          <i className="ri-arrow-go-back-line mr-3"></i>RESTART
                        </button>
                      </div>
                    </div>
                    <div className="position-relative">
                      <div className="section-center">
                        <div className="scan-text">
                          <h5 className="text-center font-14 font-weight-500">
                            Place your <strong> QR code </strong>on the scanner
                          </h5>
                        </div>
                        <div className="text-center mt-30">
                          <button
                            className="btn button-blue scan-btn"
                            onClick={getConfirmationDetails}
                          >
                            <i className="bx bx-barcode-reader scan-icon"></i>
                            Scan
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-8 pr-0">
                  <div className="scan-img">
                    <img src="./assets/images/scan-bg.jpg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer
          page={"documentscanning"}
          showModal={showModal}
          openModal={openModal}
          timerCounter={timerCounter}
          timerValue={timerValue}
          restartTimer={restartTimer}
          stopTimer={stopTimer}
          timeLimit={timeLimit}
        />
      </main>
      {showModal && (
        <HomePageCard
          showModal={showModal}
          resetTimerAndClosePopUp={resetTimerAndClosePopUp}
        />
      )}
    </div>
  );
};

export default QrScanning;
