import { useState } from "react";
import Payment from "../../../components/Payment/Payment";
import Footer from "../../../components/Layout/Footer";
import HomePageCard from "../../../components/Layout/Card/HomePageCard";
import { getPassportScanData } from "../../../store/Checkin/Scan/scanPassportSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  generateTokenApi,
  checkIn,
  triggerPayment,
} from "../../../network/apiService";
import { timerConstantValue } from "../../../constants/constant";

const PaymentOnPassportScan = () => {
  const timeLimit = timerConstantValue;
  const [showModal, setShowModal] = useState(false);
  const [paymentToggle, setPaymentToggle] = useState(false);
  const [timerValue, setTimerValue] = useState(timeLimit);
  const [restartTimer, setRestartTimer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [successLoader, setSuccessLoader] = useState(false);
  const [erroModalData, setErrorModalData] = useState({
    toggleError: false,
    errorMessage: "",
  });
  const [erroModalData2, setErrorModalData2] = useState({
    toggleError: false,
    errorMessage: "",
  });
  const [erroModalData3, setErrorModalData3] = useState({
    toggleError: false,
    errorMessage: "",
  });
  const navigate = useNavigate();
  const userData = useSelector(getPassportScanData);
  const token = localStorage.getItem("token");

  const resetTimerAndClosePopUp = () => {
    if (!timerValue) {
      setTimerValue(timeLimit);
    }
    setRestartTimer(true);
    setShowModal(false);
  };

  const timerCounter = (value) => {
    setTimerValue(value);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const stopTimer = () => {
    setRestartTimer(false);
  };

  const closeErrorModal = () => {
    setErrorModalData((state) => ({
      ...state,
      toggleError: false,
      errorMessage: "",
    }));
  };

  const closeErrorModal2 = () => {
    setErrorModalData2((state) => ({
      ...state,
      toggleError: false,
      errorMessage: "",
    }));
  };

  const closeErrorModal3 = () => {
    setErrorModalData3((state) => ({
      ...state,
      toggleError: false,
      errorMessage: "",
    }));
  };

  const generateToken = async () => {
    setPaymentToggle(true);
    return;
  };

  const userCheckIn = async () => {
    try {
      setLoading3(true);
      const checkinData = {
        confirmationNo: userData?.pmsConfirmationNo,
        reservationNameID: userData?.reservationNameID,
      };
      const response2 = await checkIn(checkinData, token);
      if (response2.status === 200) {
        setLoading3(false);
        setSuccessLoader(true);
        setTimeout(() => {
          setSuccessLoader(false);
          navigate("/passport-scan-create-key");
        }, 2000);
        return;
      }
    } catch (error) {
      setLoading3(false);
      setErrorModalData3((state) => ({
        ...state,
        toggleError: true,
        errorMessage: error.message,
      }));
      console.log(error);
      return;
    }
    return;
  };

  const paymentTypeHandler = async (paymentMode) => {
    try {
      setLoading2(true);
      const paymentType = {
        vendor: "Q3",
        paymentType: paymentMode,
        terminalId: "100010001",
        trxNumber: "txn98980098",
        trxAmount: 10,
        currency: "AED",
        workStation: "KIOS1",
        orderId: "OR1090090",
      };
      const response = await triggerPayment(paymentType, token);
      if (response.status === 200) {
        setLoading2(false);
        setLoading3(true);
        const checkinData = {
          confirmationNo: userData?.pmsConfirmationNo,
          reservationNameID: userData?.reservationNameID,
          paymentType,
        };
        const response2 = await checkIn(checkinData, token);
        if (response2.status === 200) {
          setLoading3(false);
          setPaymentToggle(false);
          setSuccessLoader(true);
          setTimeout(() => {
            setSuccessLoader(false);
            navigate("/passport-scan-create-key");
          }, 2000);
          return;
        }
      }
    } catch (error) {
      if (error?.config?.url === "api/Payment/InitPayment") {
        setLoading2(false);
        setErrorModalData2((state) => ({
          ...state,
          toggleError: true,
          errorMessage: error.message,
        }));
      } else {
        setLoading3(false);
        setErrorModalData3((state) => ({
          ...state,
          toggleError: true,
          errorMessage: error.message,
        }));
      }
      console.log(error);
      return;
    }
    return;
  };

  return (
    <div>
      <Payment
        loading={loading}
        loading2={loading2}
        loading3={loading3}
        userData={userData}
        erroModalData={erroModalData}
        erroModalData2={erroModalData2}
        erroModalData3={erroModalData3}
        generateToken={generateToken}
        closeErrorModal={closeErrorModal}
        closeErrorModal2={closeErrorModal2}
        closeErrorModal3={closeErrorModal3}
        openModal={openModal}
        paymentToggle={paymentToggle}
        setPaymentToggle={setPaymentToggle}
        navigateUrl={"/passport-scan-create-key"}
        userCheckIn={userCheckIn}
        paymentTypeHandler={paymentTypeHandler}
        successLoader={successLoader}
        successMessage={"You have been successfully Checked-in"}
      />
      <Footer
        page={"payment"}
        showModal={showModal}
        openModal={openModal}
        timerCounter={timerCounter}
        timerValue={timerValue}
        restartTimer={restartTimer}
        stopTimer={stopTimer}
        timeLimit={timeLimit}
      />
      {showModal && (
        <HomePageCard showModal={showModal} resetTimerAndClosePopUp={resetTimerAndClosePopUp} />
      )}
    </div>
  );
};

export default PaymentOnPassportScan;
