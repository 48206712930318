/* eslint-disable jsx-a11y/alt-text */
import i18next from "i18next";
import personImage from "../../../assets/images/person-image.PNG";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  resetGuestCount,
  userReservationDetailsConfirmatioNo,
} from "../../../store/Checkin/ConfirmationNumber/confirmationNumberSlice";
import {
  resetScanPassportGuestCount,
  userReservationDetailsPassport,
} from "../../../store/Checkin/Scan/scanPassportSlice";
import {
  resetQRGuestCount,
  userReservationDetailsQr,
} from "../../../store/Checkin/QrCode/qrCodeSlice";
import { userReservationDetailsCheckoutPage } from "../../../store/Checkout/checkoutSlice";

const HomePageCard = ({ showModal, closeModal, resetTimerAndClosePopUp }) => {
  const timeLimit = 10;
  const navigate = useNavigate();
  const [currentCount, setCurrentCount] = useState(timeLimit);

  const closeModalPopUp = () => {
    resetTimerAndClosePopUp();
  };

  const dispatch = useDispatch();

  const homePageHandler = () => {
    debugger
    localStorage.removeItem("confirmationNo");
    i18next.changeLanguage("en");
    localStorage.removeItem("token");
    localStorage.removeItem("LastName");
    dispatch(userReservationDetailsCheckoutPage({}));
    dispatch(resetGuestCount());
    dispatch(resetQRGuestCount());
    dispatch(resetScanPassportGuestCount());
    dispatch(userReservationDetailsQr({}));
    dispatch(userReservationDetailsPassport({}));
    dispatch(userReservationDetailsConfirmatioNo({}));
  };

  useEffect(() => {
    let interval = null;
    if (showModal) {
      interval = setInterval(() => {
        setCurrentCount(currentCount - 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [showModal, currentCount]);

  const progressOffset = Math.round(100 - (100 * currentCount) / timeLimit);

  useEffect(() => {
    if (currentCount === 0) {
      localStorage.removeItem("confirmationNo");
      localStorage.removeItem("token");
      navigate("/home-page");
    }
  }, [currentCount, navigate]);

  return (
    <div className="modal" style={{ zIndex: "2" }}>
      <div className="modal-dialog modal-lg modal-top">
        <div className="modal-content modal-bg">
          <div className="modal-body">
            <div className="text-center">
              {/* <div>
                <img src={personImage} />
              </div> */}
              <div className="mt-30 modal-info">
                <h6>Are you sure you want to return to the home page?</h6>
                <h6>Any unsaved progress will be lost</h6>
              </div>
            </div>
            <div className="text-center mt-30">
              <button
                type="button"
                onClick={closeModalPopUp}
                className="btn modal-no-btn"
              >
                No
              </button>
              <Link
                onClick={homePageHandler}
                to={"/home-page"}
                className="btn modal-yes-btn"
              >
                Yes
              </Link>
            </div>
            <div className="completed-status  modal-timer">
              <span style={{ color: "black" }}>{currentCount}</span>
              <div className="base-timer">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="-1 -1 34 34">
                  <circle
                    cx="16"
                    cy="16"
                    r="15.9155"
                    className="progress-bar__background"
                  />
                  <circle
                    cx="16"
                    cy="16"
                    r="15.9155"
                    className="progress-bar__progress js-progress-bar"
                    style={{ strokeDashoffset: progressOffset }}
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePageCard;
