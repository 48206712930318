import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const LoaderPopup = ({ loading }) => {
  return (
    <div>
      <Modal open={loading} style={{ zIndex: "1" }}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "500px",
            backgroundColor: "#f5f5f5",
            border: "2px #000",
            boxShadow: "0 0 24px rgba(0, 0, 0, 0.5)",
            padding: "16px",
            borderRadius: "20px",
          }}
        >
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, textAlign: "center", fontSize: "1.8rem" }}
          >
            Please wait for while we process your request
          </Typography>
          <div style={{ textAlign: "center" }}>
            <div className="lds-ellipsis">
              <div></div>
              <div></div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default LoaderPopup;
