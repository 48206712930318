import { useState, useEffect } from "react";
import Upgrade from "../../../components/Upgrade/Upgrade";
import Footer from "../../../components/Layout/Footer";
import HomePageCard from "../../../components/Layout/Card/HomePageCard";

const UpgradeOptionConfirmationNo = () => {
  const timeLimit = 80;
  const [showModal, setShowModal] = useState(false);
  const [upgradeRoomModal, setUpgradeRoomModal] = useState(false);
  const [toggleSkipModal, setToggleSkipModal] = useState(false);
  const [toggleDoneModal, setToggleDoneModal] = useState(false);
  const [timerValue, setTimerValue] = useState(timeLimit);
  const [restartTimer, setRestartTimer] = useState(false);
  const [toggleCheckoutTimeModal, setToggleCheckoutTimeModal] = useState(false);
  const [timeChanged, setTimeChanged] = useState(false);

useEffect(() => {
  if (timeChanged) {
    openCheckOutTimeModal();
  }
}, [timeChanged]);

const openCheckOutTimeModal = () => {
  setToggleCheckoutTimeModal(true);
};



  const [upgrade, setUpgrade] = useState({
    breakFast: false,
    earlyCheckout: false,
    airportDropOff: false,
  });

  const [roomOptions, setRoomOptions] = useState({
    room: null,
    price: null,
  });

  const resetTimerAndClosePopUp = () => {
    // if (!timerValue) {
    //   setTimerValue(timeLimit);
    // }
    // setRestartTimer(true);
    setShowModal(false);
  };

  const timerCounter = (value) => {
    setTimerValue(value);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const stopTimer = () => {
    setRestartTimer(false);
  };

  const openUpgradeModal = () => {
    setUpgradeRoomModal(true);
  };

  const closeUpgradeModal = () => {
    setUpgradeRoomModal(false);
  };

  const skipModal = () => {
    setToggleSkipModal(true);
  };

  const closeSkipModal = () => {
    setToggleSkipModal(false);
  };

  const closeDoneModal = () => {
    setToggleDoneModal(false);
  };
  const closeCheckoutTimeModal = () => {
    setToggleCheckoutTimeModal(false);
    setTimeChanged(false);
  };

  const resetStates = () => {
    setUpgrade({
      breakFast: false,
      earlyCheckout: false,
      airportDropOff: false,
    });
    setRoomOptions({
      room: null,
      price: null,
    });
    setToggleDoneModal(true);
  };

  const skipHandler = () => {
    setUpgrade({
      breakFast: false,
      earlyCheckout: false,
      airportDropOff: false,
    });
    setRoomOptions({
      room: null,
      price: null,
    });
  };

  const removeRoomOptions = () => {
    setRoomOptions({
      room: null,
      price: null,
    });
    setUpgradeRoomModal(false);
  };

  const upgradeHandler = (option, status) => {
    if (option === "breakfast" && status === "selected") {
      setUpgrade((state) => {
        return { ...state, breakFast: true };
      });
    }

    if (option === "breakfast" && status === "remove") {
      setUpgrade((state) => {
        return { ...state, breakFast: false };
      });
    }

    if (option === "earlycheckout" && status === "selected") {
      setUpgrade((state) => {
        return { ...state, earlyCheckout: true };
      });
    }

    if (option === "earlycheckout" && status === "remove") {
      setUpgrade((state) => {
        return { ...state, earlyCheckout: false };
      });
    }

    if (option === "airportdropoff" && status === "selected") {
      setUpgrade((state) => {
        return { ...state, airportDropOff: true };
      });
    }

    if (option === "airportdropoff" && status === "remove") {
      setUpgrade((state) => {
        return { ...state, airportDropOff: false };
      });
    }
  };
  return (
    <div>
      <Upgrade
        showModal={showModal}
        upgradeRoomModal={upgradeRoomModal}
        toggleSkipModal={toggleSkipModal}
        toggleDoneModal={toggleDoneModal}
        timerValue={timerValue}
        restartTimer={restartTimer}
        upgrade={upgrade}
        roomOptions={roomOptions}
        skipModal={skipModal}
        openModal={openModal}
        resetStates={resetStates}
        removeRoomOptions={removeRoomOptions}
        openUpgradeModal={openUpgradeModal}
        closeUpgradeModal={closeUpgradeModal}
        closeSkipModal={closeSkipModal}
        closeDoneModal={closeDoneModal}
        upgradeHandler={upgradeHandler}
        skipHandler={skipHandler}
        setRoomOptions={setRoomOptions}
        setUpgrade={setUpgrade}
        navigateUrl = {"/confirmation-no-reservation-terms"}
      />
      <Footer
        page={"upgrade"}
        openModal={openModal}
        timerCounter={timerCounter}
        timerValue={timerValue}
        restartTimer={restartTimer}
        stopTimer={stopTimer}
        timeLimit={timeLimit}
      />
      {/* {showModal && (
        <HomePageCard showModal={showModal} resetTimerAndClosePopUp={resetTimerAndClosePopUp} />
      )} */}
    </div>
    
  );
};

export default UpgradeOptionConfirmationNo;
