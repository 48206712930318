const API_BASE_URL = "https://samso-engage.ae-samsotechapp.com";

//export const API_BASE_URL2 = "http://localhost:4000/";
//export const API_BASE_URL2 = "http://localhost:8443/";
//export const API_BASE_URL2 = "http://192.168.52.185:8443/";
export const API_BASE_URL2 = "http://localhost:8443/";
//reksh machne

//export const API_BASE_URL2 = "http://localhost:8443/";
//kiosk server



export const API_URL = API_BASE_URL;
//