import { useState, useEffect } from "react";
import moment from "moment";
import {
  assignRoom,
  getAvailableRooms,
  getPropertyData,
  getReservationDetails,
  navigationPath,
} from "../../../network/apiService";
import ReservationForm from "../../../components/Forms/ReservationForm";
import Footer from "../../../components/Layout/Footer";
import HomePageCard from "../../../components/Layout/Card/HomePageCard";
import { timerConstantValue } from "../../../constants/constant";
import { userReservationDetailsPassport } from "../../../store/Checkin/Scan/scanPassportSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userReservationDetailsConfirmatioNo } from "../../../store/Checkin/ConfirmationNumber/confirmationNumberSlice";
import { userReservationDetailsQr } from "../../../store/Checkin/QrCode/qrCodeSlice";
import { userReservationDetailsCheckoutPage } from "../../../store/Checkout/checkoutSlice";

const PassportReservationForm = () => {
  const timeLimit = timerConstantValue;
  const page = "passportscan";
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loading4, setLoading4] = useState(false);
  const [timerValue, setTimerValue] = useState(timeLimit);
  const [restartTimer, setRestartTimer] = useState(false);
  const [updateConfirmationNo, setUpdateConfirmationNo] = useState("19489691");
  const [erroModalData, setErrorModalData] = useState({
    toggleError: false,
    errorMessage: "",
  });
  const [erroModalData2, setErrorModalData2] = useState({
    toggleError: false,
    errorMessage: "",
  });
  const [erroModalData3, setErrorModalData3] = useState({
    toggleError: false,
    errorMessage: "",
  });
  const [reservationData, setReservationData] = useState({
    roomNo: "",
    arrivalDate: "",
    departureDate: "",
    nights: "",
    adults: "",
    child: "",
    checkoutTime: "",
    roomType: "",
  });
  const [propertyDetails, setPropertyDetails] = useState();
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const lastName = localStorage.getItem("LastName");

  const homePageHandler = () => {
    localStorage.removeItem("confirmationNo");
    localStorage.removeItem("token");
    localStorage.removeItem("LastName");
    dispatch(userReservationDetailsCheckoutPage({}));
    dispatch(userReservationDetailsQr({}));
    dispatch(userReservationDetailsPassport({}));
    dispatch(userReservationDetailsConfirmatioNo({}));
    navigate("/");
    return;
  };

  // const dummyData = {
  //   confirmationNo: "19533596",
  //   roomNo: "110",
  //   arrivalDate: "2021-09-24",
  //   departureDate: "2021-09-24",
  //   nights: 0,
  //   adults: 0,
  //   child: 0,
  //   checkoutTime: moment("2021-09-24T00:00:00").format("hh:mm A") || "",
  //   roomType: "SGL",
  // };

  //api call for reservation form.
  useEffect(() => {
    if (lastName) {
      fetchReservationDetails({ LastName: lastName });
    }
  }, [lastName]);

  const fetchReservationDetails = async (query) => {
    try {
      setLoading(true);
      const response = await getReservationDetails(query, token);
      const propertyResponse = await getPropertyData(token);
      // data of latest state of reservation form api call
      if (
        response?.statusCode === 200 &&
        propertyResponse?.status === 200
      ) {
        setLoading(false);
        setReservationData(response?.data);
        setPropertyDetails(propertyResponse?.data);
      }
      if (response.data.statusCode === 102) {
        setErrorModalData((state) => ({
          ...state,
          toggleError: true,
          errorMessage: response.message,
        }));
        setLoading(false);
        return;
      }
    } catch (error) {
      setErrorModalData((state) => ({
        ...state,
        toggleError: true,
        errorMessage: error.message,
      }));
      setLoading(false);
    }
  };
  const getConfirmationNoOfSelectedDiv = (confNo, divBoolean) => {
    confNo = "19489691"
    setUpdateConfirmationNo(confNo);
    return;
  };

  const disptachUserDetails = () => {

    debugger
    const gotReservationData = reservationData.find(
      (data) => data.pmsConfirmationNo === updateConfirmationNo
    );
    dispatch(userReservationDetailsPassport(gotReservationData));
    return;
  };

  const resetTimerAndClosePopUp = () => {
    if (!timerValue) {
      setTimerValue(timeLimit);
    }
    setRestartTimer(true);
    setShowModal(false);
  };

  const timerCounter = (value) => {
    setTimerValue(value);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const stopTimer = () => {
    setRestartTimer(false);
  };

  const closeErrorModal = () => {
    setErrorModalData((state) => ({
      ...state,
      toggleError: false,
      errorMessage: "",
    }));
  };

  const closeErrorModal2 = () => {
    setErrorModalData2((state) => ({
      ...state,
      toggleError: false,
      errorMessage: "",
    }));
    homePageHandler();
    return;
  };

  const closeErrorModal3 = () => {
    setErrorModalData3((state) => ({
      ...state,
      toggleError: false,
      errorMessage: "",
    }));
    homePageHandler();
    return;
  };

  const setConfirmationNumberIntoLocalStorage = (pageName, confNo, ngtUrl) => {
    if (pageName === "passportscan") {
      localStorage.setItem("confirmationNo", confNo);
      localStorage.removeItem("LastName");
      navigate(ngtUrl);
      disptachUserDetails();
    }
    return;
  };

  const proceedHandler = async () => {
    const foundReservationData = reservationData.length
      ? reservationData?.find(
          // (ele) => +ele?.pmsConfirmationNo === +updateConfirmationNo
          (ele) => +ele?.pmsConfirmationNo === "19489691"
        )
      : reservationData;
    if (!foundReservationData?.reservationStatus) {
      setErrorModalData((state) => ({
        ...state,
        toggleError: true,
        errorMessage: "Please visit front desk for assistance",
      }));
      return;
    } else {
      try {
        setConfirmationNumberIntoLocalStorage(
          page,
          foundReservationData?.pmsConfirmationNo,
          "/passport-scan-face-verification"
        );
        return;
      
      } catch (error) {
       
      }
    }
    return;
  };

  return (
    <div>
      <ReservationForm
        loading={loading}
        loading2={loading2}
        loading3={loading3}
        loading4={loading4}
        page={page}
        reservationData={reservationData}
        openModal={openModal}
        closeErrorModal={closeErrorModal}
        closeErrorModal2={closeErrorModal2}
        closeErrorModal3={closeErrorModal3}
        erroModalData={erroModalData}
        erroModalData2={erroModalData2}
        erroModalData3={erroModalData3}
        updateConfirmationNo={updateConfirmationNo}
        getConfirmationNoOfSelectedDiv={getConfirmationNoOfSelectedDiv}
        propertyDetails={propertyDetails}
        disptachUserDetails={disptachUserDetails}
        proceedHandler={proceedHandler}
      />
      <Footer
        page={"documentscanning"}
        showModal={showModal}
        openModal={openModal}
        timerCounter={timerCounter}
        timerValue={timerValue}
        restartTimer={restartTimer}
        stopTimer={stopTimer}
        timeLimit={timeLimit}
      />
      {showModal && (
        <HomePageCard
          showModal={showModal}
          resetTimerAndClosePopUp={resetTimerAndClosePopUp}
        />
      )}
    </div>
  );
};

export default PassportReservationForm;
