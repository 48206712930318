import Thankyou from "../../../components/Thankyou";
import { useSelector } from "react-redux";
import { getPassportScanData } from "../../../store/Checkin/Scan/scanPassportSlice";
import { getQrScanningData } from "../../../store/Checkin/QrCode/qrCodeSlice";

const ThankyouQrScanning = ()=>{
  const userData = useSelector(getQrScanningData);

    return <Thankyou userData={userData}/>;
}

export default ThankyouQrScanning;