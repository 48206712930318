import SignatureCanvas from "react-signature-canvas";

const SignaturePad = ({
  signature,
  setSignature,
  url,
  setUrl,
  gotSignature,
  showRetryBtn = true,
}) => {
  const clearSign = () => {
    signature.clear();
    setUrl(null);
  };

  return (
    <div>
      <div>
        <div className="signature-box">
          <SignatureCanvas
            canvasProps={{ height: 200, width: 700, className: "sigCanvas" }}
            ref={(data) => {
              setSignature(data);
            }}
            onEnd={gotSignature}
          />
        </div>
        <p className="sign-here">Sign Here</p>
      </div>
      {showRetryBtn && (
        <div className="d-flex justify-content-end">
          <button onClick={clearSign} className="btn terms-retry-btn">
            <i className="bx bx-revision"></i>Retry
          </button>
          {/* <button onClick={gotSignature} className="btn terms-retry-btn" style={{marginLeft:"7px"}}>
            Save
          </button> */}
        </div>
      )}
    </div>
  );
};

export default SignaturePad;
