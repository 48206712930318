import { useState } from "react";
import Footer from "../../../components/Layout/Footer";
import HomePageCard from "../../../components/Layout/Card/HomePageCard";
import KeyCard from "../../../components/Layout/Card/KeyCard";
import CreateKey from "../../../components/Createkey/Createkey";
import { encodeKey } from "../../../network/apiService";
import { useSelector } from "react-redux";
import { getconfirmationnumberData } from "../../../store/Checkin/ConfirmationNumber/confirmationNumberSlice";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import DownloadKeyModal from "../../../components/Layout/Card/DownloadKeyModal";
import { timerConstantValue } from "../../../constants/constant";

const CreateKeyConfirmationNO = () => {
  const timeLimit = timerConstantValue;
  const [showModal, setShowModal] = useState(false);
  const [showKeyModal, setShowKeyModal] = useState(false);
  const [showDownloadKeyModal, setDownloadKeyModal] = useState(false);
  const [timerValue, setTimerValue] = useState(timeLimit);
  const [restartTimer, setRestartTimer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [erroModalData, setErrorModalData] = useState({
    toggleError: false,
    errorMessage: "",
  });
  const userData = useSelector(getconfirmationnumberData);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const resetTimerAndClosePopUp = () => {
    if (!timerValue) {
      setTimerValue(timeLimit);
    }
    setRestartTimer(true);
    setShowModal(false);
  };

  const timerCounter = (value) => {
    setTimerValue(value);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const keyModal = () => {
    setShowKeyModal(false);
  };

  const closeKeyModal = () => {
    debugger
    setShowKeyModal(false);
    navigate("/confirmation-no-thank-you");
    return;
  };

  const openDownloadKey = () => {
    setDownloadKeyModal(true);
  };

  const closeDownloadKey = () => {
    setDownloadKeyModal(false);
  };

  const doneDownloadKey = () => {
    navigate("/confirmation-no-thank-you");
  };

  const stopTimer = () => {
    setRestartTimer(false);
  };

  const closeErrorModal = () => {
    setErrorModalData((state) => ({
      ...state,
      toggleError: false,
      errorMessage: "",
    }));
  };

  const createKeyHandler = async (createNewKey) => {
    try {
      setLoading(true);
      const sendData = {
        confirmationNo: userData?.pmsConfirmationNo,
        reservationNameID: userData?.reservationNameID,
        roomNo: userData?.roomNo,
        checkoutDate:
          moment(userData?.departureDate).format("YYYY-MM-DD") || "",
        checkoutTime: userData?.departureTime || "",
        isNewKey: true,
        workStationName: "Kiosk1",
      };

      if (createNewKey) setShowKeyModal(false);

      const response = await encodeKey(sendData, token);
      if (!createNewKey && response.status === 200) {
        setTimeout(() => {
          setLoading(false);
          setShowKeyModal(true);
        }, 2000);
        return;
      }

      if (createNewKey && response.status === 200) {
        setTimeout(() => {
          setLoading(false);
          navigate("/confirmation-no-thank-you");
        }, 2000);
        return;
      }
    } catch (error) {
      setLoading(false);
      setErrorModalData((state) => ({
        ...state,
        toggleError: true,
        errorMessage: error.message,
      }));
      console.log(error);
      return;
    }
  };

  return (
    <div>
      <CreateKey
        openModal={openModal}
        loading={loading}
        userData={userData}
        erroModalData={erroModalData}
        closeErrorModal={closeErrorModal}
        openDownloadKey={openDownloadKey}
        createKeyHandler={createKeyHandler}
      />
      <Footer
        page={"key"}
        showModal={showModal}
        openModal={openModal}
        timerCounter={timerCounter}
        timerValue={timerValue}
        restartTimer={restartTimer}
        stopTimer={stopTimer}
        timeLimit={timeLimit}
      />
      {showModal && (
        <HomePageCard
          showModal={showModal}
          resetTimerAndClosePopUp={resetTimerAndClosePopUp}
        />
      )}
      {showKeyModal && (
        <KeyCard closeKeyModal={closeKeyModal} keyModal={createKeyHandler} />
      )}
      {showDownloadKeyModal && (
        <DownloadKeyModal
          closeDownloadKey={closeDownloadKey}
          doneDownloadKey={doneDownloadKey}
        />
      )}
    </div>
  );
};

export default CreateKeyConfirmationNO;
