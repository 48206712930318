import { useEffect, useState } from "react";
import FaceMaskCard from "../../../components/Layout/Card/FaceMaskCard";
import HomePageCard from "../../../components/Layout/Card/HomePageCard";
import DocumentVerification from "../../../components/UserVerification/DocumentVerification";
import Footer from "../../../components/Layout/Footer";
import { scanPassportData } from "../../../constants/scanjsondata";
import { useSelector } from "react-redux";
import { getconfirmationnumberData } from "../../../store/Checkin/ConfirmationNumber/confirmationNumberSlice";
import { useNavigate } from "react-router-dom";
import { getPassportScanData } from "../../../store/Checkin/Scan/scanPassportSlice";
import { timerConstantValue } from "../../../constants/constant";

const DocumentScanPassportScan = () => {
  const timeLimit = timerConstantValue;
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timerValue, setTimerValue] = useState(timeLimit);
  const [restartTimer, setRestartTimer] = useState(false);
  const [successLoader, setSuccessLoader] = useState(false);
  const [erroModalData, setErrorModalData] = useState({
    toggleError: false,
    errorMessage: "",
  });
  const [flipModalToggle, setFlipModalToggle] = useState(false);
  const [flipDocument, setFlipDocument] = useState(false);
  const [countOfDocumentScan, setCountOfDocumentScan] = useState(0);
  const [scanData, setScanData] = useState({});
  const userData = useSelector(getPassportScanData);
  const navigate = useNavigate();
  const signedInUserName = userData.firstName + "" + userData.lastName;
  const token = localStorage.getItem("token");

  const openModal = () => {
    setShowModal(true);
  };

  const resetTimerAndClosePopUp = () => {
    if (!timerValue) {
      setTimerValue(timeLimit);
    }
    setRestartTimer(true);
    setShowModal(false);
  };

  const timerCounter = (value) => {
    setTimerValue(value);
  };

  const stopTimer = () => {
    setRestartTimer(false);
  };

  const closeErrorModal = () => {
    setErrorModalData((state) => ({
      ...state,
      toggleError: false,
      errorMessage: "",
    }));
  };

  const completedScanDone = () => {
    //api call for flip scan document

    // try {
    // const response = await scanUserPassport

    //after api call if response is success
    // if (signedInUserName !== scanData.firstName + "" + scanData.lastName) {
    //   setScanData(scanData);
    //logic of scanning multiple guest document after successful api call.
    // if (response?.data?.statusCode === 200){

    // apply the logic for successfull api call here and for error in the catch block which is commented down below

    setFlipModalToggle(false);
    setFlipDocument(false);
    setSuccessLoader(true);
    setTimeout(() => {
      setSuccessLoader(false);
      // setCountOfDocumentScan(0);
      navigate("/passport-scan-face-verification");
      return;
    }, 2000);
    // }
    // } catch (error) {
    //   setErrorModalData((state) => ({
    //     ...state,
    //     toggleError: true,
    //     errorMessage: error.message,
    //   }));
    // setLoading(false);
    // console.log(error);
    // }
  };

  useEffect(() => {
    // debugger;
    if (scanPassportData.idType === "P") {
      setSuccessLoader(true);
      setTimeout(() => {
        setSuccessLoader(false);
        // setCountOfDocumentScan(0);
        navigate("/passport-scan-face-verification");
        return;
      }, 2000);
      return;
    }
    if (scanPassportData.idType !== "" && scanPassportData.idType !== "P" && flipDocument && !loading) {
      setFlipModalToggle(true);
      return;
    }
  }, [
    flipDocument,
    loading,
    navigate,
  ]);

  const scanDocumentHandler = () => {
    //api call for scan document

    // const response = await scanUserPassport

    //after api call if response is success
    // if (signedInUserName !== scanData.firstName + "" + scanData.lastName) {
    //   setScanData(scanData);
    //logic of scanning multiple guest document after successful api call.
    setLoading(true);
    if (scanPassportData.idType === "P") {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      return;
    }
    if (scanPassportData.idType !== "P") {
      setFlipDocument(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      return;
    }
    // } else {
    //   setErrorModalData((state) => ({
    //     ...state,
    //     toggleError: true,
    //     errorMessage: "Username does not match",
    //   }));
    //   return;
    // }
  };

  return (
    <div>
      <DocumentVerification
        loading={loading}
        successLoader={successLoader}
        successMessage={"Document scanning completed succcessfully"}
        // reservationData={reservationData}
        openModal={openModal}
        closeErrorModal={closeErrorModal}
        erroModalData={erroModalData}
        userData={userData}
        scanDocumentHandler={scanDocumentHandler}
        completedScanDone={completedScanDone}
        flipModalToggle={flipModalToggle}
      />
      <Footer
        page={"documentscanning"}
        showModal={showModal}
        openModal={openModal}
        timerCounter={timerCounter}
        timerValue={timerValue}
        restartTimer={restartTimer}
        stopTimer={stopTimer}
        timeLimit={timeLimit}
      />
      {showModal && (
        <HomePageCard
          showModal={showModal}
          resetTimerAndClosePopUp={resetTimerAndClosePopUp}
        />
      )}
    </div>
  );
};

export default DocumentScanPassportScan;
