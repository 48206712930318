import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  scanDetails: {},
};

const scanSlice = createSlice({
  name: "scandocument",
  initialState,
  reducers: {
    scannedDocumentDetails: (state, action) => {
      state.scanDetails = action.payload;
    },
  },
});

export const getScanData = (state) =>
  state.scandocument.scanDetails;

export const { scannedDocumentDetails } = scanSlice.actions;

export default scanSlice.reducer;
