/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { useLocation, useNavigate } from "react-router-dom";
import "../../assets/stylesheet/scan.css";
import ErrorPopup from "../LoaderAndNotification/ErrorPopup";
import LoaderPopup from "../LoaderAndNotification/LoaderPopup";
import SuccessPopup from "../LoaderAndNotification/SuccessPopup";
import FlipDocumentModal from "../Layout/Card/FlipDocumentModal";
import { capitalizeFullName, nameValidation } from "../../helpers/commonHelper";

const DocumentVerification = ({
  loading,
  reservationData,
  openModal,
  userData,
  openMaskModal,
  closeErrorModal,
  erroModalData,
  scanDocumentHandler,
  successLoader,
  successMessage,
  flipModalToggle,
  completedScanDone,
}) => {
  return (
    <div>
      <main>
        <div className="main-content">
          <div className="h-100">
            <div className="content-bg h-100">
              <div className="row h-100">
                <div className="col-sm-6 pl-0">
                  <div className="h-100">
                    <div>
                      <button
                        onClick={() => {
                          openModal();
                        }}
                        className="btn home-btn mt-6"
                      >
                        <i className="ri-arrow-go-back-line mr-3"></i>RESTART
                      </button>
                    </div>

                    <LoaderPopup loading={loading} />
                    <ErrorPopup
                      closeErrorModal={closeErrorModal}
                      erroModalData={erroModalData}
                    />
                    <SuccessPopup
                      loading={successLoader}
                      successMessage={successMessage}
                    />
                    <FlipDocumentModal
                      completedScanDone={completedScanDone}
                      flipModalToggle={flipModalToggle}
                    />

                    <div className="scan-content-left">
                      <div className="mt-115">
                        <div className="scan-text">
                          <h4 className="text-center  font-weight-500">
                            <strong>
                              {nameValidation(
                                userData.firstName,
                                userData.lastName
                              )}
                            </strong>
                          </h4>
                          <p className="text-center  font-weight-500 mb-0" style={{marginTop:'20px', fontSize: '20px'}}>
                            Place your valid document on the scanner and touch 'SCAN'
                          </p>
                        
                        </div>
                        <div className="text-center mt-30">
                          <button
                            onClick={() => {
                              scanDocumentHandler();
                            }}
                            className="btn button-blue scan-btn"
                            // onClick={()=>{setShowMaskModal(true)}}
                          >
                            <i className="bx bx-barcode-reader scan-icon"></i>
                            Scan
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 pr-0">
                  <div className="scan-img">
                    <img src="./assets/images/scan-bg.jpg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default DocumentVerification;
