/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from "react";
import HomePageCard from "../Layout/Card/HomePageCard";
import profileImage from "../../assets/images/profile-small.jpg";
import "../../assets/stylesheet/confirmation.css";
import "../../assets/stylesheet/reservation-form.css";
import "../../assets/stylesheet/confirmation-number.css";
import "../../assets/stylesheet/screen-verification.css";
import "../../assets/stylesheet/find-booking.css";
import "../../assets/stylesheet/boxicons/css/boxicons.min.css";
import { useNavigate } from "react-router-dom";
import ErrorPopup from "../LoaderAndNotification/ErrorPopup";
import SuccessPopup from "../LoaderAndNotification/SuccessPopup";
import LoaderPopup from "../LoaderAndNotification/LoaderPopup";
import FaceRcgFailedModal from "../Layout/Card/FaceRcgFailedModal";
import { capitalizeFullName } from "../../helpers/commonHelper";

const FaceVerification = ({
  loading,
  openModal,
  closeErrorModal,
  erroModalData,
  signedInUserName,
  successLoader,
  successMessage,
  facialRecognisationFailed,
  completedFacialRecognisation,
  backToHomePage,
  faceImage
}) => {
  return (
    <div>
      <main>
        <div className="main-content bg-invoice-white">
          <div>
            <div>
              <button
                onClick={() => {
                  openModal(true);
                }}
                type="button"
                className="btn home-btn"
              >
                <i className="ri-arrow-go-back-line mr-3"></i>RESTART
              </button>
            </div>
          </div>
          <div className="face-verify-center">
            <div>
              <div className="page-title-name" style={{fontSize:'40px'}}>
                {capitalizeFullName(signedInUserName) || "-"}
              </div>
            </div>
            <div className="page-title mt-20">
              Please look at the screen while
              <br></br>
              we verify your ID
            </div>

            <div className="profile-scan text-center">
              <img src={faceImage} />
            </div>

            <div className="mt-30 text-center d-flex justify-content-center align-items-center">
              <p className="donut mb-0"></p>
              <p className="mb-0 verify-text">Verifying your ID</p>
            </div>
            <div className="text-center">
              <p className="verify-text-set">
                <span className="mat-icon material-symbols-outlined">
                  <i className="bx bxs-error"></i>
                </span>
                Please remove your Mask, Glass and Cap for face verification
              </p>
            </div>
          </div>
        </div>
        {/* <LoaderPopup loading={loading} /> */}
        <ErrorPopup
          closeErrorModal={closeErrorModal}
          erroModalData={erroModalData}
        />
        <SuccessPopup loading={successLoader} successMessage={successMessage} />
        {facialRecognisationFailed && (
          <FaceRcgFailedModal
            ficalRecognisationModal={facialRecognisationFailed}
            completedFacialRecognisation={completedFacialRecognisation}
            backToHomePage={backToHomePage}
          />
        )}
      </main>
    </div>
  );
};

export default FaceVerification;
