import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getconfirmationnumberData,
  userReservationDetailsConfirmatioNo,
} from "../../../store/Checkin/ConfirmationNumber/confirmationNumberSlice";
import FaceVerification from "../../../components/UserVerification/FaceVerification";
import HomePageCard from "../../../components/Layout/Card/HomePageCard";
import Footer from "../../../components/Layout/Footer";
import FaceMaskCard from "../../../components/Layout/Card/FaceMaskCard";
import {
  getPassportScanData,
  getScanPassportCurrentCountOfGuest,
  scanPassportCountOfTheGuests,
  userReservationDetailsPassport,
} from "../../../store/Checkin/Scan/scanPassportSlice";
import { timerConstantValue } from "../../../constants/constant";
import { userReservationDetailsQr } from "../../../store/Checkin/QrCode/qrCodeSlice";
import { nameValidation } from "../../../helpers/commonHelper";

import {getScanData} from "../../../store/Checkin/Scan/documentScanSlice";
import { faceVerification } from "../../../network/apiService";



const FaceVerificationPassportScan = () => {
  const timeLimit = timerConstantValue;
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [successLoader, setSuccessLoader] = useState(false);
  const [timerValue, setTimerValue] = useState(timeLimit);
  const [restartTimer, setRestartTimer] = useState(false);
  useState(0);
  const [facialRecognisationFailed, setFacialRecognisationFailed] =
    useState(false);
  // const [showMaskModal, setShowMaskModal] = useState(true);
  const [erroModalData, setErrorModalData] = useState({
    toggleError: false,
    errorMessage: "",
  });
  const token = localStorage.getItem("token");
  const userData = useSelector(getPassportScanData);
  const totalCount = +userData?.adultCount + +userData?.childCount || 1;

  // const signedInUserName = nameValidation(
  //   userData.firstName,
  //   userData.lastName
  // );

  // const signedInUserName =
  //   userData.firstName && userData.lastName
  //     ? userData.firstName + " " + userData.lastName
  //     : "null";


  const dispatch = useDispatch();
  const guestCount = useSelector(getScanPassportCurrentCountOfGuest);
  const scanData= useSelector (getScanData);
  const signedInUserName = scanData.GivenName + " " + scanData.LastName;
  const faceImage= scanData.Portrait?"data:image/jpeg;base64," +scanData.Portrait:"";

  useEffect(() => {
    verifyFace();
  }, [guestCount]);

//New use effect
  // useEffect(() => {
  //   if (guestCount === totalCount) {
  //     setSuccessLoader(true);
  //     setTimeout(() => {
  //       setSuccessLoader(false);
  //       navigate("/passport-scan-reservation-details");
  //       return;
  //     }, 2000);
  //   }
  //   if (guestCount < totalCount) {
  //     setSuccessLoader(true);
  //     setTimeout(() => {
  //       setSuccessLoader(false);
  //       navigate("/passport-scan-document-verification");
  //       return;
  //     }, 2000);
  //   }
  // }, [guestCount, navigate, totalCount]);

  // useEffect(() => {
  //   if (guestCount === totalCount) {
  //     setSuccessLoader(true);
  //     setTimeout(() => {
  //       setSuccessLoader(false);
  //       navigate("/passport-scan-reservation-details");
  //       return;
  //     }, 2000);
  //   }
  //   if (guestCount < totalCount) {
  //     setSuccessLoader(true);
  //     setTimeout(() => {
  //       setSuccessLoader(false);
  //       navigate("/passport-scan-document-verification");
  //       return;
  //     }, 2000);
  //   }
  // }, [
  //   guestCount,
  //   navigate,
  //   totalCount,
  // ]);

  // const verifyFace = useCallback(async () => {
  //   // try{
  //   //api call for scan document

  //   // const response = await faceVerify

  //   // after api call if api call is successfull then line 63 code will run
  //   if (
  //     guestCount < totalCount
  //     // checking the status of response if it is successfull modal pop up will not appear
  //     // && response.status === 200
  //   ) {
  //     const changeRoute = setTimeout(() => {
  //       setSuccessLoader(true);
  //       setTimeout(() => {
  //         setSuccessLoader(false);
  //         dispatch(scanPassportCountOfTheGuests(1));
  //         // setFacialRecognisationFailed(true);
  //         // navigate("/confirmation-no-reservation-details");
  //       }, 3000);
  //     }, 2000);

  //     return () => clearTimeout(changeRoute);
  //   }

  //   //after api call if api fails then the below code will work

  //   // setLoading(true);

  //   //logic of facial recognisation of multiple guest after api call is not successfull then line 63 code will run
  //   if (
  //     guestCount < totalCount
  //     // checking the status of response if it is not successfull modal pop up will not appear
  //     // && response.status !== 200
  //   ) {
  //     const changeRoute = setTimeout(() => {
  //       setLoading(false);
  //       // setSuccessLoader(true);
  //       setTimeout(() => {
  //         dispatch(scanPassportCountOfTheGuests(1));
  //         // setSuccessLoader(false);
  //         setFacialRecognisationFailed(true);
  //         // navigate("/confirmation-no-reservation-details");
  //       }, 3000);
  //     }, 2000);

  //     return () => clearTimeout(changeRoute);
  //   }
  //   // }catch(error){
  //   //   console.log(error);

  //   //setting state to true if api is failed so that we can enable the continue modal
  //   // setFacialRecognisationFailed(true)
  //   // }

  //   // }
  // }, [dispatch, guestCount, totalCount]);

  // // const closeMaskModal = () => {
  // //   setShowMaskModal(false);
  // // };

  // const manualFaceVerification = () => {
  //   // try{
  //   // setLoading(true);
  //   //do the api call here
  //   // const response = await apiName("payload", token)
  //   //after api checking the name of the user through api response
  //   // const userFullName = response.firstName + "" + response.lastName;
  //   // if(userFullName === signedInUserName){
  //   // if (!showMaskModal) {
  //   setFacialRecognisationFailed(false);
  //   if (guestCount < totalCount) {
  //     const changeRoute = setTimeout(() => {
  //       dispatch(scanPassportCountOfTheGuests(1));
  //       setSuccessLoader(true);
  //       setTimeout(() => {
  //         setSuccessLoader(false);
  //       }, 3000);
  //     }, 2000);

  //     return () => clearTimeout(changeRoute);
  //   }
  //   return;
  //   // }

  //   // }catch(error){
  //   //   console.log(error);
  //   // }

  //   // }
  // };



  const verifyFace = useCallback(async () => {
    // try{
    //api call for scan document

    // const response = await faceVerify

    try{
    
         
    const  faceVerificationData = await faceVerification({});
    
    setLoading(true);

    if(faceVerificationData.data!=null)
    {
      if(faceVerificationData.data.ComparisonResult==1 && faceVerificationData.data.ComparisonFaceScore>85)
      {
        if(totalCount === guestCount)
        {
          setLoading(false);
            setSuccessLoader(true);
          setTimeout(() => {
            setSuccessLoader(false);
            //navigate("/passport-scan-reservation-details");
            navigate("/confirmation-no-upgrade");
            return;
          }, 3000);
        }
        if (guestCount < totalCount) {
          setLoading(false);
          setSuccessLoader(true);
          setTimeout(() => {
            setSuccessLoader(false);
            navigate("/confirmation-no-document-verification");
            return;
          }, 2000);
        }
     
      }
      else
      {
        setLoading(false);
        //message="Face verification failed";
        //loadSuccess= false;
        // setErrorModalData((state) => ({
        //   ...state,
        //   toggleError: true,
        //   errorMessage: "Facial verification failed",
        // }));
        setFacialRecognisationFailed(true);
      }

//navigate to face verification 
//navigate("/passport-scan-face-verification");
//return;

    }
    else{
      //message="Face verification failed";
      //loadSuccess= false;
      setLoading(false);
      // setErrorModalData((state) => ({
      //   ...state,
      //   toggleError: true,
      //   errorMessage: "Facial verification failed",
      // }));
      setFacialRecognisationFailed(true);
    }
  }
  catch(error)
  {
    setFacialRecognisationFailed(true)
  }


    // // after api call if api call is successfull then line 63 code will run
    // if (
    //   guestCount < totalCount
    //   // checking the status of response if it is successfull modal pop up will not appear
    //   // && response.status === 200
    // ) {
    //   const changeRoute = setTimeout(() => {
    //     setSuccessLoader(true);
    //     setTimeout(() => {
    //       setSuccessLoader(false);
    //       dispatch(confNoCountOfTheGuests(1));
    //       // setFacialRecognisationFailed(true);
    //       // navigate("/confirmation-no-reservation-details");
    //     }, 3000);
    //   }, 2000);

    //   return () => clearTimeout(changeRoute);
    // }

    // //after api call if api fails then the below code will work

    // // setLoading(true);

    // //logic of facial recognisation of multiple guest after api call is not successfull then line 63 code will run
    // if (
    //   guestCount < totalCount
    //   // checking the status of response if it is not successfull modal pop up will not appear
    //   // && response.status !== 200
    // ) {
    //   const changeRoute = setTimeout(() => {
    //     setLoading(false);
    //     // setSuccessLoader(true);
    //     setTimeout(() => {
    //       dispatch(confNoCountOfTheGuests(1));
    //       // setSuccessLoader(false);
    //       setFacialRecognisationFailed(true);
    //       // navigate("/confirmation-no-reservation-details");
    //     }, 3000);
    //   }, 2000);

    //   return () => clearTimeout(changeRoute);
    // }
    // }catch(error){
    //   console.log(error);

    //setting state to true if api is failed so that we can enable the continue modal
    // setFacialRecognisationFailed(true)
    // }

    // }
  }, [dispatch, guestCount, totalCount]);

  const manualFaceVerification = () => {
    // try{
    // setLoading(true);
    //do the api call here
    // const response = await apiName("payload", token)
    //after api checking the name of the user through api response
    // const userFullName = response.firstName + "" + response.lastName;
    // if(userFullName === signedInUserName){
    // if (!showMaskModal) {
    setFacialRecognisationFailed(false);
    // if (guestCount < totalCount) {
    //   const changeRoute = setTimeout(() => {
    //     dispatch(confNoCountOfTheGuests(1));
    //     setSuccessLoader(true);
    //     setTimeout(() => {
    //       setSuccessLoader(false);
    //     }, 3000);
    //   }, 2000);

    //   return () => clearTimeout(changeRoute);
    // }
    //navigate("/passport-scan-reservation-details");
    navigate("/confirmation-no-upgrade");
    return;
    // }

    // }catch(error){
    //   console.log(error);
    // }

    // }
  };

  const navigateToHomePage = () => {
    localStorage.removeItem("confirmationNo");
    localStorage.removeItem("token");
    dispatch(userReservationDetailsQr({}));
    dispatch(userReservationDetailsPassport({}));
    dispatch(userReservationDetailsConfirmatioNo({}));
    navigate("/");
    return;
  };

  const resetTimerAndClosePopUp = () => {
    if (!timerValue) {
      setTimerValue(timeLimit);
    }
    setRestartTimer(true);
    setShowModal(false);
  };

  const timerCounter = (value) => {
    setTimerValue(value);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const stopTimer = () => {
    setRestartTimer(false);
  };

  const closeErrorModal = () => {
    setErrorModalData((state) => ({
      ...state,
      toggleError: false,
      errorMessage: "",
    }));
  };

  return (
    <div>
      <FaceVerification
        loading={loading}
        successLoader={successLoader}
        successMessage={"Face verification completed succcessfully"}
        openModal={openModal}
        closeErrorModal={closeErrorModal}
        erroModalData={erroModalData}
        signedInUserName={signedInUserName}
        facialRecognisationFailed={facialRecognisationFailed}
        completedFacialRecognisation={manualFaceVerification}
        backToHomePage={navigateToHomePage}
        faceImage={faceImage}
      />
      <Footer
        page={"facerecognition"}
        showModal={showModal}
        openModal={openModal}
        timerCounter={timerCounter}
        timerValue={timerValue}
        restartTimer={restartTimer}
        stopTimer={stopTimer}
        timeLimit={timeLimit}
      />
      {showModal && (
        <HomePageCard
          showModal={showModal}
          resetTimerAndClosePopUp={resetTimerAndClosePopUp}
        />
      )}
      {/* {showMaskModal && <FaceMaskCard closeModal={closeMaskModal} />} */}
    </div>
  );
};

export default FaceVerificationPassportScan;
