import { useEffect, useState } from "react";
import HomePageCard from "../../../components/Layout/Card/HomePageCard";
import DocumentVerification from "../../../components/UserVerification/DocumentVerification";
import Footer from "../../../components/Layout/Footer";
import { scanPassportData } from "../../../constants/scanjsondata";
import { useSelector } from "react-redux";
import { getconfirmationnumberData } from "../../../store/Checkin/ConfirmationNumber/confirmationNumberSlice";
import { useNavigate } from "react-router-dom";
import { timerConstantValue } from "../../../constants/constant";
import { scannedDocumentDetails } from "../../../store/Checkin/Scan/documentScanSlice";
import {clearScanData,getScanData} from "../../../network/apiService";
import { useDispatch } from "react-redux";
import { reservationSample } from "../../../constants/reservationSample";

const DocumentScanConfirmationNo = () => {
  const timeLimit = timerConstantValue;
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timerValue, setTimerValue] = useState(timeLimit);
  const [restartTimer, setRestartTimer] = useState(false);
  const [successLoader, setSuccessLoader] = useState(false);
  const [erroModalData, setErrorModalData] = useState({
    toggleError: false,
    errorMessage: "",
  });
  const [flipModalToggle, setFlipModalToggle] = useState(false);
  const [flipDocument, setFlipDocument] = useState(false);
  const [scanData, setScanData] = useState({});
  const [clearData, setClearData] = useState({});
  const userData = useSelector(getconfirmationnumberData);

  //const userData = reservationSample?.data;
  const navigate = useNavigate();
  const signedInUserName = userData.firstName + "" + userData.lastName;

  //console.log("Name--->"+JSON.stringify(userData))
  //signedInUserName=signedInUserName.replace(/\s/g, "");
  const token = localStorage.getItem("token");

  const dispatch = useDispatch();

let scanName="";
let signName = signedInUserName.replace(/\s/g, "");

  const openModal = () => {
    setShowModal(true);
  };

  const resetTimerAndClosePopUp = () => {
    if (!timerValue) {
      setTimerValue(timeLimit);
    }
    setRestartTimer(true);
    setShowModal(false);
  };

  const timerCounter = (value) => {
    setTimerValue(value);
  };

  const stopTimer = () => {
    setRestartTimer(false);
  };

  const closeErrorModal = () => {
    setErrorModalData((state) => ({
      ...state,
      toggleError: false,
      errorMessage: "",
    }));
  };

  const completedScanDone = async () => {
    //api call for flip scan document

    // try {
    // const response = await scanUserPassport

    //after api call if response is success
    // if (signedInUserName !== scanData.firstName + "" + scanData.lastName) {
    //   setScanData(scanData);
    //logic of scanning multiple guest document after successful api call.
    // if (response?.data?.statusCode === 200){

    // apply the logic for successfull api call here and for error in the catch block which is commented down below
    
    const scanData = await getScanData({});

      debugger 

    if(scanData!=null && scanData.data!=null && scanData.data.DocumentImageSide1!=null && scanData.data.DocumentImageSide2!=null)
    {

      dispatch(scannedDocumentDetails(scanData.data));
      //console.log(scanData.data.LastName);

     
      //navigate to face verification 
      if(scanData.data.Portrait !=null)
      {
        setFlipDocument(false);
        setFlipModalToggle(false);
        setSuccessLoader(true);
        setTimeout(() => {
          setSuccessLoader(false);
          navigate("/confirmation-no-face-verification");
          return;
        }, 2000);
        //  navigate("/passport-scan-face-verification");
         // return;
      }
      else{
         
        setFlipDocument(false);
        setFlipModalToggle(false);
        setErrorModalData((state) => ({
          ...state,
          toggleError: true,
          errorMessage: "Scanning failed",
        }));
        setLoading(false);
       // console.log(error);
        return;
      }
    }
    else{
      setErrorModalData((state) => ({
        ...state,
        toggleError: true,
        errorMessage: "Scanning failed",
      }));
      setLoading(false);
     // console.log(error);
      //return;
    }


   
    // }
    // } catch (error) {
    //   setErrorModalData((state) => ({
    //     ...state,
    //     toggleError: true,
    //     errorMessage: error.message,
    //   }));
    // setLoading(false);
    // console.log(error);
    // }
  };

  useEffect(() => {
    if (scanData.DocumentType === "P") {
      setSuccessLoader(true);
      setTimeout(() => {
        setSuccessLoader(false);
        navigate("/confirmation-no-face-verification");
        return;
      }, 2000);
      return;
    }
    if (scanData.DocumentType !=="" && scanData.DocumentType !== "P" && flipDocument && !loading) {
      setFlipModalToggle(true);
      return;
    }
  }, [
    flipDocument,
    loading,
    navigate,
  ]);

  const scanDocumentHandler = async () => {
    //api call for scan document

    // try {
    // const response = await scanUserPassport

    //after api call if response is success
    // if (signedInUserName !== scanData.firstName + "" + scanData.lastName) {
    //   setScanData(scanData);
    //logic of scanning multiple guest document after successful api call.
    try
    {
    const clearData = await clearScanData({});



    setLoading(true);

    const scanData = await getScanData({});

    debugger
    // if(scanData.data!=null && scanData.data.FullImage!=null)
    // {
    //   dispatch(scannedDocumentDetails(scanData.data));
    //   console.log(scanData.data.LastName);
    // }
    if(scanData.data!=null && scanData.data.DocumentImageSide1!=null)
    {

      dispatch(scannedDocumentDetails(scanData.data));
      //console.log(scanData.data.LastName);

      //scanData.data.DocumentType ="ID"

      if(scanData.data.DocumentType !=="" && scanData.data.DocumentType!=="P")
      {
        setLoading(false);
       
        setFlipModalToggle(true);
        setFlipDocument(true);
        // setTimeout(() => {
        //   setLoading(false);
        // }, 1000);
        return;
        //setScanLoader(true);
        //return;
      }
      else if(scanData.data.Portrait!==null && scanData.data.DocumentType==="P")
      {
        scanName= scanData.data.GivenName +""+ scanData.data.LastName;
        scanName=scanName.replace(/\s/g, "");

        dispatch(scannedDocumentDetails(scanData.data));
        setTimeout(() => {
          setSuccessLoader(false);
          navigate("/confirmation-no-face-verification");
          return;
        }, 2000);
      // if (signName.toUpperCase() === scanName.toUpperCase()){
      //   dispatch(scannedDocumentDetails(scanData.data));
      //   setTimeout(() => {
      //     setSuccessLoader(false);
      //     navigate("/confirmation-no-face-verification");
      //     return;
      //   }, 2000);
      //   //navigate("/confirmation-no-face-verification");
      //  // return;
      // }
     
    }
    else{
      setErrorModalData((state) => ({
        ...state,
        toggleError: true,
        errorMessage: "Unable to scan document",
      }));
      setLoading(false);
      return;
    }
    }

    //Commenting out their code//
   // #region
    // if (scanPassportData.idType === "P") {
    //   setTimeout(() => {
    //     setLoading(false);
    //   }, 1000);
    //   return;
    // }
    // if (scanPassportData.idType !== "P") {
    //   setFlipDocument(true);
    //   setTimeout(() => {
    //     setLoading(false);
    //   }, 1000);
    //   return;
    // }
    //#endregion
    } catch (error) {
      setErrorModalData((state) => ({
        ...state,
        toggleError: true,
        errorMessage: error.message,
      }));
    setLoading(false);
    console.log(error);
    }
  };

  return (
    <div>
      <DocumentVerification
        loading={loading}
        successLoader={successLoader}
        successMessage={"Document scanning completed succcessfully"}
        // reservationData={reservationData}
        openModal={openModal}
        closeErrorModal={closeErrorModal}
        erroModalData={erroModalData}
        userData={userData}
        scanDocumentHandler={scanDocumentHandler}
        completedScanDone={completedScanDone}
        flipModalToggle={flipModalToggle}
      />
      <Footer
        page={"documentscanning"}
        showModal={showModal}
        openModal={openModal}
        timerCounter={timerCounter}
        timerValue={timerValue}
        restartTimer={restartTimer}
        stopTimer={stopTimer}
        timeLimit={timeLimit}
      />
      {showModal && (
        <HomePageCard
          showModal={showModal}
          resetTimerAndClosePopUp={resetTimerAndClosePopUp}
        />
      )}
    </div>
  );
};

export default DocumentScanConfirmationNo;
