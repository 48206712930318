/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import HomePageCard from "../../components/Layout/Card/HomePageCard";
import { useState, useEffect } from "react";
import "../../assets/stylesheet/confirmation.css";
import "../../assets/stylesheet/reservation-form.css";
import "../../assets/stylesheet/find-booking.css";
import "../../assets/stylesheet/confirmation-number.css";
import Footer from "../../components/Layout/Footer";
import { useNavigate } from "react-router-dom";
import LoaderPopup from "../../components/LoaderAndNotification/LoaderPopup";
import {
  assignRoom,
  getAvailableRooms,
  getReservationDetails,
} from "../../network/apiService";
import ErrorPopup from "../../components/LoaderAndNotification/ErrorPopup";
import { timerConstantValue } from "../../constants/constant";
import moment from "moment";
import { userReservationDetailsCheckoutPage } from "../../store/Checkout/checkoutSlice";
import {
  resetGuestCount,
  userReservationDetailsConfirmatioNo,
} from "../../store/Checkin/ConfirmationNumber/confirmationNumberSlice";
import { userReservationDetailsQr } from "../../store/Checkin/QrCode/qrCodeSlice";
import { userReservationDetailsPassport } from "../../store/Checkin/Scan/scanPassportSlice";
import { useDispatch } from "react-redux";

const ConfirmationNumber = () => {
  const timeLimit = timerConstantValue;
  const [showModal, setShowModal] = useState(false);
  const [confirmationNumberData, setConfirmationNumberData] = useState({
    lastName: "",
    confirmationNumber: "",
  });
  const [active, setActive] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [timerValue, setTimerValue] = useState(timeLimit);
  const [restartTimer, setRestartTimer] = useState(false);
  const [erroModalData, setErrorModalData] = useState({
    toggleError: false,
    errorMessage: "",
  });
  const [erroModalData2, setErrorModalData2] = useState({
    toggleError: false,
    errorMessage: "",
  });
  const [erroModalData3, setErrorModalData3] = useState({
    toggleError: false,
    errorMessage: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  const resetTimerAndClosePopUp = () => {
    if (!timerValue) {
      setTimerValue(timeLimit);
    }
    setRestartTimer(true);
    setShowModal(false);
  };

  const timerCounter = (value) => {
    setTimerValue(value);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const stopTimer = () => {
    setRestartTimer(false);
  };

  const closeErrorModal = () => {
    setErrorModalData((state) => ({
      ...state,
      toggleError: false,
      errorMessage: "",
    }));
  };

  const closeErrorModal2 = () => {
    setErrorModalData2((state) => ({
      ...state,
      toggleError: false,
      errorMessage: "",
    }));
    homePageHandler();
    return;
  };

  const closeErrorModal3 = () => {
    setErrorModalData3((state) => ({
      ...state,
      toggleError: false,
      errorMessage: "",
    }));
    homePageHandler();
    return;
  };

  const confirmationDataChangeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setConfirmationNumberData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const homePageHandler = () => {
    localStorage.removeItem("confirmationNo");
    localStorage.removeItem("token");
    localStorage.removeItem("LastName");
    dispatch(userReservationDetailsCheckoutPage({}));
    dispatch(userReservationDetailsQr({}));
    dispatch(userReservationDetailsPassport({}));
    dispatch(userReservationDetailsConfirmatioNo({}));
    setConfirmationNumberData({ lastName: "", confirmationNumber: "" });
    navigate("/");
    return;
  };

  const getConfirmationDetails = async () => {
    try {
      setLoading(true);
      const newObj = {
        LastName: confirmationNumberData.lastName,
        ConfirmationNo: confirmationNumberData.confirmationNumber,
      };
      const response = await getReservationDetails(newObj, token);

      debugger

      if (response.statusCode === 200) {
       
        setConfirmationNumberData({
          lastName: "",
          confirmationNumber: "",
        });
        localStorage.setItem(
          "confirmationNo",
          response.data.pmsConfirmationNo
        );
        setTimeout(() => {
          setLoading(false);
          navigate("/confirmation-no-reservation-form");
        }, 2000);
        
        return;
      }
      else if (
        response.statusCode === 200 &&
        !response?.data?.reservationStatus
      ) {
        setLoading(false);
        setErrorModalData((state) => ({
          ...state,
          toggleError: true,
          errorMessage:
            "Your reservation is not active. Please visit front desk for assistance.",
        }));
        return;
      }
      if (response.data.statusCode === 102) {
        setErrorModalData((state) => ({
          ...state,
          toggleError: true,
          errorMessage: response.data.message,
        }));
        setLoading(false);
        return;
      }
    } catch (error) {
      const url = new URL(error?.config?.url, window.location.origin);
      if (url.pathname === "/api/Kiosk/GetReservationDetails") {
        setLoading(false);
        setErrorModalData((state) => ({
          ...state,
          toggleError: true,
          errorMessage: error.message,
        }));
        return;
      }
      if (url.pathname === "/api/Kiosk/GetAvailableRooms") {
        setLoading2(false);
        setErrorModalData2((state) => ({
          ...state,
          toggleError: true,
          errorMessage: error.message,
        }));
        return;
      }
      if (url.pathname === "/api/Kiosk/AssignRoom") {
        setLoading3(false);
        setErrorModalData3((state) => ({
          ...state,
          toggleError: true,
          errorMessage: error.message,
        }));
        return;
      }
    }
    return;
  };

  useEffect(() => {
    if (
      confirmationNumberData.lastName &&
      confirmationNumberData.confirmationNumber
    ) {
      setActive("active");
    } else {
      setActive("");
    }
  }, [
    confirmationNumberData.confirmationNumber,
    confirmationNumberData.lastName,
  ]);

  return (
    <div>
      <main>
        <div className="main-content confirmation-white">
          <div>
            <div>
              <button
                onClick={() => {
                  navigate(-1);
                }}
                className="btn button-blue back-btn"
              >
                <i className="bx bx-chevron-left"></i>BACK
              </button>
            </div>
            <div>
              <button
                onClick={() => {
                  setShowModal(true);
                }}
                className="btn home-btn"
              >
                <i className="ri-arrow-go-back-line mr-3"></i>RESTART
              </button>
            </div>
          </div>
          <div className="checkout-content-center">
            <div>
              <div className="d-flex justify-content-center did-floating-label-content">
                <input
                  type="text"
                  name="lastName"
                  value={confirmationNumberData.lastName}
                  onChange={confirmationDataChangeHandler}
                  className="form-control form-control-set did-floating-input"
                  autoComplete="off"
                  placeholder=""
                />
                <label className="did-floating-label">Last Name</label>
              </div>
              <div className="d-flex justify-content-center did-floating-label-content">
                <input
                  type="text"
                  name="confirmationNumber"
                  value={confirmationNumberData.confirmationNumber}
                  onChange={confirmationDataChangeHandler}
                  className="form-control form-control-set did-floating-input"
                  autoComplete="off"
                  placeholder=""
                />
                <label className="did-floating-label">
                  Confirmation Number
                </label>
              </div>
            </div>

            <LoaderPopup loading={loading} />
            <ErrorPopup
              closeErrorModal={closeErrorModal}
              erroModalData={erroModalData}
            />
            <LoaderPopup loading={loading2} />
            <ErrorPopup
              closeErrorModal={closeErrorModal2}
              erroModalData={erroModalData2}
            />
            <LoaderPopup loading={loading3} />
            <ErrorPopup
              closeErrorModal={closeErrorModal3}
              erroModalData={erroModalData3}
            />

            <div className="reservation-btn-set p-0 mt-top">
              <div className="find-booking p-0">
                <button
                  onClick={getConfirmationDetails}
                  className={`btn button-outline find-booking-btn ${active}`}
                  disabled={active ? false : true}
                >
                  Search
                </button>
              </div>
            </div>
          </div>

          {/* <div className="confirmation-number-image mt-30">
            <img src={confirmation_number} />
          </div> */}
        </div>

        <Footer
          page={"documentscanning"}
          showModal={showModal}
          openModal={openModal}
          timerCounter={timerCounter}
          timerValue={timerValue}
          restartTimer={restartTimer}
          stopTimer={stopTimer}
          timeLimit={timeLimit}
        />
      </main>
      {showModal && (
        <HomePageCard
          resetTimerAndClosePopUp={resetTimerAndClosePopUp}
          showModal={showModal}
        />
      )}
    </div>
  );
};

export default ConfirmationNumber;
