import { useEffect } from "react";

const FooterTimer = ({
  timerValue,
  showModal,
  openModal,
  timerCounter,
  restartTimer,
  stopTimer,
  timeLimit,
}) => {
  useEffect(() => {
    if (!timerValue && restartTimer && !showModal) {
      stopTimer();
      return;
    }
  }, [restartTimer, showModal, stopTimer, timerValue]);

  useEffect(() => {
    let interval = null;

    if (timerValue === 0 && !showModal && !restartTimer) {
      openModal();
      return;
    }

    if (timerValue > 0) {
      interval = setInterval(() => {
        timerCounter(timerValue - 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [showModal, timerValue, restartTimer, openModal, timerCounter]);

  const progressOffset = Math.round(100 - (100 * timerValue) / timeLimit);

  return (
    <>
      <div className="completed-status">
        <span>{timerValue}</span>
        <div className="base-timer">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="-1 -1 34 34">
            <circle
              cx="16"
              cy="16"
              r="15.9155"
              className="progress-bar__background"
            />
            <circle
              cx="16"
              cy="16"
              r="15.9155"
              className="progress-bar__progress js-progress-bar"
              style={{ strokeDashoffset: progressOffset }}
            />
          </svg>
        </div>
      </div>
    </>
  );
};

export default FooterTimer;
