import { Route, Routes, Navigate } from "react-router-dom";
import HomePage from "../pages/HomePage";
import CheckinPage from "../pages/CheckinPage";
import PassportScanning from "../containers/PassportChecking/PassportScanning";
import QrScanning from "../containers/QrCodeCheckin/QrScanning";
import ConfirmationNumber from "../containers/ConfirmationNumberCheckin/ConfirmationNumber";
import CheckOutPage from "../pages/CheckOutPage";
import AgreedForm from "../components/TermsAndConditions/AgreedForm";
import PrivateRoute from "../HOC/PrivateRoute";
import InvoicePage from "../components/InvoicePage/InvoicePage";
import Payment2 from "../components/Payment/Payment2";
import Thankyou2 from "../components/Thanyou2";
import ConfirmationNumberReservationForm from "../containers/ConfirmationNumberCheckin/components/ConfirmationNumberReservationForm";
import DocumentScanConfirmationNo from "../containers/ConfirmationNumberCheckin/components/DocumentScanConfirmationNo";
import FaceVerificationConfirmationNo from "../containers/ConfirmationNumberCheckin/components/FaceVerificationConfirmationNo";
import UpgradeOptionConfirmationNo from "../containers/ConfirmationNumberCheckin/components/UpgradeOptionConfirmationNo";
import ReservationDetailsConfirmationNo from "../containers/ConfirmationNumberCheckin/components/ReservationDetailsConfirmationNo";
import CreateKeyConfirmationNO from "../containers/ConfirmationNumberCheckin/components/CreateKeyConfirmationNo";
import PaymentOnConfirmationNo from "../containers/ConfirmationNumberCheckin/components/PaymentOnConfirmationNo";
import PassportReservationForm from "../containers/PassportChecking/components/PassportReservationForm";
import DocumentScanPassportScan from "../containers/PassportChecking/components/DocumentScanPassportScan";
import FaceVerificationPassportScan from "../containers/PassportChecking/components/FaceVerificationPassportScan";
import UpgradeOptionPassportScan from "../containers/PassportChecking/components/UpgradeOptionPassportScan";
import ReservationDetailsPassportScan from "../containers/PassportChecking/components/ReservationDetailsPassportScan";
import PaymentOnPassportScan from "../containers/PassportChecking/components/PaymentOnPassportScan";
import CreateKeyPassportScan from "../containers/PassportChecking/components/CreateKeyPassportScan";
import ThankyouConfirmationNo from "../containers/ConfirmationNumberCheckin/components/ThankyouConfirmationNo";
import ThankyouPassportScan from "../containers/PassportChecking/components/ThankyouPassportScan";
import QrScanningReservationForm from "../containers/QrCodeCheckin/components/QrScanningReservationForm";
import FaceVerificationQrScanning from "../containers/QrCodeCheckin/components/FaceVerificationQrScanning";
import ReservationDetailsQrScanning from "../containers/QrCodeCheckin/components/ReservationDetailsQrScanning";
import PaymentOnQrScanning from "../containers/QrCodeCheckin/components/PaymentOnQrScanning";
import CreateKeyQrScanning from "../containers/QrCodeCheckin/components/CreateKeyQrScanning";
import ThankyouQrScanning from "../containers/QrCodeCheckin/components/ThankyouQrScanning";
import InvoiceInformation from "../containers/Checkout/InvoiceInformation";
import CheckoutPayment from "../containers/Checkout/CheckoutPayment";
import SendInvoiceOnMail from "../containers/Checkout/SendInvoiceOnMail";
import DocumentScanQrScanning from "../containers/QrCodeCheckin/components/DocumentScanQrScanning";

const CustomRouter = () => {
  return (
    <Routes>
      {/* <Route path="/" element={<ProtectedRoute component={Login} />} /> */}
      <Route path="/" element={<PrivateRoute component={HomePage} />} />
      <Route path="home-page" element={<PrivateRoute component={HomePage} />} />
      <Route
        path="checkin-page"
        element={<PrivateRoute component={CheckinPage} />}
      />
      <Route
        path="check-out-page"
        element={<PrivateRoute component={CheckOutPage} />}
      />

      <Route
        path="confirmation-number"
        element={<PrivateRoute component={ConfirmationNumber} />}
      />
      <Route
        path="confirmation-no-reservation-form"
        element={<PrivateRoute component={ConfirmationNumberReservationForm} />
      }
      />

      <Route
        path="confirmation-no-document-verification"
        element={<PrivateRoute component={DocumentScanConfirmationNo} />}
      />

      <Route
        path="confirmation-no-face-verification"
        element={<PrivateRoute component={FaceVerificationConfirmationNo} />}
      />

      <Route
        path="confirmation-no-upgrade"
        element={<PrivateRoute component={UpgradeOptionConfirmationNo} />}
      />

      <Route
        path="confirmation-no-reservation-details"
        element={<PrivateRoute component={ReservationDetailsConfirmationNo} />}
      />

      <Route
        path="confirmation-no-create-key"
        element={<PrivateRoute component={CreateKeyConfirmationNO} />}
      />

      <Route
        path="confirmation-no-payment"
        element={<PrivateRoute component={PaymentOnConfirmationNo} />}
      />

      <Route
        path="confirmation-no-thank-you"
        element={<PrivateRoute component={ThankyouConfirmationNo} />}
      />

      {/* *************************************************** */}

      <Route
        path="passport-scanning"
        element={<PrivateRoute component={PassportScanning} />}
      />

      <Route
        path="passport-scanning-reservation-form"
        element={<PrivateRoute component={PassportReservationForm} />}
      />

      <Route
        path="passport-scan-document-verification"
        element={<PrivateRoute component={DocumentScanPassportScan} />}
      />

      <Route
        path="passport-scan-face-verification"
        element={<PrivateRoute component={FaceVerificationPassportScan} />}
      />

      <Route
        path="passport-scan-upgrade"
        element={<PrivateRoute component={UpgradeOptionPassportScan} />}
      />

      <Route
        path="passport-scan-reservation-details"
        element={<PrivateRoute component={ReservationDetailsPassportScan} />}
      />

      <Route
        path="passport-scan-payment"
        element={<PrivateRoute component={PaymentOnPassportScan} />}
      />

      <Route
        path="passport-scan-create-key"
        element={<PrivateRoute component={CreateKeyPassportScan} />}
      />

      <Route
        path="passport-scan-thank-you"
        element={<PrivateRoute component={ThankyouPassportScan} />}
      />

      {/* *********************************************************** */}

      <Route
        path="qr-code-scanning"
        element={<PrivateRoute component={QrScanning} />}
      />

      <Route
        path="qr-code-scanning-reservation-form"
        // element={<PrivateRoute component={QrScanningReservationForm} 
        element={<PrivateRoute component={ConfirmationNumberReservationForm} 
        
        />}
      />

      <Route
        path="qr-code-scanning-document-verification"
        element={<PrivateRoute component={DocumentScanQrScanning} />}
      />

      <Route
        path="qr-code-scanning-face-verification"
        element={<PrivateRoute component={FaceVerificationQrScanning} />}
      />

      <Route
        path="qr-code-scanning-reservation-details"
        element={<PrivateRoute component={ReservationDetailsQrScanning} />}
      />

      <Route
        path="qr-code-scanning-payment"
        element={<PrivateRoute component={PaymentOnQrScanning} />}
      />

      <Route
        path="qr-code-scanning-create-key"
        element={<PrivateRoute component={CreateKeyQrScanning} />}
      />

      <Route
        path="qr-code-scanning-thank-you"
        element={<PrivateRoute component={ThankyouQrScanning} />}
      />

      {/* ********************************************************** */}

      <Route path="payment2" element={<PrivateRoute component={Payment2} />} />

      <Route
        path="checkout-invoice-info"
        element={<PrivateRoute component={InvoiceInformation} />}
      />

      <Route
        path="checkout-payment"
        element={<PrivateRoute component={CheckoutPayment} />}
      />

      <Route
        path="checkout-send-invoice-mail"
        element={<PrivateRoute component={SendInvoiceOnMail} />}
      />

      <Route
        path="checkout-thank-you"
        element={<PrivateRoute component={Thankyou2} />}
      />
      {/* { <Route path="/login" element={<Login />} />} */}
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
};

export default CustomRouter;
