/* eslint-disable jsx-a11y/alt-text */
import { Link } from "react-router-dom";
import KeyImage from "../../../assets/images/key-image.PNG";
const KeyCard = ({ closeKeyModal, keyModal }) => {
  return (
    <div className="modal" style={{ zIndex: "1" }}>
      <div className="modal-dialog modal-lg modal-top">
        <div className="modal-content">
          <div className="modal-body">
            <div className="text-center">
              <div className="mt-30 modal-info">
                <h6>Do you want to create another key?</h6>
              </div>
              {/* <div>
                <img src={KeyImage} />
              </div> */}
            </div>
            <div className="text-center mt-30">
              <button
                onClick={() => {
                  closeKeyModal();
                }}
                className="btn modal-no-btn key-modal-no"
              >
                No
              </button>
              <button
                className="btn modal-yes-btn key-modal-yes"
                onClick={() => {
                  keyModal(true);
                }}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KeyCard;
