/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import "../../assets/stylesheet/process-payment.css";
import { useState } from "react";
import HomePageCard from "../Layout/Card/HomePageCard";
import creditCardScan from "../../assets/images/CreditCardScan.gif";
import Footer from "../Layout/Footer";
import { Link } from "react-router-dom";
import CheckoutFooter from "../Layout/CheckoutFooter";

const Payment2 = () => {
  const [showModal, setShowModal] = useState(false);
  const [timerValue, setTimerValue] = useState(80);
  const [restartTimer, setRestartTimer] = useState(false);

  const resetTimerAndClosePopUp = () => {
    if (!timerValue) {
      setTimerValue(80);
    }
    setRestartTimer(true);
    setShowModal(false);
  };

  const timerCounter = (value) => {
    setTimerValue(value);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const stopTimer = () => {
    setRestartTimer(false);
  };

  return (
    <div>
      <main>
        <div className="main-content">
          <div className="h-100">
            <div className="content-bg h-100">
              <div className="row h-100">
                <div className="col-sm-5 pl-0">
                  <div className="h-100">
                    <div>
                      <div>
                        <button
                          onClick={() => {
                            setShowModal(true);
                          }}
                          className="btn home-btn"
                        >
                          <i class="ri-arrow-go-back-line mr-3"></i>RESTART
                        </button>
                      </div>
                    </div>
                    <div className="scan-content-left">
                      <div>
                        <div className="scan-text">
                          <h5 className="text-center font-16 font-weight-600">
                            Its time to process your payment
                          </h5>
                        </div>

                        <div>
                          <div className="text-center payment-content">
                            <p>
                              Room Rate: <span>$276</span>
                            </p>
                            <p>
                              Package: <span>$188.76</span>
                            </p>
                            
                          </div>
                        </div>

                        <div className="mt-30">
                          <h5 className="font-weight-600 text-center font-15">
                            Total amount due:
                          </h5>
                          <div className="text-center">
                            <p>$188.77</p>
                          </div>
                        </div>

                        <div className="text-center mt-30">
                          <Link
                            to="/invoice"
                            className="btn button-outline proceed-blue-btn"
                            style={{
                              backgroundColor: "#03355c",
                              width: "166px",
                            }}
                          >
                            PAY
                          </Link>
                        </div>

                        
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-7 pr-0">
                  <div className="process-payment-img">
                    <img src={creditCardScan} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <CheckoutFooter
          page={"payment"}
          showModal={showModal}
          openModal={openModal}
          timerCounter={timerCounter}
          timerValue={timerValue}
          restartTimer={restartTimer}
          stopTimer={stopTimer}
        />
      </main>
      {showModal && (
        <HomePageCard showModal={showModal} resetTimerAndClosePopUp={resetTimerAndClosePopUp} />
      )}
    </div>
  );
};

export default Payment2;
