import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AgreedForm from "../../components/TermsAndConditions/AgreedForm";
import CheckoutFooter from "../../components/Layout/CheckoutFooter";
import HomePageCard from "../../components/Layout/Card/HomePageCard";
import { useSelector } from "react-redux";
import { getCheckoutData } from "../../store/Checkout/checkoutSlice";
import { timerConstantValue } from "../../constants/constant";

const InvoiceInformation = () => {
  const timeLimit = timerConstantValue;
  const [showModal, setShowModal] = useState(false);
  const [timerValue, setTimerValue] = useState(timeLimit);
  const [restartTimer, setRestartTimer] = useState(false);
  const [signature, setSignature] = useState(null);
  const [url, setUrl] = useState(null);

  const navigate = useNavigate();
  const data = useSelector(getCheckoutData);

  const resetTimerAndClosePopUp = () => {
    if (!timerValue) {
      setTimerValue(timeLimit);
    }
    setRestartTimer(true);
    setShowModal(false);
  };

  const timerCounter = (value) => {
    setTimerValue(value);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const stopTimer = () => {
    setRestartTimer(false);
  };

  const navigateToPayment = () => {
    navigate("/checkout-payment");
  };
  const clearSign = () => {
    signature.clear();
    setUrl(null);
  };
  return (
    <div>
      <AgreedForm
        // loading={loading}
        openModal={openModal}
        // closeErrorModal={closeErrorModal}
        // erroModalData={erroModalData}
        setSignature={setSignature}
        setUrl={setUrl}
        clearSign = {clearSign}
        url={url}
        navigateToPayment={navigateToPayment}
      />
      <CheckoutFooter
        page={"agreedform"}
        showModal={showModal}
        openModal={openModal}
        timerCounter={timerCounter}
        timerValue={timerValue}
        restartTimer={restartTimer}
        stopTimer={stopTimer}
        timeLimit={timeLimit}
      />
      {showModal && (
        <HomePageCard
          showModal={showModal}
          resetTimerAndClosePopUp={resetTimerAndClosePopUp}
        />
      )}
    </div>
  );
};

export default InvoiceInformation;
