import { Link } from "react-router-dom";
import KeyImage from "../../../assets/images/key-image.PNG";
import QrImg from "../../../assets/images/qr-image.PNG";
import AppStoreImage from "../../../assets/images/appstore.png";
import PlayStoreImage from "../../../assets/images/playstore.png";
const DownloadKeyModal = ({
  closeModal,
  closeDownloadKey,
  doneDownloadKey,
}) => {
  return (
    <div className="modal" style={{ zIndex: "1" }}>
      <div className="modal-dialog modal-lg modal-top">
        <div className="modal-content modal-bg">
          <div className="modal-body">
            <div className="text-center download-key-width">
              <div className="mt-30 modal-info">
                <p>
                  Download the Mobile App in your device and Login with
                  registered E-mail id to access the room key.Tap 'Cancel' if
                  you prefer using a physical key.{" "}
                </p>
              </div>
              <div>
                {/* <img src={KeyImage} /> */}

                <div className="download-key-section">
                  <div className="download-app-store">
                    <div className="download-qr-image">
                      <img src={QrImg} alt="" />
                    </div>
                    <div className="app-store-image">
                      <img src={AppStoreImage} alt="" />
                    </div>
                  </div>
                  <div className="download-app-store">
                    <div className="download-qr-image">
                      <img src={QrImg} alt="" />
                    </div>
                    <div className="app-store-image">
                      <img src={PlayStoreImage} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center mt-30">
              <button
                onClick={closeDownloadKey}
                className="btn cancel-modal-btn"
              >
                Cancel
              </button>
              <button
                className="btn button-blue done-modal-btn"
                onClick={doneDownloadKey}
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadKeyModal;
