import { Link } from "react-router-dom";
import LoaderPopup from "../../LoaderAndNotification/LoaderPopup";
import ErrorPopup from "../../LoaderAndNotification/ErrorPopup";

const PaymentModal = ({
  setPaymentToggle,
  navigateUrl,
  loading2,
  loading3,
  erroModalData2,
  erroModalData3,
  closeErrorModal2,
  closeErrorModal3,
  paymentTypeHandler,
}) => {
  return (
    <div className="modal" style={{ zIndex: "1" }}>
      <LoaderPopup loading={loading2} />
      <LoaderPopup loading={loading3} />
      <ErrorPopup
        closeErrorModal={closeErrorModal2}
        erroModalData={erroModalData2}
      />
      <ErrorPopup
        closeErrorModal={closeErrorModal3}
        erroModalData={erroModalData3}
      />
      <div className="modal-dialog modal-lg modal-top">
        <div className="modal-content">
          <div className="modal-body">
            <div className="text-center">
              <div>
                <img src="assets/images/person-image.PNG" alt="" />
              </div>
              <div className="mt-30">
                <h6>Payment Method</h6>
              </div>
            </div>
            <div className="text-center mt-30">
              <button
                onClick={() => {
                  paymentTypeHandler("creditcard");
                }}
                className="btn modal-no-btn credit-card-btn"
              >
                <i className="bx bx-credit-card-alt payment-card-icon"></i>
                Credit card
              </button>
              <button
                onClick={() => {
                  paymentTypeHandler("debitcard");
                }}
                className="btn modal-yes-btn debit-card-btn"
              >
                <i className="bx bx-credit-card payment-card-icon"></i>Debit
                Card
              </button>
              {/* <button
                onClick={() => {
                  paymentTypeHandler("upi");
                }}
                className="btn modal-no-btn upi-card-btn"
                style={{ marginLeft: "15px" }}
              >
                <i className="bx bx-qr-scan payment-card-icon"></i>UPI
              </button> */}
            </div>
          </div>
          <div className="payment-cancel-btn">
            <button
              onClick={() => {
                setPaymentToggle(false);
              }}
              className="btn modal-no-btn credit-card-btn"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentModal;
