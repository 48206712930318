import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const FaceRcgFailedModal = ({
  ficalRecognisationModal,
  completedFacialRecognisation,
  backToHomePage,
}) => {
  return (
    <div>
      <Modal open={ficalRecognisationModal} style={{ zIndex: "1" }}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "800px",
            backgroundColor: "#f5f5f5",
            border: "2px #000",
            boxShadow: "0 0 24px rgba(0, 0, 0, 0.5)",
            padding: "70px",
            borderRadius: "20px",
          }}
        >
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, textAlign: "center", fontSize: "1.8rem" }}
          >
            <p>Face Verification Failed!!</p>
            <p>To verify your face, please click on continue button.</p>
          </Typography>
          <div style={{ textAlign: "center" }}>
            {/* <div className="lds-ellipsis">
          <div></div>
          <div></div>
        </div> */}
            <button
              style={{ marginTop: "20px", padding: "15px" }}
              className="btn button-blue scan-btn"
              onClick={() => {
                completedFacialRecognisation();
              }}
            >
              Continue
            </button>
            <button
              style={{ marginTop: "20px", padding: "15px", marginLeft: "15px" }}
              className="btn button-blue scan-btn"
              onClick={() => {
                backToHomePage();
              }}
            >
              Back
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default FaceRcgFailedModal;
