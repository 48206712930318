import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import receipt from "./receipt.pdf";
import { useState } from "react";
import "../../assets/stylesheet/agreed-sign.css"

function PdfViewer() {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const [zoom, setZoom] = useState(1); // Initial zoom level

  const handleZoomIn = () => {
    setZoom(zoom + 0.1);
  };

  const handleZoomOut = () => {
    setZoom(zoom - 0.1);
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  return (
    <div style={{ textAlign: "center", position:"relative" }}>
      <header>  
        <Document file={receipt} onLoadSuccess={onDocumentLoadSuccess}>
          <Page
            height={800}
            textAlign="center"
            width={500}
            pageNumber={1}
            scale={zoom}
          />
        </Document>
        <lable className="text-center" style={{fontSize: '10px'}}>
          (Page {pageNumber} of {numPages})
        </lable>
        <div className="pdf-btn" style={{marginTop: '25px'}}>
          <div>
            <button className="pdf-zoom-btn" onClick={handleZoomIn}><i className='bx bx-zoom-in'></i></button>
          </div>
          <div >
            <button className="pdf-zoom-btn" onClick={handleZoomOut}><i className='bx bx-zoom-out'></i></button>
          </div>
        </div>
      </header>
      <center></center>
    </div>
  );
}

export default PdfViewer;