/* eslint-disable jsx-a11y/anchor-is-valid */
import "../../assets/stylesheet/agreed-sign.css";
import PdfViewer from "./PdfViewer";
import SignaturePad from "../Forms/SignaturePad";

const AgreedForm = ({
  openModal,
  navigateToPayment,
  signature,
  setSignature,
  setUrl,
  url,
  gotSignature,
  clearSign
}) => {
  return (
    <div>
      <main>
        <div className="main-content">
          <div className="h-100">
            <div className="content-bg h-100">
              <div className="row h-100">
                <div className="col-sm-12 pl-0 col-left-bg">
                  <div className="h-100">
                    <div className="d-flex justify-content-between">
                      <div style={{ marginTop: "0px", marginBottom: "0px" }}>
                        <button
                          onClick={() => {
                            openModal();
                          }}
                          className="btn home-btn"
                        >
                          <i className="ri-arrow-go-back-line mr-3"></i>RESTART
                        </button>
                      </div>
                    </div>
                    <div className="content-padding">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="text-center">
                            <div className="info-invoice">
                              {/* <h3>
                                <strong>Invoice Details</strong>
                              </h3> */}
                              <PdfViewer />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6" >
                          <div className="col-md-12">
                          <div className="">
                                <button style={{    float: 'right'}}
                                  className="btn terms-retry-btn"
                                  onClick={clearSign}
                                >
                                  <i className="bx bx-revision"></i>
                                </button>
                              </div>
                            <SignaturePad
                              signature={signature}
                              setSignature={setSignature}
                              gotSignature={gotSignature}
                              setUrl={setUrl}
                              url={url}
                              showRetryBtn={false}
                            />

                            <div className="col-md-12" style={{float:'right' , marginTop: "50px"}}>
                              <button style={{float: 'right'}}
                                onClick={navigateToPayment}
                                className="btn button-blue proceed-btn"
                              >
                                Proceed{" "}
                                {/* Proceed <i className="bx bx-chevron-right"></i> */}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AgreedForm;
