import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { HashRouter as Router } from "react-router-dom";
import "./assets/stylesheet/bootstrap/css/bootstrap.min.css";
import "./assets/stylesheet/style1.css";
import "./assets/stylesheet/responsive.css";
import "./assets/stylesheet/remixicon/remixicon.css";
import "./assets/stylesheet/footer.css";
import { Provider } from "react-redux";
import store from "./store";
import "./i18n";
// import "react-toastify/dist/ReactToastify.css";
// import "./assets/stylesheet/style.css"
// import "./stylesheet/assets/bootstrap/js/bootstrap.bundle.min.js"
// import "./stylesheet/assets/bootstrap-icons/bootstrap-icons.css"

const root = document.getElementById("root");
ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  root
);
