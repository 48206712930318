/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import KeyEncoding from "../../assets/images/keybg.jpg";
import "../../assets/stylesheet/create-key.css";
import LoaderPopup from "../LoaderAndNotification/LoaderPopup";
import ErrorPopup from "../LoaderAndNotification/ErrorPopup";

const CreateKey = ({
  openModal,
  erroModalData,
  closeErrorModal,
  loading,
  userData,
  openDownloadKey,
  createKeyHandler,
}) => {
  return (
    <div>
      <main>
        <LoaderPopup loading={loading} />
        <ErrorPopup
          closeErrorModal={closeErrorModal}
          erroModalData={erroModalData}
        />
        <div className="main-content">
          <div className="h-100">
            <div className="content-bg h-100">
              <div className="row h-100">
                <div className="col-sm-4 pl-0">
                  <div className="h-100">
                    <div>
                      <div>
                        <button
                          onClick={() => {
                            openModal();
                          }}
                          className="btn home-btn"
                        >
                          <i className="ri-arrow-go-back-line mr-3"></i>RESTART
                        </button>
                      </div>
                    </div>
                    <div className="scan-content-left position-relative">
                      <div className="create-key-center">
                        <div>
                          <div className="text-center">
                            <p className="mb-0 font-15 font-weight-500">
                              Your Room Number
                            </p>
                            <h5 className="font-weight-700 mb-0 number-color">
                              {userData?.roomNo || "-"}
                            </h5>
                          </div>

                          <div className="room-keycard mt-30">
                            <div className="text-center">
                              <p className="font-14 font-weight-500">
                                Place the room keycard to make your key
                              </p>

                              <button
                                onClick={() => {
                                  createKeyHandler();
                                }}
                                className="btn button-blue create-key-btn"
                              >
                                <i className="bx bxs-key mr-5"></i>Create Key
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="text-center mt-20">
                          <div className="or-line-center">
                            <span>OR</span>
                          </div>
                        </div>
                        <div className="room-keycard mt-20">
                          <div className="text-center">
                            <p className="font-14 font-weight-500">
                              Generate Digital Key in your Rewards App
                            </p>
                            <button
                              onClick={() => {
                                openDownloadKey();
                              }}
                              className="btn button-blue download-key-btn"
                            >
                              <i className="bx bx-mobile-alt mr-5"></i>Download
                              Key
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-8 pr-0">
                  <div className="key-encoding-img">
                    <img src={KeyEncoding} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default CreateKey;
