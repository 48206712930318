import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import baseApi from "../../network/baseApi";

export const faceVerification = createAsyncThunk("faceVerification", async(qd)=>{
    const response = await baseApi.post(`api/Document/ProcessDocuments`, qd);
    return response.data;
})

const initialState = {
  faceData: { loading: false, data: [], error: "" },
};

const faceVerificationSlice = createSlice({
  name: "faceverification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(faceVerification.pending, (state) => {
      state.faceData.loading = true;
    });
    builder.addCase(faceVerification.fulfilled, (state, action) => {
      state.faceData.loading = false;
      state.faceData.data = action.payload;
      state.faceData.error = "";
    });
    builder.addCase(faceVerification.rejected, (state, action) => {
      state.faceData.loading = false;
      state.faceData.data = [];
      state.faceData.error = action.error.message;
    });
  },
});

export const getFaceData = (state) => state.userFaceVerification.faceData;

export default faceVerificationSlice.reducer;