import { useState, useEffect } from "react";
import HomePageCard from "../components/Layout/Card/HomePageCard";
import { useNavigate } from "react-router-dom";
import CheckoutFooter from "../components/Layout/CheckoutFooter";
import { getReservationDetails } from "../network/apiService";
import { userReservationDetailsCheckoutPage } from "../store/Checkout/checkoutSlice";
import { useDispatch } from "react-redux";
import LoaderPopup from "../components/LoaderAndNotification/LoaderPopup";
import ErrorPopup from "../components/LoaderAndNotification/ErrorPopup";
import { timerConstantValue } from "../constants/constant";

const CheckOut = () => {
  const timeLimit = timerConstantValue;
  const [showModal, setShowModal] = useState(false);
  const [timerValue, setTimerValue] = useState(timeLimit);
  const [restartTimer, setRestartTimer] = useState(false);
  const [userCheckoutData, setUserCheckoutData] = useState({
    lastName: "",
    roomNumber: "",
  });
  const [active, setActive] = useState("");
  const [loading, setLoading] = useState(false);
  const [erroModalData, setErrorModalData] = useState({
    toggleError: false,
    errorMessage: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  const resetTimerAndClosePopUp = () => {
    if (!timerValue) {
      setTimerValue(timeLimit);
    }
    setRestartTimer(true);
    setShowModal(false);
  };

  const timerCounter = (value) => {
    setTimerValue(value);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const stopTimer = () => {
    setRestartTimer(false);
  };

  const closeErrorModal = () => {
    setErrorModalData((state) => ({
      ...state,
      toggleError: false,
      errorMessage: "",
    }));
  };

  const checkoutDataChangeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUserCheckoutData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const getCheckoutDetails = async () => {
    try {
      setLoading(true);
      const newObj = {
        LastName: userCheckoutData.lastName,
        RoomNumber: userCheckoutData.roomNumber,
      };
      const response = await getReservationDetails(newObj, token);
      if (response.data.statusCode === 200) {
        setLoading(false);
        dispatch(userReservationDetailsCheckoutPage(response.data));
        setUserCheckoutData({
          lastName: "",
          roomNumber: "",
        });
        localStorage.setItem(
          "confirmationNo",
          response.data.pmsConfirmationNo
        );
        navigate("/checkout-invoice-info");
        // if (
        //   response?.data?.lastName.toUpperCase() ===
        //   userCheckoutData.lastName.toUpperCase()
        // ) {
        //   setLoading(false);
        //   dispatch(userReservationDetailsCheckoutPage(response.data));
        //   setUserCheckoutData({
        //     lastName: "",
        //     roomNumber: "",
        //   });
        //   localStorage.setItem(
        //     "confirmationNo",
        //     response.data.pmsConfirmationNo
        //   );
        //   navigate("/checkout-invoice-info");
        //   return;
        // } else if (
        //   response?.data?.data?.lastName.toUpperCase() !==
        //   userCheckoutData.lastName.toUpperCase()
        // ) {
        //   setLoading(false);
        //   setErrorModalData((state) => ({
        //     ...state,
        //     toggleError: true,
        //     errorMessage:
        //       "LastName does not matched. Please provide the valid lastname",
        //   }));
        //   return;
        // }
        return;
      }
      if (response.data.statusCode === 102) {
        setErrorModalData((state) => ({
          ...state,
          toggleError: true,
          errorMessage: response.data.message,
        }));
        setLoading(false);
        return;
      }
    } catch (error) {
      setLoading(false);
      setErrorModalData((state) => ({
        ...state,
        toggleError: true,
        errorMessage: error.message,
      }));
      console.log(error);
      return;
    }
    return;
  };

  useEffect(() => {
    if (userCheckoutData.lastName && userCheckoutData.roomNumber) {
      setActive("active");
    } else {
      setActive("");
    }
  }, [userCheckoutData.roomNumber, userCheckoutData.lastName]);

  return (
    <div>
      <main>
        <div className="main-content confirmation-white">
          <div>
            <div>
              <button
                onClick={() => {
                  setShowModal(true);
                }}
                type="button"
                className="btn home-btn"
              >
                <i className="ri-arrow-go-back-line mr-3"></i>RESTART
              </button>
            </div>
          </div>

          <div className="checkout-content-center">
            <div>
              <div className="d-flex justify-content-center did-floating-label-content">
                <input
                  type="text"
                  name="lastName"
                  value={userCheckoutData.lastName}
                  onChange={checkoutDataChangeHandler}
                  autoComplete="off"
                  className="form-control form-control-set did-floating-input"
                  placeholder=""
                />
                <label className="did-floating-label">Last Name</label>
              </div>
            </div>
            <div>
              <div className="d-flex justify-content-center did-floating-label-content">
                <input
                  type="text"
                  name="roomNumber"
                  value={userCheckoutData.roomNumber}
                  onChange={checkoutDataChangeHandler}
                  autoComplete="off"
                  className="form-control form-control-set did-floating-input"
                  placeholder=""
                />
                <label className="did-floating-label">Room Number</label>
              </div>
            </div>

            <div className="reservation-btn-set p-0 mt-top">
              <div className="find-booking p-0">
                <button
                  onClick={getCheckoutDetails}
                  className={`btn button-outline find-booking-btn ${active}`}
                  disabled={active ? false : true}
                >
                  Find My Booking
                </button>
              </div>
            </div>
          </div>
        </div>
        <LoaderPopup loading={loading} />
        <ErrorPopup
          closeErrorModal={closeErrorModal}
          erroModalData={erroModalData}
        />
        <CheckoutFooter
          page={"confirmation"}
          showModal={showModal}
          openModal={openModal}
          timerCounter={timerCounter}
          timerValue={timerValue}
          restartTimer={restartTimer}
          stopTimer={stopTimer}
          timeLimit={timeLimit}
        />
      </main>
      {showModal && (
        <HomePageCard
          showModal={showModal}
          resetTimerAndClosePopUp={resetTimerAndClosePopUp}
        />
      )}
    </div>
  );
};

export default CheckOut;
