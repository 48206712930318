import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userDetails: {},
  guestCount: 1,
};

const qrScanningSlice = createSlice({
  name: "qrscanning",
  initialState,
  reducers: {
    userReservationDetailsQr: (state, action) => {
      state.userDetails = action.payload;
    },
    qrScanCountOfTheGuests: (state, action) => {
      state.guestCount = state.guestCount + action.payload;
    },
    resetQRGuestCount: (state) => {
      state.guestCount = 1;
    },
  },
});

export const getQrScanningData = (state) => state.qrscanning.userDetails;
export const getQRScanCurrentCountOfGuest = (state) =>
  state.qrscanning.guestCount;

export const {
  userReservationDetailsQr,
  qrScanCountOfTheGuests,
  resetQRGuestCount,
} = qrScanningSlice.actions;

export default qrScanningSlice.reducer;
