import {
  capitalizeFullName,
  formatDate,
  nameValidation,
} from "../../helpers/commonHelper";
import moment from "moment";

const ReservationFormTemplate = ({
  reservationData,
  multipleUserData,
  propertyDetails,
}) => {
  const findNumberOfNights = (arrivalDate, departureDate) => {
    let noOfNights = null;
    if (arrivalDate && departureDate) {
      const date1 = new Date(moment(arrivalDate).format("YYYY-MM-DD"));
      const date2 = new Date(moment(departureDate).format("YYYY-MM-DD"));
      const timeDiff = date2.getTime() - date1.getTime();
      const nights = Math.ceil(timeDiff / (1000 * 3600 * 24));
      noOfNights = nights;
    }
    return noOfNights;
  };

  return (
    <div>
      {/* <div className="reservation-card-width"> */}
      {/* <div className="card"> */}
      <div className="card-header">
        <div>
          <h4>
            Confirmation Number :{" "}
            <strong>{reservationData?.pmsConfirmationNo}</strong>
          </h4>
        </div>
      </div>
      <div className="card-body">
        <div>
          <div>
            <div style={{marginBottom:'15px'}}>
              <label className="reservation-card-label_sm">Property Name:</label>
              <div>
                <label className="reservation-card-label_md">{propertyDetails?.property}</label>
              </div>
            </div>
            <div className="reservation-date" style={{marginBottom:'10px'}}>
              <div>
                <label className="reservation-card-label_sm">Arrival Date:</label>
                <div>
                  <label className="reservation-card-label_md">{formatDate(reservationData?.arrivalDate)}</label>
                </div>
              </div>
              <div>
                <label className="reservation-card-label_sm">
                  Departure Date:
                </label>
                <div>
                  <label className="reservation-card-label_md">{formatDate(reservationData?.departureDate) || ""}</label>
                </div>
              </div>
              <div>
                <label className="reservation-card-label_sm">
                  Number of Nights:
                </label>
                <div>
                  <label className="reservation-card-label_md">
                    {findNumberOfNights(
                      reservationData?.arrivalDate,
                      reservationData?.departureDate
                    )}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div style={{marginTop:"15px"}}>
            <div className="reservation-date gap-set" style={{marginBottom:'15px'}}>
              <div>
                <label className="reservation-card-label_sm">Adult(s):</label>
                <div>
                  <label className="reservation-card-label_md">{reservationData?.adultCount}</label>
                </div>
              </div>
              <div style={{marginLeft: '30px'}}> 
                <label className="reservation-card-label_sm">
                  Children(0-12years old):
                </label>
                <div>
                  <label className="reservation-card-label_md">{reservationData?.childCount || 0}</label>
                </div>
              </div>
            </div>
            <div className="reservation-date gap-set" style={{marginBottom:'15px'}}>
              <div>
                <label className="reservation-card-label_sm">Room Type:</label>
                <div>
                  <label className="reservation-card-label_md">{reservationData?.roomTypeName}</label>
                </div>
              </div>
              {Array.isArray(multipleUserData) && multipleUserData.length && (
                <div style={{ marginLeft: "-39px" }}>
                  <label className="reservation-card-label_sm">Name:</label>
                  <div>
                    <label className="reservation-card-label_md">
                      {nameValidation(
                        reservationData?.firstName,
                        reservationData?.lastName
                      )}
                    </label>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
    // </div>
    // </div>
  );
};

export default ReservationFormTemplate;
